export default function MessagesIcon() {
  return (
    <svg
      width='42'
      height='31'
      viewBox='0 0 42 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M41.4609 2.87883L21.0125 16.7983L0.550293 2.87883V1.85612C0.550293 1.11234 1.18542 0.514648 1.95862 0.514648H40.0526C40.8258 0.514648 41.4609 1.11234 41.4609 1.85612V2.87883ZM41.4609 25.1261L27.35 15.5232L41.4609 6.07978V25.1394V25.1261ZM41.4609 28.8185C41.4609 29.5623 40.8258 30.1732 40.0526 30.1732H1.95862C1.18542 30.1732 0.550293 29.5623 0.550293 28.8185V28.1676L17.0498 17.1171L20.9987 19.7602L24.9475 17.1171L41.4471 28.1676V28.8185H41.4609ZM0.550293 6.0665L14.675 15.5232L0.550293 25.1394V6.0665Z'
        fill='white'
      />
    </svg>
  );
}
