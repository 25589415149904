import axios from 'axios';
import { appHelpers } from '../../../helpers';

const getFirebaseUser = async (uid) => {
  let body = {
    firebaseUserId: uid,
  };

  let config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/register/getFirebaseUser`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_TOKEN}`,
    },
    data: body,
  };

  try {
    return await axios(config);
  } catch (error) {
    return {
      results: null,
      error: error,
    };
  }
};

export { getFirebaseUser };
