import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Header } from '../header';
import { Nav } from '../nav';
import { Footer } from '../global';
// import { useMatchMedia } from '../../hooks/useMatchMedia';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <>
      <Toaster />
      <Header />
      <Nav />
      {children}
      <Footer />
    </>
  );
};
