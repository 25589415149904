import { MainLayout } from '../../../components/layouts/mainLayout';
import {
  DashboardPageStyled,
  // DashboardContentStyled,
} from '../styles/dashboardContent.styles';

import { DashboardWidgets } from '../components/dashboardwidgets';
import { DashboardContent } from '../components/dashboardContent';
import { InfoResources } from '../../../components/infoResources';

export const Dashboard = () => {
  return (
    <MainLayout>
      <DashboardPageStyled>
        <DashboardWidgets />
        <DashboardContent />
      </DashboardPageStyled>
      <InfoResources />
    </MainLayout>
  );
};
