import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

const useGetAllInvoices = () => {
  const { hsId } = useUserContext();

  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    error: isInvoicesError,
    refetch: invoiceRefetch,
    isFetching: isFetchingInvoices,
  } = useQuery(
    ['all-invoices'],
    async () => {
      if (hsId) {
        return await api({
          hsId: hsId,
        }).invoices.getAllInvoices();
      } else {
        return [];
      }
    },
    { enabled: !!hsId, keepPreviousData: true }
  );

  return {
    invoicesData,
    isInvoicesLoading,
    isInvoicesError,
    invoiceRefetch,
    isFetchingInvoices,
  };
};

export { useGetAllInvoices };
