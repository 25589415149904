import PropTypes from 'prop-types';
import {
  PostItem,
  PostItemImage,
} from './styles/infoResourcesMobileItem.styles';
import moment from 'moment';

export default function InfoResourcesMobileItem({ post }) {
  return (
    <PostItem>
      <div>
        <a href={post.url} rel='noopener noreferrer' target='_blank'>
          <h3>{post.title}</h3>
          <span>{moment(post.createdDate).format('DD/MM/YYYY')}</span>
        </a>
      </div>
      <PostItemImage>
        <a href={post.url} rel='noopener noreferrer' target='_blank'>
          <img src={post.featuredImage} alt={post.featuredImageAltText} />
        </a>
      </PostItemImage>
    </PostItem>
  );
}

InfoResourcesMobileItem.propTypes = {
  post: PropTypes.obj,
};
