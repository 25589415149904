import { useAxios } from '../../../lib/axios';

type UserResults = {
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName: string;
  disabled: boolean;
  metadata: {
    lastSignInTime: string;
    creationTime: string;
  };
  multiFactor: {
    enrolledFactors: {
      uid: string;
      displayName: string;
      factorId: string;
      enrollmentTime: string;
      phoneNumber?: string;
    }[];
  };
  providerData: {
    uid: string;
    displayName: string;
    email: string;
    providerId: string;
  }[];
};

const fetchEmailVerifyStatus = async (email: string) => {
  if (!email) {
    throw new Error('Email address not provided');
  }

  const requestData = {
    email,
  };

  try {
    const { results } = (await useAxios({
      endpoint: '/v2/users/fetchEmailVerifyStatus',
      method: 'POST',
      requestData: requestData,
    })) as { results: UserResults };

    return results;
  } catch (err) {
    throw new Error('Error verifying email status');
  }
};

export { fetchEmailVerifyStatus };
