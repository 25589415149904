import { createContext } from 'react';

type User = {
  given_name?: string;
  family_name?: string;
  nickname?: string;
  name?: string;
  picture?: string;
  locale?: string;
  updated_at?: string;
  email?: string;
  email_verified?: boolean;
  sub?: string;
};

type UserContextState = {
  accountStatus: string | null | undefined;
  companyIds: Array<number | string>;
  hsId: string | null | undefined;
  user: User | undefined | null;
  isAuthenticated: boolean | null;
};

const defaultUserContextState: UserContextState = {
  accountStatus: '',
  companyIds: [],
  hsId: '',
  user: {
    given_name: '',
    email: '',
    family_name: '',
    nickname: '',
    name: '',
    picture: '',
    locale: '',
    updated_at: '',
    email_verified: false,
    sub: '',
  },
  isAuthenticated: false,
};

const UserContext = createContext(defaultUserContextState);

export { UserContext };
