import {
  Card,
  CardCol,
  CardColLabel,
  CardColDate,
  CardColContent,
} from '../../../../components/card';
import { DownloadCloudBlue } from '../../../../components/svg';
import { DocumentsListItemStyled } from '../styles/documentsListItem.styles';
import { DocumentsListItemProps } from '../types';
import { useMatchMedia } from '../../../../hooks/useMatchMedia';

const DocumentsListItem = ({ document }: DocumentsListItemProps) => {
  const isDeskTopResolution = useMatchMedia('(min-width: 992px)', true);

  return (
    <Card className='pod-card-documents' cols='4' data-testid='docs-list-item'>
      <DocumentsListItemStyled>
        <CardCol className='document-date'>
          <CardColLabel label='Date uploaded' />
          <CardColDate date={document.createdDate} />
        </CardCol>
        {isDeskTopResolution && (
          <CardCol className='pod-card__doc-ref'>
            <CardColLabel label='Document reference' />
            <CardColContent content={document.reference} />
          </CardCol>
        )}

        <CardCol className='pod-card__doc-name'>
          {isDeskTopResolution && <CardColLabel label='File Name' />}
          <CardColContent content={document.name} />
        </CardCol>
        <CardCol className='document-type'>
          {isDeskTopResolution && <CardColLabel label='File Type' />}
          <CardColContent content={document.type} />
        </CardCol>
        <CardCol className='document-download-button'>
          {document.attachment ? (
            <a
              href={document.attachment}
              download
              target='_blank'
              rel='noreferrer'
              aria-label='Download document'
            >
              <DownloadCloudBlue />
            </a>
          ) : null}
        </CardCol>
      </DocumentsListItemStyled>
    </Card>
  );
};

export { DocumentsListItem };
