import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';

import { useUserContext } from '../../providers/user/useUserContext';
import { useParams } from 'react-router';

const useGetAllStatements = () => {
  const { hsId } = useUserContext();
  const { accountId } = useParams();

  const { data, error, isLoading, refetch } = useQuery(
    ['statements'],
    async () => {
      if (hsId) {
        return await api({
          hsId: hsId,
          companyId: accountId,
        }).statements.getAllStatements();
      } else {
        return [];
      }
    },
    { enabled: !!hsId }
  );

  return {
    statementsData: data,
    isStatementsLoading: isLoading,
    statementsError: error,
    statementsRefetch: refetch,
  };
};

export { useGetAllStatements };
