export default function DownloadCloudBlue() {
  return (
    <svg
      width='26'
      height='19'
      viewBox='0 0 26 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_499_6683)'>
        <path
          d='M19.4961 18.9091H4.83303C2.16276 18.9091 -0.0078125 16.8289 -0.0078125 14.2532C-0.0078125 11.6774 2.15495 9.59723 4.83303 9.59723C4.91891 9.59723 5.01261 9.60474 5.09849 9.61225C4.95795 9.03401 4.87207 8.43324 4.87207 7.80995C4.87988 3.49946 8.51051 0 13 0C17.0522 0 20.4018 2.85364 21.0108 6.58589C23.8685 7.24673 25.9922 9.70987 25.9922 12.6611C25.9922 16.1155 23.0799 18.9091 19.4961 18.9091Z'
          fill='#033F85'
        />
        <g clipPath='url(#clip1_499_6683)'>
          <path
            d='M12.7016 15.186C12.8766 15.4238 13.1642 15.4227 13.3373 15.1835L16.4608 10.8659C16.6339 10.6267 16.512 10.4285 16.1866 10.4298L14.2872 10.4373L13.8749 7.60407C13.8737 7.31927 13.6079 7.0885 13.2825 7.08978L12.6923 7.09212C12.3669 7.09341 12.1029 7.32628 12.104 7.61108L11.7066 10.4475L9.80714 10.4551C9.48173 10.4563 9.36141 10.6489 9.53644 10.8933L12.7016 15.186Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_499_6683'>
          <rect width='26' height='18.9091' fill='white' />
        </clipPath>
        <clipPath id='clip1_499_6683'>
          <rect
            width='7.09091'
            height='8.27273'
            fill='white'
            transform='translate(16.5618 15.3496) rotate(179.773)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
