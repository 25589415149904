import styled from 'styled-components';

export const StyledWrapper = styled.section`
  width: 100%;
  margin-top: ${(props) => (props.marginTop ? '0.8rem' : '')};
  padding: 6rem 0;
  background: ${(props) =>
    props.gradient
      ? 'linear-gradient(123deg, #b32442 0%, #6d2566 50%, #095798 100%'
      : ''});

`;
