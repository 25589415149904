import styled from 'styled-components';

export const MessagesInboxSorterWrapperStyled = styled.div`
  padding: 1.2rem 2rem;
  color: var(--nbs-black);
  border-right: solid 0.1rem var(--nbs-ui-grey);

  label {
    margin-right: 3.2rem;
    color: var(--nbs-navy-blue);
    font-size: 1.4rem;
  }
`;

export const MessagesInboxSorterFormStyled = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

export const MessagesInboxSorterDropdownWrapperStyled = styled.div`
  position: relative;
  flex: 1;
  border: solid 0.1rem var(--nbs-navy-blue);
  border-radius: 0.4rem;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    font-size: 1.2rem;
    content: '\u25BC';
    z-index: -1;
  }

  select {
    width: calc(100% + 3.2rem);
    padding: 0.4rem 1.2rem;
    color: var(--nbs-navy-blue);
    background: none;
    border: none;
    outline: none;
  }
`;
