import styled from 'styled-components';

export const CardColDropdownToggleStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    background: none;
  }

  &.is-active {
    button {
      transform: rotate(180deg);
    }
  }
`;
