import styled from 'styled-components';

export const LoginButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 2.4rem;
  color: var(--white);
  font-weight: bold;
  text-transform: uppercase;
  background-color: var(--nbs-navy-blue);
  border-radius: 999rem;
`;
