import { QueriesSingleEmailReplyStyled } from '../styles/queriesSingleEmailsReply.styles';

// Form
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import toast from 'react-hot-toast';
import { useReplyToTicket } from '../hooks/useReplyToTicket';
import { useGetTicketEmails } from '../../../../hooks/tickets/useGetTicketEmails';
import { useUserContext } from '../../../../providers/user/useUserContext';
import { useParams } from 'react-router';

const QueriesSingleEmailsReply = () => {
  const { user, hsId } = useUserContext();
  const { id } = useParams();
  const { singleTicketEmailRefetch } = useGetTicketEmails();
  const schema = Yup.object().shape({
    // message: Yup.string().required('Message is required'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    // control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleTicketReply = async (data) => {
    const replyData = {
      hsId: hsId,
      email: user.email,
      firstName: user?.given_name,
      lastName: user?.family_name,
      message: data.message,
      ticketId: id,
    };
    await useReplyToTicket(replyData);
    await singleTicketEmailRefetch();

    reset();
  };

  const loading = (data) => {
    toast.promise(handleTicketReply(data), {
      loading: 'Saving...',
      success: () => `Email sent`,
      error: () => `There was an error sending your email`,
    });
  };

  return (
    <QueriesSingleEmailReplyStyled id='reply'>
      <h4>Reply to query</h4>
      <form onSubmit={handleSubmit(loading)}>
        <div className='form-group'>
          <textarea {...register('message')} />
          {errors.message ? (
            <div className='form-error'>{errors.message?.message}</div>
          ) : null}
          <button className='button button--primary' type='submit'>
            Submit
          </button>
        </div>
      </form>
    </QueriesSingleEmailReplyStyled>
  );
};

export { QueriesSingleEmailsReply };
