import styled from 'styled-components';

export const FormSendingStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: 2rem auto auto;
  padding: 3.2rem 2rem;
  background: var(--nbs-blue-light);
  border: dashed 0.2rem rgba(48, 76, 133, 0.4);
  border-radius: 0.4rem;

  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-top: 2rem;
  }
`;
