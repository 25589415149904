import { HubspotDocUploadForm } from '../../../../components/forms/hubspotDocUploadForm';
import { UploadFormStyled } from '../styles/uploadFile.styles';

const UploadFile = () => {
  return (
    <UploadFormStyled>
      <HubspotDocUploadForm />
    </UploadFormStyled>
  );
};

export { UploadFile };
