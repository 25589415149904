import styled from 'styled-components';

export const SkeletonBlogCardsStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5.2rem;
  width: 128rem;
  margin: 0 auto;
`;

export const SkeletonBlogCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  width: 100%;
  height: 47rem;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  overflow: hidden;

  --glare-bg: linear-gradient(
    90deg,
    transparent,
    hsla(0, 0%, 100%, 0.4),
    transparent
  );

  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--glare-bg);
    transform: translateX(-100%);
    animation: skeleton-glare 1.75s infinite ease-out;
    z-index: 1;
  }

  @keyframes skeleton-glare {
    to {
      transform: translateX(100%);
    }
  }

  .skeleton-blog-card__image {
    width: 100%;
    height: 25.5rem;
    background: var(--nbs-blue-light);
  }

  .skeleton-blog-card__content {
    padding: 2.4rem 2.4rem 0 2.4rem;
  }

  .skeleton-blog-card__title {
    width: 70%;
    height: 2.8rem;
    margin: 0 0 1.6rem;
    background: var(--nbs-blue-light);
    border-radius: 0.8rem;
  }

  .skeleton-blog-card__text {
    width: 100%;
    height: 1.6rem;
    margin: 0 0 0.8rem;
    background: var(--nbs-blue-light);
    border-radius: 0.8rem;
  }

  .skeleton-blog-card__button {
    width: 16rem;
    height: 4rem;
    margin-top: 3.2rem;
    background: var(--nbs-blue-light);
    border-radius: 999px;
  }
`;
