import PropTypes from 'prop-types';
import { SkeletonLoaderItem } from './';
import { SkeletonListStyled } from './styles/skeletonLoader.styles';

const SkeletonLoader = ({ items }) => {
  return (
    <SkeletonListStyled>
      {items.map((n) => (
        <SkeletonLoaderItem key={n} />
      ))}
    </SkeletonListStyled>
  );
};

export default SkeletonLoader;

SkeletonLoader.propTypes = {
  items: PropTypes.array,
};

SkeletonLoader.defaultProps = {
  items: [1, 2, 3, 4, 5],
};
