import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetHubspotUser } from '../../hooks/user';

import { MainLayout } from '../../components/layouts/mainLayout';
import { Container } from '../../components/container';
import { Wrapper } from '../../components/layout';
import { EmptyState } from '../../components/emptyState';
import { Loader } from '../../components/global';

type AuthGuardProps = {
  component: React.ComponentType;
};

export const AuthGuard = ({ component }: AuthGuardProps) => {
  const { hsUserData, isLoadingUser } = useGetHubspotUser();
  const { user, isLoading: isAuth0Loading } = useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      !isAuth0Loading &&
      !isLoadingUser &&
      hsUserData !== undefined &&
      user !== undefined
    ) {
      setLoading(false);
    }
  }, [hsUserData, user, isAuth0Loading, isLoadingUser]);

  //timeout to force a login if loading takes too long
  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) {
        navigate('/'); // Redirect to home page
      }
    }, 5000); // 5 seconds timeout

    return () => clearTimeout(timer);
  }, [loading, navigate]);

  if (loading || isAuth0Loading || isLoadingUser) {
    return (
      <MainLayout>
        <Wrapper>
          <Container>
            <Loader />
          </Container>
        </Wrapper>
      </MainLayout>
    );
  }

  if (!user?.email_verified) {
    return (
      <MainLayout>
        <Wrapper>
          <Container>
            <EmptyState
              icon='cog'
              label='Verify your email address.'
              text='Before our Energy Generators team can approve you and you have access. You need to verify your email. If you have not received the verification email please check your junk. If you have any problems verifying your email address please inform us by filling in this form:'
              link='https://share-eu1.hsforms.com/1_Ke7Itz1SjyqCLK_afQdTgevqok'
              linkExternal={true}
            />
          </Container>
        </Wrapper>
      </MainLayout>
    );
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <MainLayout>
        <Wrapper>
          <Container>
            <Loader />
          </Container>
        </Wrapper>
      </MainLayout>
    ),
  });

  if (
    !isLoadingUser &&
    hsUserData?.properties.account_status === 'registered'
  ) {
    return (
      <MainLayout>
        <Wrapper>
          <Container>
            <EmptyState
              icon='cog'
              label='Thank you for signing up.'
              text='Our Energy Generators Team will shortly approve your account and then send you a welcome email.
              If you do not receive this email within the next 24 hours, please let your Account Manager know.'
            />
          </Container>
        </Wrapper>
      </MainLayout>
    );
  }

  if (!isLoadingUser && hsUserData?.properties.account_status === 'revoked') {
    return (
      <MainLayout>
        <Wrapper>
          <Container>
            <EmptyState
              icon='warning'
              label='Account Revoked'
              text='Your Energy Generators account has been cancelled, if you believe this has been done in error please contact your client lead.'
            />
          </Container>
        </Wrapper>
      </MainLayout>
    );
  }

  if (!isLoadingUser && hsUserData?.properties.account_status === 'rejected') {
    return (
      <MainLayout>
        <Wrapper>
          <Container>
            <EmptyState
              icon='warning'
              label='Account Denied'
              text='We regret to inform you that you do not currently meet the requirements to access Energy Generators. If you are a customer of our Generation Services and believe this is in error then please contact your Client Lead to request approval.'
            />
          </Container>
        </Wrapper>
      </MainLayout>
    );
  }

  if (
    !isLoadingUser &&
    hsUserData?.properties.account_status === 'accountApproved'
  ) {
    return <Component />;
  }

  // Fallback in case no conditions match
  return (
    <MainLayout>
      <Wrapper>
        <Container>
          <Loader />
        </Container>
      </Wrapper>
    </MainLayout>
  );
};
