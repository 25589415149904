import { FooterTextStyled } from './styles/footerText.styles';

const FooterText = () => {
  return (
    <FooterTextStyled>
      <div className='footer-copyright-text'>
        <p>
          <span>&copy;</span>
          <span>2024 Npower Commercial Gas Limited.</span>
        </p>
      </div>
      <div className='footer-address-text'>
        <p>
          Npower Commercial Gas Limited Registered No. 3768856. Registered
          Office: Westwood Way, Westwood Business Park, Coventry, CV4 8LG,
          United Kingdom.
        </p>
      </div>
    </FooterTextStyled>
  );
};

export default FooterText;
