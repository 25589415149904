import { QueryEmailHeader } from '../styles/querySingleEmails.styles';

import { useState } from 'react';
import { useGetTicketEmails } from '../../../../hooks/tickets/useGetTicketEmails';
import { QueriesSingleEmailList } from './queriesSingleEmailsList';

import { QueriesSingleEmailsReply } from './queriesSingleEmailsReply';

const QueriesSingleEmails = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { singleTicketEmailData, singleTicketEmailRefetch } =
    useGetTicketEmails();

  const handleScrollToReply = () => {
    const reply = document.getElementById('reply');
    reply?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleEmailRefresh = async () => {
    setIsRefreshing(true);
    await singleTicketEmailRefetch();
    setIsRefreshing(false);
  };

  return (
    <>
      <QueryEmailHeader>
        <h2>Query history</h2>
        <span>{singleTicketEmailData?.results.length}</span>

        <div className='actions'>
          <button
            className='button-refresh'
            onClick={() => handleEmailRefresh()}
          >
            {isRefreshing ? 'Loading...' : 'Refresh'}
          </button>
          <button
            className='button-reply'
            onClick={() => handleScrollToReply()}
          >
            Add reply
          </button>
        </div>
      </QueryEmailHeader>
      <QueriesSingleEmailList emails={singleTicketEmailData?.results} />
      <QueriesSingleEmailsReply />
    </>
  );
};

export { QueriesSingleEmails };
