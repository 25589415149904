import { TicketFormData } from '../../types/tickets';
import { ids } from '../../helpers';

const useAssociateTicket = (ticketData: TicketFormData) => {
  const associationsData = [];
  const associationIds = ids.getAssociationIds();

  // Associate to contact or company
  if (ticketData.companyId) {
    associationsData.push({
      to: { id: ticketData.companyId },
      types: [
        {
          associationCategory: 'HUBSPOT_DEFINED',
          associationTypeId: associationIds
            ? associationIds.ticket_to_company
            : '',
        },
      ],
    });
  } else {
    associationsData.push({
      to: { id: ticketData.hsId },
      types: [
        {
          associationCategory: 'HUBSPOT_DEFINED',
          associationTypeId: associationIds
            ? associationIds.ticket_to_contact
            : '',
        },
      ],
    });
  }

  // Add Assignees
  if (ticketData.values.assignee && ticketData.values.assignee.length > 0) {
    const userAssociations = ticketData.values.assignee.map(
      (user: { value: string }) => ({
        to: { id: user.value },
        types: [
          {
            associationCategory: 'HUBSPOT_DEFINED',
            associationTypeId: associationIds
              ? associationIds.ticket_to_contact
              : '',
          },
        ],
      })
    );

    associationsData.push(...userAssociations);
  }

  // Add to contract
  if (ticketData.values.contract_id) {
    associationsData.push({
      to: { id: ticketData.values.contract_id },
      types: [
        {
          associationCategory: 'USER_DEFINED',
          associationTypeId: associationIds
            ? associationIds.ticket_to_contract
            : '',
        },
      ],
    });
  }

  // If its a Statement
  if (ticketData.type && ticketData.type.includes('statement')) {
    associationsData.push({
      to: { id: ticketData.values.statementNumber },
      types: [
        {
          associationCategory: 'USER_DEFINED',
          associationTypeId: associationIds
            ? associationIds.gs_statement_to_ticket
            : '',
        },
      ],
    });
  }

  if (ticketData.type && ticketData.type.includes('invoices')) {
    associationsData.push({
      to: { id: ticketData.values.invoiceNumber },
      types: [
        {
          associationCategory: 'USER_DEFINED',
          associationTypeId: associationIds
            ? associationIds.gen_services_invoice_to_ticket
            : '',
        },
      ],
    });
  }

  if (ticketData.type && ticketData.type.includes('forecasts')) {
    associationsData.push({
      to: { id: ticketData.values.forecastId },
      types: [
        {
          associationCategory: 'USER_DEFINED',
          associationTypeId: associationIds
            ? associationIds.gen_services_forecast_asset_to_ticket
            : '',
        },
      ],
    });
  }

  return associationsData;
};

export { useAssociateTicket };
