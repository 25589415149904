import styled from 'styled-components';

export const EmptyStateStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  background: rgba(255, 255, 255, 0.4);
  border: dashed 0.2rem rgba(48, 76, 133, 0.4);
  border-radius: 8px;

  svg {
    width: 3.6rem;
    margin-right: 1.6rem;
  }

  h2,
  p {
    color: var(--nbs-black);
    text-align: center;
  }

  h2 {
    margin: 2rem 0 0;

    font-weight: bold;
  }

  p {
    margin-top: 0;
  }
`;
