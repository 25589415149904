import { Container } from '../../../../components/container';

import {
  SingleItemContent,
  SingleItemAttachment,
} from '../../../../components/singleItem';
import { formatters } from '../../../../helpers';
import { ForecastsSingleContentProps } from '../types';

const ForecastsSingleContent = ({ forecast }: ForecastsSingleContentProps) => {
  return (
    <SingleItemContent>
      <Container>
        <ul>
          <li>
            <strong>Forecast From:</strong>{' '}
            {formatters.formatDate(forecast.forecast_from, 'DD/MM/YYYY')}
          </li>
          <li>
            <strong>Forecast To:</strong>{' '}
            {formatters.formatDate(forecast.forecast_to, 'DD/MM/YYYY')}
          </li>
          {forecast.forecast_estimated_volume__mwh_ ? (
            <li>
              <strong>Estimated Volume (kWh):</strong>{' '}
              {forecast.forecast_estimated_volume__mwh_}
            </li>
          ) : null}
          {forecast.forecast_attachment_url ? (
            <SingleItemAttachment
              attachmentUrl={forecast.forecast_attachment_url}
            />
          ) : null}
        </ul>
      </Container>
    </SingleItemContent>
  );
};

export { ForecastsSingleContent };
