import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProfileBlue from '../../../svg/profileBlue';
import { HeaderProfileMobile } from '../../header/headerProfileMobile';
import { useUserContext } from '../../../../providers/user/useUserContext';

import { Link } from 'react-router-dom';
// Icons
import MenuHomeBlue from '../../../svg/menuHomeBlue';
import MenuMessagesBlue from '../../../svg/menuMessagesBlue';
import MenuTicketsBlueMobile from '../../../svg/menuTicketsBlueMobile';
import ForecastsBlue from '../../../svg/forecastsBlue';
import InvoicesBlue from '../../../svg/invoicesBlue';
import StatementsBlue from '../../../svg/statementsBlue';
import DocumentsBlue from '../../../svg/documentsBlue';
import SettingsBlue from '../../../svg/settingsBlue';
import LogoutBlue from '../../../svg/logoutBlue';
import { svg } from '../../..';

//Logout
import { useAuth0 } from '@auth0/auth0-react';

const GradientColumn = styled.div`
  padding-top: 20px;
  padding-left: 40px;
  width: 30%;
`;

const MenuContainer = styled.div`
  height: 100%;
`;

const RowContainer = styled.div`
  height: 100%;
  display: flex;
`;

const CloseMenu = styled.button`
  background-color: transparent;
`;

const MenuListColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  padding-bottom: 50px;
  justify-content: space-between;
`;

const MenuListColumnContainer = styled.div`
  width: 70%;
`;

const MenuListItem = styled(Link)`
  display: flex;
  flex-direction: row;
  padding-top: 15px;
`;

const LogoutItem = styled.a`
  display: flex;
  flex-direction: row;
  padding-top: 15px;
`;

const MenuListItemText = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid #e9e5e5;
  flex-grow: 1;
  font-size: 1.6rem;
  margin-left: 15px;
`;

const MenuNotificationUserHolder = styled.div`
  padding-bottom: 60px;
`;

const ProfileNameHolder = styled.div`
  font-weight: bold;
  padding-left: 10px;
  span {
    font-weight: normal;
  }
`;

export default function FooterMobileNav({ menuState, setMenuState }) {
  const { user } = useUserContext();
  const { logout } = useAuth0();
  return (
    <div
      className={menuState ? 'mobile-nav-menu is-active' : 'mobile-nav-menu'}
    >
      <MenuContainer>
        <RowContainer>
          <GradientColumn>
            <CloseMenu onClick={() => setMenuState(false)}>
              <svg.MenuCloseWhite />
            </CloseMenu>
          </GradientColumn>
          <MenuListColumnContainer className='has-bg-white'>
            <MenuListColumn>
              <MenuNotificationUserHolder>
                <div className='is-display-flex justify-end'>
                  <HeaderProfileMobile />
                </div>
              </MenuNotificationUserHolder>
              <div>
                <MenuListItem
                  to='/app/dashboard'
                  onClick={() => setMenuState(false)}
                >
                  <div>
                    <MenuHomeBlue />
                  </div>
                  <MenuListItemText className='is-text-blue'>
                    Home
                  </MenuListItemText>
                </MenuListItem>
                <MenuListItem
                  to='/app/messages'
                  onClick={() => setMenuState(false)}
                >
                  <div>
                    <MenuMessagesBlue />
                  </div>
                  <MenuListItemText className='is-text-blue'>
                    Messages
                  </MenuListItemText>
                </MenuListItem>
                <MenuListItem
                  to='/app/queries'
                  onClick={() => setMenuState(false)}
                >
                  <div>
                    <MenuTicketsBlueMobile />
                  </div>
                  <MenuListItemText className='is-text-blue'>
                    Queries
                  </MenuListItemText>
                </MenuListItem>
                <MenuListItem
                  to='/app/forecasts'
                  onClick={() => setMenuState(false)}
                >
                  <div>
                    <ForecastsBlue />
                  </div>
                  <MenuListItemText className='is-text-blue'>
                    Forecasts
                  </MenuListItemText>
                </MenuListItem>
                <MenuListItem
                  to='/app/invoices'
                  onClick={() => setMenuState(false)}
                >
                  <div>
                    <InvoicesBlue />
                  </div>
                  <MenuListItemText className='is-text-blue'>
                    Invoices
                  </MenuListItemText>
                </MenuListItem>
                <MenuListItem
                  to='/app/statements'
                  onClick={() => setMenuState(false)}
                >
                  <div>
                    <StatementsBlue />
                  </div>
                  <MenuListItemText className='is-text-blue'>
                    Statements
                  </MenuListItemText>
                </MenuListItem>
                <MenuListItem
                  to='/app/documents'
                  onClick={() => setMenuState(false)}
                >
                  <div>
                    <DocumentsBlue />
                  </div>
                  <MenuListItemText className='is-text-blue'>
                    Documents
                  </MenuListItemText>
                </MenuListItem>
                <MenuListItem
                  to='/app/my-account'
                  onClick={() => setMenuState(false)}
                >
                  <div>
                    <SettingsBlue />
                  </div>
                  <MenuListItemText className='is-text-blue'>
                    Settings
                  </MenuListItemText>
                </MenuListItem>
                <LogoutItem
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  <div>
                    <LogoutBlue />
                  </div>
                  <MenuListItemText className='is-text-blue'>
                    Logout
                  </MenuListItemText>
                </LogoutItem>
              </div>
              <div className='is-display-flex align-center'>
                <ProfileBlue />
                <ProfileNameHolder className='is-text-blue'>
                  {user && user?.given_name ? (
                    <strong>{user?.given_name}</strong>
                  ) : (
                    <strong>{user?.email}</strong>
                  )}
                  {user?.company ? (
                    <span className='is-display-block'>{user.company}</span>
                  ) : null}
                </ProfileNameHolder>
              </div>
            </MenuListColumn>
          </MenuListColumnContainer>
        </RowContainer>
      </MenuContainer>
    </div>
  );
}

FooterMobileNav.propTypes = {
  menuState: PropTypes.bool,
  setMenuState: PropTypes.func,
  headerAccountName: PropTypes.string,
  companyName: PropTypes.string,
};
