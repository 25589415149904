import { TicketsListPriorityStyled } from './styles/ticketItem.styles';

type TicketsListItemPriorityProps = {
  priority: string | null;
};

const TicketsListItemPriority = ({
  priority,
}: TicketsListItemPriorityProps) => {
  return (
    <TicketsListPriorityStyled
      className={`ticket-priority ticket-priority--${priority}`}
    >
      {priority}
    </TicketsListPriorityStyled>
  );
};

export default TicketsListItemPriority;
