import {
  AUTHO_DEV_CONFIG,
  AUTHO_LOCAL_CONFIG,
  AUTHO_PROD_CONFIG,
} from '../../constants/auth0Config';

const getAuth0Config = () => {
  if (
    process.env.REACT_APP_AUTH_MODE === 'development' ||
    process.env.REACT_APP_AUTH_MODE === 'staging'
  ) {
    return AUTHO_DEV_CONFIG;
  } else if (process.env.REACT_APP_AUTH_MODE === 'production') {
    return AUTHO_PROD_CONFIG;
  } else if (process.env.REACT_APP_AUTH_MODE === 'local') {
    return AUTHO_LOCAL_CONFIG;
  }
};

export { getAuth0Config };
