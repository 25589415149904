import { Alert } from '../../../../../components/alert';
import { MessagesInboxListItemMobile } from './messagesInboxListItemMobile';
import { useMessagesContext } from '../../../../../providers/messages/useMessagesContext';

import SimpleBar from 'simplebar-react';

type MessagesInboxListMobileProps = {
  setSingleMessageActive: (value: boolean) => void;
};

const MessagesInboxListMobile = ({
  setSingleMessageActive,
}: MessagesInboxListMobileProps) => {
  const { messages, messagesLoading, messagesError } = useMessagesContext();

  if (messagesLoading) {
    return (
      <>
        <p>Loading messages</p>
      </>
    );
  }

  if (messagesError) {
    return (
      <>
        <Alert
          type='error'
          message='There was a problem loading your messages, please try again'
        />
      </>
    );
  }

  return (
    <SimpleBar
      className='inbox-list-simplebar'
      autoHide={true}
      style={{
        maxHeight: 700,
        width: '100%',
      }}
      // forcehidden='x'
    >
      {messages.length > 0 &&
        messages.map((message) => (
          <MessagesInboxListItemMobile
            key={message.id}
            message={message}
            setSingleMessageActive={setSingleMessageActive}
          />
        ))}
    </SimpleBar>
  );
};

export { MessagesInboxListMobile };
