import styled from 'styled-components';

export const DividerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 3rem 0;

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    height: 0.2rem;
    background: #ebebeb;
    content: '';
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  span {
    position: relative;
    padding: 0 4rem;
    color: var(--nbs-black);
    font-size: 1.4rem;
    background: #fff;
    z-index: 10;
  }
`;
