export default function ForecastsIcon() {
  return (
    <svg
      width='41'
      height='43'
      viewBox='0 0 41 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.611534 16.6128H7.8279V42.6342H0.611534V16.6128ZM11.4361 10.6079H18.6525V42.6342H11.4361V10.6079ZM22.2606 0.599609H29.477V42.6342H22.2606V0.599609ZM33.0852 14.6111H40.3016V42.6342H33.0852V14.6111Z'
        fill='white'
      />
    </svg>
  );
}
