import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IonLabel } from '@ionic/react';
import { Link } from 'react-router-dom';

const MyIonItem = styled(Link)`
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 88px;
  --padding: 0 1rem;
  @media (min-width: 1280px) {
    padding: 0 1.7rem;
  }
  .nav-link {
    margin: 0;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;
export default function NavItem(props) {
  const { navItemUrl, navItemTitle } = props;
  const displayNavItem = () => {
    if (navItemUrl) {
      return <IonLabel className='nav-link'>{navItemTitle}</IonLabel>;
    }
  };

  return (
    <MyIonItem
      lines='none'
      color='none'
      className='is-text-uppercase is-text-white is-font-bold'
      to={navItemUrl}
      tabIndex='0'
      role='menuitem'
    >
      {displayNavItem()}
    </MyIonItem>
  );
}

NavItem.propTypes = {
  navItemUrl: PropTypes.string,
  navItemTitle: PropTypes.string,
  dropdownState: PropTypes.bool,
};
