import styled from 'styled-components';

export const FormInfoStyled = styled.div`
  display: flex;
  margin: 1.6rem 0;
  padding: 1.2rem 1.6rem;
  background: var(--nbs-light-blue);
  border-radius: 0.4rem;

  p {
    margin: 0;
    color: var(--nbs-navy-blue);
    font-size: 1.4rem;
    font-weight: bold;
  }
`;
