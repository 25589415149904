import styled from 'styled-components';

export const NotificationsDropdownBadgeStyled = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  width: 1.6rem;
  height: 1.6rem;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  background-color: var(--nbs-red);
  border-radius: 50%;
  z-index: 10;
`;
