import { useAuth0 } from '@auth0/auth0-react';
import { RegisterButtonStyled } from './styles/registerButton.styles';

const RegisterButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleRegisterClick = () => {
    loginWithRedirect({
      appState: {
        returnTo: '/app/dashboard',
      },
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  };

  return (
    <RegisterButtonStyled onClick={() => handleRegisterClick()}>
      Sign up
    </RegisterButtonStyled>
  );
};

export { RegisterButton };
