import styled from 'styled-components';

export const AuthLoadingStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 2rem;
    font-weight: bold;
  }
`;
