import axios from 'axios';
import { ObjectIds } from '../../../types/objects';
import { GetTicketRequest } from './types';

import { appHelpers, ids } from '../../../helpers';
import { TICKETS_PROPERTIES } from '../../../constants/ticketObjectProperties';

const getSingleTicket = async (requestData: GetTicketRequest) => {
  if (!requestData) {
    throw new Error('Unable to fetch invoice');
  }

  const { ticketId, hsId } = requestData;

  const objectIds: ObjectIds | null = ids.getObjectIds();
  const pipelineIds = ids.getPipelineIds();

  const BODY = {
    id: ticketId,
    hsId: hsId,
    properties: TICKETS_PROPERTIES,
    pipelineId: pipelineIds ? pipelineIds.tickets : null,
    objectType: objectIds ? objectIds.tickets : null,
  };

  const CONFIG = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/tickets/getSingleTicket`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching ticket: ${err.message}`);
    } else {
      throw new Error(`Error fetching ticket`);
    }
  }
};

export { getSingleTicket };
