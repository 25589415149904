export default function SettingsBlue() {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.5325 12.5575C19.5775 12.22 19.6 11.8712 19.6 11.5C19.6 11.14 19.5775 10.78 19.5213 10.4425L21.805 8.66495C22.0075 8.50745 22.0638 8.2037 21.94 7.9787L19.78 4.2437C19.645 3.9962 19.3638 3.91745 19.1163 3.9962L16.4275 5.0762C15.865 4.6487 15.2688 4.2887 14.605 4.0187L14.2 1.1612C14.155 0.891201 13.93 0.699951 13.66 0.699951H9.34C9.07 0.699951 8.85625 0.891201 8.81125 1.1612L8.40625 4.0187C7.7425 4.2887 7.135 4.65995 6.58375 5.0762L3.895 3.9962C3.6475 3.9062 3.36625 3.9962 3.23125 4.2437L1.0825 7.9787C0.947501 8.21495 0.992501 8.50745 1.2175 8.66495L3.50125 10.4425C3.445 10.78 3.4 11.1512 3.4 11.5C3.4 11.8487 3.4225 12.22 3.47875 12.5575L1.195 14.335C0.992501 14.4925 0.936251 14.7962 1.06 15.0212L3.22 18.7562C3.355 19.0037 3.63625 19.0825 3.88375 19.0037L6.5725 17.9237C7.135 18.3512 7.73125 18.7112 8.395 18.9812L8.8 21.8387C8.85625 22.1087 9.07 22.3 9.34 22.3H13.66C13.93 22.3 14.155 22.1087 14.1888 21.8387L14.5938 18.9812C15.2575 18.7112 15.865 18.3512 16.4163 17.9237L19.105 19.0037C19.3525 19.0937 19.6338 19.0037 19.7688 18.7562L21.9288 15.0212C22.0638 14.7737 22.0075 14.4925 21.7938 14.335L19.5325 12.5575ZM11.5 15.55C9.2725 15.55 7.45 13.7275 7.45 11.5C7.45 9.27245 9.2725 7.44995 11.5 7.44995C13.7275 7.44995 15.55 9.27245 15.55 11.5C15.55 13.7275 13.7275 15.55 11.5 15.55Z'
        fill='#023F85'
      />
    </svg>
  );
}
