import { appHelpers } from '../../../../helpers';

export const footerLinks = {
  company: [
    {
      id: appHelpers.generateRandomId(),
      title: 'About Us',
      url: 'https://npowerbusinesssolutions.com/about',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Sitemap',
      url: 'https://npowerbusinesssolutions.com/sitemap.xml',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Media Centre',
      url: 'mailto:business@npower.com',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'nBS Foundation',
      url: 'https://npowerbusinesssolutions.com/foundation',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Work with Us',
      url: 'https://jobs.eon.com',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Our Fuel Mix',
      url: 'https://npowerbusinesssolutions.com/company/fuel-mix',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Carbon Reduction Plan',
      url: 'https://npowerbusinesssolutions.com/hubfs/NCGL_Carbon_Reduction_Plan_Dec_23.docx.pdf',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Our Addresses',
      url: 'https://npowerbusinesssolutions.com/company/addresses',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Financial Results',
      url: 'https://npowerbusinesssolutions.com/company/financial-results',
    },
  ],
  information: [
    {
      id: appHelpers.generateRandomId(),
      title: 'Cookies',
      url: 'https://npowerbusinesssolutions.com/information/cookies',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Privacy',
      url: 'https://npowerbusinesssolutions.com/information/privacy-policy',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Standard of Conduct',
      url: 'https://npowerbusinesssolutions.com/information/standards',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Terms & Conditions',
      url: 'https://npowerbusinesssolutions.com/terms-and-conditions',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Accessibility',
      url: 'https://npowerbusinesssolutions.com/information/accessibility',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Human Rights',
      url: 'https://www.eon.com/en/about-us/sustainability/corporate-governance/human-rights.html',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Modern Slavery Statement',
      url: 'https://npowerbusinesssolutions.com/information/modern-slavery',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Phone Charge Information',
      url: 'https://npowerbusinesssolutions.com/information/phone-charges',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Section 172 of the Companies Act 2006',
      url: 'https://npowerbusinesssolutions.com/information/section-172',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Gender Pay Gap',
      url: 'https://npowerbusinesssolutions.com/information/gender-pay-gap',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Whistle Blowing',
      url: 'https://npowerbusinesssolutions.com/information/whistle-blowing',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Environmental Information Request',
      url: 'https://npowerbusinesssolutions.com/information/whistle-blowing',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Market-wide Half-Hourly Settlement',
      url: 'https://npowerbusinesssolutions.com/market-wide-half-hourly-settlement',
    },
  ],
  resources: [
    {
      id: appHelpers.generateRandomId(),
      title: 'Case Studies',
      url: 'https://npowerbusinesssolutions.com/case-studies',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Blogs',
      url: 'https://npowerbusinesssolutions.com/resources/tag/blog',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Webinars',
      url: 'https://npowerbusinesssolutions.com/resources/tag/webinar',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Podcasts',
      url: 'https://npowerbusinesssolutions.com/resources/tag/podcast',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Downloads',
      url: 'https://npowerbusinesssolutions.com/resources/tag/download',
    },
  ],
  account: [
    {
      id: appHelpers.generateRandomId(),
      title: 'Manage your Account',
      url: 'https://npowerbusinesssolutions.com/my-account',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Payments',
      url: 'https://npowerbusinesssolutions.com/my-account/payments',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Invoice Information',
      url: 'https://npowerbusinesssolutions.com/my-account/invoices',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Charges',
      url: 'https://npowerbusinesssolutions.com/non-commodity-charges',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Third Party Charges',
      url: 'https://npowerbusinesssolutions.com/third-party-and-industry-charges',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Non-commodity Charges',
      url: 'https://npowerbusinesssolutions.com/changes-to-your-non-commodity-charges',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Meters',
      url: 'https://npowerbusinesssolutions.com/my-account/metering',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Supply',
      url: 'https://npowerbusinesssolutions.com/my-account/supply',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Moving Premises',
      url: 'https://npowerbusinesssolutions.com/my-account/moving-premises',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Dashboard',
      url: 'https://nbsdashboard.npower.com',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Risk Navigator',
      url: 'https://npowerbusinesssolutions.com/my-account/risk-navigator',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Intelligent Analytics',
      url: 'https://npowerbusinesssolutions.com/my-account/intelligent-analytics',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Marketing and Data Preferences',
      url: 'https://npowerbusinesssolutions.com/my-account',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Glossary',
      url: 'https://npowerbusinesssolutions.com/my-account/glossary',
    },
  ],
  products: [
    {
      id: appHelpers.generateRandomId(),
      title: 'Buy Energy',
      url: 'https://npowerbusinesssolutions.com/buy',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Manage Usage',
      url: 'https://npowerbusinesssolutions.com/large/manage-usage',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'New Connections',
      url: 'https://npowerbusinesssolutions.com/large/new-connections',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Net Zero',
      url: 'https://npowerbusinesssolutions.com/large/net-zero',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Compliance',
      url: 'https://npowerbusinesssolutions.com/large/compliance',
    },
    {
      id: appHelpers.generateRandomId(),
      title: 'Generation',
      url: 'https://npowerbusinesssolutions.com/corporate/generation',
    },
  ],
};
