import { useContext } from 'react';
import { UserContext } from './userContext';

const useUserContext = () => {
  const appUser = useContext(UserContext);

  if (appUser === undefined) {
    throw new Error('useUserContext can only be used inside of UserProvider');
  }

  return appUser;
};

export { useUserContext };
