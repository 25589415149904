import { appHelpers } from '../../../helpers';
import { STATEMENT_PROPERTIES } from '../../../constants/statementObjectProperties';
import { ids } from '../../../helpers';

import axios from 'axios';

import { ApiRequestData } from '../../../types/api';
import { AssociationRequestData } from '../documents/types';

const getAllStatements = async (requestData: ApiRequestData) => {
  if (!requestData) {
    throw new Error('Unable to fetch statements');
  }

  const { hsId, companyId, uid } = requestData;

  const associationRequestData: AssociationRequestData = {
    objectFromType: null,
    recordId: null,
  };

  const objectIds = ids.getObjectIds();
  const pipelineIds = ids.getPipelineIds();

  if (companyId) {
    associationRequestData.objectFromType = objectIds
      ? objectIds.companies
      : null;
    associationRequestData.recordId = companyId;
  } else {
    associationRequestData.objectFromType = objectIds
      ? objectIds.contacts
      : null;
    associationRequestData.recordId = hsId;
  }

  const BODY = {
    objectType: objectIds ? objectIds.statements : null,
    properties: STATEMENT_PROPERTIES,
    hsId: hsId,
    uid: uid,
    companyId: companyId,
    pipelineId: pipelineIds ? pipelineIds.statements : null,
    associationData: associationRequestData,
  };

  const CONFIG = {
    url: `${appHelpers.getApiUrl()}/api/v2/statements/getStatements/all`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching statements: ${err.message}`);
    } else {
      throw new Error(`Error fetching statements`);
    }
  }
};

export { getAllStatements };
