import { timeDate } from '../../../../helpers';
import { api } from '../../../../api/apiHandler';
import { ids } from '../../../../helpers';

type EmailData = {
  hsId: string;
  email: string;
  firstName: string;
  lastName: string;
  message: string;
  ticketId: string;
};

const useReplyToTicket = async (data: EmailData) => {
  const timeStamp = timeDate.createTimeStamp();

  const associationIds = ids.getAssociationIds();

  const emailHeaders = {
    from: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
    },
    sender: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
    },
    to: [
      {
        email:
          'npower Business Solutions Energy Generators Team<energygenerators@npowerbusinesssolutions.com>',
      },
    ],
  };

  const associations = [
    {
      to: { id: data.hsId },
      types: [
        {
          associationCategory: 'HUBSPOT_DEFINED',
          associationTypeId: associationIds
            ? associationIds.email_to_contact
            : null,
        },
      ],
    },
    {
      to: { id: data.ticketId },
      types: [
        {
          associationCategory: 'HUBSPOT_DEFINED',
          associationTypeId: associationIds
            ? associationIds.email_to_ticket
            : null,
        },
      ],
    },
  ];

  const emailData = {
    subject: `Update to ticket #${data.ticketId}`,
    message: data.message,
    headers: emailHeaders,
    timeStamp: timeStamp,
    associations: associations,
  };

  return await api(emailData).email.createEmail();
};

export { useReplyToTicket };
