import { useLocation } from 'react-router';
import { Container } from '../../../components/container';
import { Wrapper } from '../../../components/layout';
import { MainLayout } from '../../../components/layouts/mainLayout';

export const AuthError = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get('error');
  const errorDescription = queryParams.get('error_description');
  return (
    <MainLayout>
      <Wrapper>
        <Container>
          <h1>An Error Occurred</h1>
          {error && (
            <p>
              <strong>Error:</strong> {error}
            </p>
          )}
          {errorDescription && (
            <p>
              <strong>Description:</strong> {errorDescription}
            </p>
          )}
        </Container>
      </Wrapper>
    </MainLayout>
  );
};
