import { AddQueryDropdownProps, ForecastItem } from './types';
import { useGetAllForecasts } from '../../../../hooks/forecasts';

const AddQueryForecastsDropdown = ({ register }: AddQueryDropdownProps) => {
  const { forecastsData, isForecastsLoading } = useGetAllForecasts();

  return (
    <div className='form-group form-group--select'>
      <label htmlFor='forecastId'>Forecast reference</label>
      <div className='select-wrapper'>
        <select id='forecastId' {...register('forecastId')}>
          {isForecastsLoading ? (
            <option selected disabled value=''>
              Loading forecasts
            </option>
          ) : null}
          {!isForecastsLoading && forecastsData.results.length > 0 ? (
            <>
              <option selected disabled value=''>
                Select forecast
              </option>
              {forecastsData.results.map((forecast: ForecastItem) => (
                <option key={forecast.id} value={forecast.id}>
                  {forecast.mpxn}
                </option>
              ))}
            </>
          ) : (
            <>
              <option selected disabled value=''>
                No forecasts
              </option>
            </>
          )}
        </select>
      </div>
    </div>
  );
};

export { AddQueryForecastsDropdown };
