import { useState } from 'react';
import { FieldErrors } from 'react-hook-form';

type FileAttachmentProps = {
  setValue: (name: string, value: File | null) => void;
  id?: string;
  errors?: FieldErrors;
};

function FileAttachment({
  setValue,
  id = 'default',
  errors,
}: FileAttachmentProps) {
  const [formFileName, setFormFileName] = useState<string | null>(null);

  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let sanitisedFile: File | null = null;

    if (e.currentTarget.files) {
      const file: File = e.currentTarget.files[0] as File;
      const sanitisedName = file.name.replace(/[^\w.]/gi, '');
      sanitisedFile = new File([file], sanitisedName, {
        type: file.type,
      });
    }

    setValue(`attachment_${id}`, sanitisedFile);
    setFormFileName(sanitisedFile?.name || null);
  };

  return (
    <>
      <div
        className={`form-group form-group--file-upload ${
          errors?.[`attachment_${id}`] ? 'has-error' : ''
        }`}
      >
        <label htmlFor={`attachment_${id}`}>Upload file</label>
        <input
          className='form-control'
          id={`attachment_${id}`}
          onChange={handleOnChange}
          type='file'
        />
        {formFileName ? (
          <>
            <p>{formFileName}</p>
            <button
              className='button button--remove'
              onClick={(e) => {
                e.preventDefault();
                setFormFileName(null);
                setValue(`attachment_${id}`, null);
              }}
            >
              Remove
            </button>
          </>
        ) : null}
        {(errors?.[`attachment_${id}`]?.message && (
          <p className='form-error'>
            {String(errors[`attachment_${id}`]?.message)}
          </p>
        )) ??
          null}
      </div>
    </>
  );
}

export default FileAttachment;
