import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-height: 95vh;
  max-width: 42rem;
  // padding: 4rem 0;
  transform: translate3d(-50%, calc(-50% + 1.6rem), 0);
  background: #fff;
  border-radius: 0.8rem;
  box-shadow: 2px 4px 9px rgba(2, 63, 133, 0.1);
  z-index: 9900;
  opacity: ${(props) => (props.modalActiveState ? 1 : 0)};
  visibility: ${(props) => (props.modalActiveState ? 'visible' : 'hidden')};
  transition: all 0.3s ease-in-out;
  transition-delay: ${(props) => (props.modalActiveState ? '0s' : '0s')};

  &.is-active {
    transform: translate3d(-50%, -50%, 0);
  }

  &::-webkit-scrollbar-thumb {
    background: red;
  }

  h2,
  button,
  form {
    padding: 0 3rem;
  }

  h2 {
    color: var(--nbs-blue);
    text-align: center;
    margin-top: 0;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
    padding: 0;
    color: var(--nbs-black);
    font-weight: bold;
    background: transparent;
    z-index: 100;
  }

  .simplebar-vertical {
    right: 0.8rem;

    .simplebar-scrollbar:before {
      background: var(--nbs-blue);
    }
  }

  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }
`;
