import PropTypes from 'prop-types';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #023f85;
  opacity: ${(props) => (props.modalActiveState ? 0.5 : 0)};
  visibility: ${(props) => (props.modalActiveState ? 'visible' : 'hidden')};
  transition-delay: ${(props) => (props.modalActiveState ? '0s' : '0.4s')};
  z-index: 9000;
`;

export default function ModalOverlay({ modalActiveState }) {
  return <Overlay modalActiveState={modalActiveState}></Overlay>;
}

ModalOverlay.propTypes = {
  modalActiveState: PropTypes.bool,
};
