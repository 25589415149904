import { QueriesListItemPriorityStyled } from '../styles/queriesListItem.styles';

type QueriesListItemPriorityProps = {
  priority: string | null;
};

const QueriesListItemPriority = ({
  priority,
}: QueriesListItemPriorityProps) => {
  return (
    <QueriesListItemPriorityStyled
      className={`ticket-priority ticket-priority--${priority}`}
    >
      {priority}
    </QueriesListItemPriorityStyled>
  );
};

export { QueriesListItemPriority };
