export default function SuccessIcon() {
  return (
    <svg
      width='108'
      height='108'
      viewBox='0 0 108 108'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_499_8670)'>
        <path
          d='M54.0224 2.22754C25.6949 2.22754 2.72241 25.2 2.72241 53.5275C2.72241 81.855 25.6949 104.828 54.0224 104.828C82.3499 104.828 105.322 81.855 105.322 53.5275C105.322 25.2 82.3724 2.22754 54.0224 2.22754Z'
          fill='white'
        />
        <path
          d='M65.0925 42.6146C56.88 46.5746 51.6825 52.6946 48.8475 56.9246C46.395 54.0671 43.9875 52.1771 43.8525 52.0646C42.435 50.9621 40.3875 51.2096 39.285 52.6271C38.1825 54.0446 38.43 56.0921 39.825 57.2171C39.87 57.2396 43.9425 60.4796 46.35 64.5746C46.935 65.5871 48.015 66.1946 49.1625 66.1946H49.3425C50.5575 66.1046 51.6375 65.3621 52.1325 64.2371C52.1775 64.1471 56.7675 53.8871 67.905 48.5096C69.525 47.7221 70.2225 45.7871 69.435 44.1671C68.6475 42.5471 66.7125 41.8496 65.07 42.6371'
          fill='#033F85'
        />
        <path
          d='M54 85.95C36.36 85.95 22.05 71.64 22.05 54C22.05 36.36 36.3375 22.05 54 22.05C71.6625 22.05 85.9275 36.36 85.9275 54C85.9275 71.64 71.64 85.95 54 85.95ZM54 0C24.165 0 0 24.165 0 54C0 83.835 24.165 108 54 108C83.835 108 108 83.835 108 54C108 24.165 83.8125 0 54 0Z'
          fill='#033F85'
        />
      </g>
      <defs>
        <clipPath id='clip0_499_8670'>
          <rect width='108' height='108' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
