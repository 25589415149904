import { Link } from 'react-router-dom';
import HeaderLogoImage from './headerLogoImage';
import { HeaderLogoStyled } from './styles/headerLogo.styles';

import { useUserContext } from '../../providers/user/useUserContext';

const HeaderLogo = () => {
  const { isAuthenticated } = useUserContext();
  return (
    <HeaderLogoStyled>
      {isAuthenticated ? (
        <Link to='/app/dashboard'>
          <HeaderLogoImage />
        </Link>
      ) : (
        <HeaderLogoImage />
      )}
    </HeaderLogoStyled>
  );
};

export default HeaderLogo;
