import FooterList from './footerList';
import FooterLogo from './footerLogo/footerLogo';
import { FooterText } from './footerText';

import {
  FooterStyled,
  FooterRowStyled,
  FooterColumnStyled,
} from './styles/footer.styles';

// Styles
// import './footer.styles.scss';
//
import { FooterContact } from './footerContact/';
import Container from '../../layout/container/container';
import { footerLinks } from './data/footerLinks';
import { FooterSocialLinks } from './footerSocialLinks';

export default function Footer() {
  return (
    <FooterStyled>
      <Container>
        <FooterRowStyled>
          <FooterColumnStyled>
            <FooterList listTitle='COMPANY' listMap={footerLinks.company} />
          </FooterColumnStyled>
          <FooterColumnStyled>
            <FooterList
              listTitle='INFORMATION'
              listMap={footerLinks.information}
            />
          </FooterColumnStyled>
          <FooterColumnStyled>
            <FooterContact />
            <FooterSocialLinks />
          </FooterColumnStyled>
        </FooterRowStyled>
        <FooterRowStyled>
          <FooterColumnStyled>
            <FooterList listTitle='RESOURCES' listMap={footerLinks.resources} />
          </FooterColumnStyled>
          <FooterColumnStyled>
            <FooterList listTitle='MY ACCOUNT' listMap={footerLinks.account} />
          </FooterColumnStyled>
          <FooterColumnStyled>
            <FooterList
              listTitle='PRODUCTS & SOLUTIONS'
              listMap={footerLinks.products}
            />
          </FooterColumnStyled>
        </FooterRowStyled>
        <FooterLogo />
        <FooterText />
      </Container>
    </FooterStyled>
  );
}
