const sortStringByAsc = (arr, key) => {
  const sortedArray = [...arr]; // Make a copy of the array
  sortedArray.sort((a, b) => {
    const valueA = a[key].toLowerCase();
    const valueB = b[key].toLowerCase();
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  });
  return sortedArray;
};

export { sortStringByAsc };
