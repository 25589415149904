// Components
import {
  Card,
  CardCol,
  CardColLabel,
  CardColContent,
  CardColDate,
  CardColStatus,
  CardColDropdownToggle,
  CardColDropdownContent,
} from '../../../../components/card';
import {
  QueriesListItemBullets,
  QueriesListItemReadButtonStyled,
} from '../styles/queriesListItem.styles';
import { QueriesListItemPriority } from './queriesListItemPriority';
import { Link } from 'react-router-dom';

// Types
import { QueriesListProps } from '../types';

// Hooks
import { useState } from 'react';

// Helpers
import { formatters } from '../../../../helpers';

const QueriesListItem = ({ query }: QueriesListProps) => {
  const [dropdownState, setDropdownState] = useState(false);

  return (
    <>
      <Card className='pod-card-tickets' cols='5' data-testid='ticket-card'>
        <div
          className='pod-card pod-card--tickets ticket'
          data-testid='ticket-list-item'
          onClick={() => setDropdownState(!dropdownState)}
        >
          <CardCol className='ticket__date'>
            {query.closedDate ? (
              <>
                <CardColLabel label='Date closed' />
                <CardColDate date={query.closedDate} />
              </>
            ) : (
              <>
                <CardColLabel label='Date opened' />
                <CardColDate date={query.createdAt} />
              </>
            )}
          </CardCol>
          <CardCol>
            <CardColLabel
              className='ticket__date-label'
              label='Query subject'
            />
            <CardColContent
              className='ticket__subject'
              content={query.subject}
            />
          </CardCol>
          <CardCol>
            {query.source ? (
              <>
                <CardColLabel label='Query source' />
                <CardColContent content={query.source} />
              </>
            ) : null}
          </CardCol>
          <CardCol className='ticket__submitted-by'>
            {query.submittedBy ? (
              <>
                <CardColLabel label='Submitted by' />
                <CardColContent content={query.submittedBy} />
              </>
            ) : null}
          </CardCol>
          {query.pipelineStage ? (
            <CardColStatus
              className='ticket__status full_width_badge'
              status={query.pipelineStage}
            />
          ) : null}
          <CardColDropdownToggle
            dropdownState={dropdownState}
            setDropdownState={setDropdownState}
          />
        </div>
      </Card>
      <CardColDropdownContent dropdownState={dropdownState}>
        <header>
          <QueriesListItemPriority priority={query.priority} />
          <h4>{query.subject}</h4>
        </header>
        <QueriesListItemBullets>
          {query.estimatedResponseDate ? (
            <li>
              Estimated response date :{' '}
              {formatters.formatDate(query.estimatedResponseDate, 'DD/MM/YY')}
            </li>
          ) : null}
          {query.lastActivityDate ? (
            <li>
              Last activity date :{' '}
              {formatters.formatDate(
                query.lastActivityDate,
                'DD/MM/YY - HH:mm'
              )}
            </li>
          ) : null}
          {query.lastMessageSentAt ? (
            <li>
              Last response date :{' '}
              {formatters.formatDate(
                query.lastMessageSentAt,
                'DD/MM/YY - HH:mm'
              )}
            </li>
          ) : null}
          {query.closedDate ? (
            <li>
              Closed date :{' '}
              {formatters.formatDate(query.closedDate, 'DD/MM/YY')}
            </li>
          ) : null}
        </QueriesListItemBullets>
        {query.content ? (
          <>
            <h5>Query description</h5>
            {query.category ? <p>Category: {query.category}</p> : null}
            {query.statementId ? (
              <Link to={'/statements/' + query.statementId}>
                View Statement
              </Link>
            ) : null}
            {query.invoiceId ? (
              <Link to={'/invoices/' + query.invoiceId}>View Invoice</Link>
            ) : null}
            {query.forecastId ? (
              <Link to={'/forecasts/' + query.forecastId}>View Forecast</Link>
            ) : null}
            <p>{query.content}</p>
            {query.owner ? (
              <p>Team member handling your query : {query.owner}</p>
            ) : null}
          </>
        ) : null}
        <p>
          <QueriesListItemReadButtonStyled
            className='ticket-link'
            to={`/app/queries/${query.id}`}
          >
            View query
          </QueriesListItemReadButtonStyled>
        </p>
      </CardColDropdownContent>
    </>
  );
};

export { QueriesListItem };
