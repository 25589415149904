import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from '../../../config/firebase';

const uploadFile = async ({ clientName, uploadType, file }) => {
  if (!clientName || !uploadType || !file) {
    throw new Error('Invalid requestData object');
  }

  const filePath = `uploads/${clientName}/${uploadType}/${file.name}`;

  const storage = getStorage();

  const storageRef = ref(storage, filePath);

  try {
    await uploadBytes(storageRef, file);
  } catch (err) {
    throw new Error('Error uploading file: ', err);
  }

  try {
    const fileDownloadUrl = await getDownloadURL(ref(storage, filePath));

    return fileDownloadUrl;
  } catch (err) {
    throw new Error('Error getting download URL: ', err);
  }
};

export { uploadFile };
