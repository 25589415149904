import { useMessagesContext } from '../../../../../providers/messages/useMessagesContext';

import {
  ActiveMessageStyled,
  ActiveMessageHeaderStyled,
  ActiveMessageBodyStyled,
  ActiveMessageFooterStyled,
} from '../../style/activeMessage.styles';
import { ProfileBlue } from '../../../../../components/svg';
import SimpleBar from 'simplebar-react';

// Helpers
import { formatters } from '../../../../../helpers';

// Hooks
import { useMessagesArchivedStatusUpdate } from '../../../../../hooks/messages/useMessagesArchivedStatusUpdate';
import { EmptyState } from '../../../../../components/emptyState';

const MessagesInboxActiveMessage = () => {
  const { activeMessage } = useMessagesContext();
  const { handleUpdateArchiveStatus } = useMessagesArchivedStatusUpdate();

  if (!activeMessage) {
    return (
      <ActiveMessageStyled>
        <div className='p-y-lg p-x-md'>
          <EmptyState
            icon='warning'
            label='No messages found'
            text='Your inbox is empty'
          />
        </div>
      </ActiveMessageStyled>
    );
  }
  return (
    <ActiveMessageStyled>
      <SimpleBar
        autoHide={true}
        style={{
          maxHeight: 700,
          width: '100%',
        }}
      >
        <ActiveMessageHeaderStyled>
          <h1>{activeMessage.subject || 'No subject'}</h1>
          <div className='message-from'>
            <span className='message-from__avatar'>
              <ProfileBlue />
            </span>
            <span>{activeMessage.from}</span>
          </div>
          <span className='message-date'>
            {formatters.formatUnixDate(activeMessage.updatedOn, 'DD/MM/YYYY')}
          </span>
        </ActiveMessageHeaderStyled>
        <ActiveMessageBodyStyled>
          <div
            dangerouslySetInnerHTML={{ __html: activeMessage.messageBody }}
          ></div>
        </ActiveMessageBodyStyled>
        <ActiveMessageFooterStyled>
          <button
            onClick={() => {
              handleUpdateArchiveStatus(activeMessage.id);
            }}
          >
            Archive message
          </button>
        </ActiveMessageFooterStyled>
      </SimpleBar>
    </ActiveMessageStyled>
  );
};

export { MessagesInboxActiveMessage };
