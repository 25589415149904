import styled from 'styled-components';

export const ContainerStyled = styled.section`
  width: 100%;
  max-width: 123.6rem;
  margin: 0 auto;
  padding: 0 0.8rem;
  @media (min-width: 1280px) {
    max-width: 128rem;
    height: auto;
    padding: 0 2.5rem;
  }
`;
