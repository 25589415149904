import { PaginationProps } from './types';
import {
  PaginationStyled,
  PaginationListStyled,
  PaginationButtonStyled,
} from './styles/pagination.styles';

const Pagination = ({
  totalItems,
  itemsPerPage,
  setCurrentPage,
  currentPage,
}: PaginationProps) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // If there's only one page, do not render the pagination.
  if (totalPages <= 1) {
    return null;
  }

  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <PaginationStyled>
      <PaginationListStyled>
        {pages.map((page) => (
          <li key={page}>
            <PaginationButtonStyled
              onClick={() => setCurrentPage(page)}
              className={currentPage === page ? 'is-active' : undefined}
            >
              {page}
            </PaginationButtonStyled>
          </li>
        ))}
      </PaginationListStyled>
    </PaginationStyled>
  );
};

export default Pagination;
