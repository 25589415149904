import styled from 'styled-components';

export const HeroStyled = styled.section`
  display: grid;
  padding: 3.2rem 0;
  background-color: #d2e2f4;

  @media (min-width: 992px) {
    padding: 8rem 0;
  }

  h1,
  p {
    margin: 0;
    color: var(--nbs-navy-blue);
  }

  h1 {
    font-size: 2.4rem;
    font-weight: 900;

    @media (min-width: 992px) {
      // margin-bottom: 1.6rem;
      font-size: 3.2rem;
    }
  }

  .hero-content {
    padding: 0 4rem;
    @media (min-width: 992px) {
      // grid-column: 2 / 4;
    }
  }

  .hero-image {
    display: none;
    position: relative;
    left: -1.2rem;

    img {
      position: sticky;
      top: 1.6rem;
    }

    @media (min-width: 1400px) {
      display: block;
    }
  }

  .hero-items {
    width: 100%;

    @media (min-width: 1400px) {
      max-width: 102rem;
    }
  }

  .hero-actions {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 4rem;

    @media (min-width: 667px) {
      flex-direction: row;
      margin-top: 4rem;
    }

    @media (min-width: 992px) {
      margin-top: 5.2rem;
    }
  }

  .hero {
    display: grid;

    @media (min-width: 992px) {
    }

    @media (min-width: 1400px) {
      grid-template-columns: 40rem 1fr;
      grid-gap: 4rem;
    }
  }
`;
