import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../providers/user/useUserContext';
import { AccountMenuDropdownProps } from './types';

import { AccountIcon, LogoutIcon } from '../svg';

import {
  DropdownMenu,
  List,
  ListItem,
} from './styles/headerAccountMenuDropdown.styles';
import SettingsIcon from '../svg/settingsIcon';

const AccountMenuDropdown = ({
  dropdownState,
  setDropdownState,
}: AccountMenuDropdownProps) => {
  const { logout } = useAuth0();
  const { user } = useUserContext();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <DropdownMenu
      className={dropdownState ? `account-menu is-active` : 'account-menu'}
    >
      <header>
        <h3>Account</h3>
      </header>
      <div className='menu_bg_line'></div>
      <List data-testid='usermenutest'>
        <ListItem className='is-display-flex align-center usermenu-item'>
          <div className='icon-wrapper'>
            <AccountIcon />
          </div>
          <div className='list-link'>
            {user && user?.given_name ? (
              <strong>{user?.given_name}</strong>
            ) : (
              <strong>{user?.email}</strong>
            )}
          </div>
        </ListItem>
        <ListItem className='is-display-flex align-center'>
          <Link to='/app/my-account' onClick={() => setDropdownState(false)}>
            <div className='icon-wrapper'>
              <SettingsIcon />
            </div>
            <div className='list-link'>
              <div>Settings</div>
            </div>
          </Link>
        </ListItem>
        <ListItem className='is-display-flex align-center'>
          <button
            onClick={() => {
              setDropdownState(false);
              handleLogout();
            }}
          >
            <div className='icon-wrapper'>
              <LogoutIcon />
            </div>
            <div className='list-link'>Log out</div>
          </button>
        </ListItem>
      </List>
    </DropdownMenu>
  );
};

export { AccountMenuDropdown };
