export default function DownloadWhite() {
  return (
    <svg
      width='23'
      height='17'
      viewBox='0 0 23 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='m-r-2xs'
    >
      <g clipPath='url(#clip0_932_6)'>
        <path
          d='M16.9967 16.6354H4.5895C2.33004 16.6354 0.493408 14.8054 0.493408 12.5393C0.493408 10.2733 2.32344 8.44324 4.5895 8.44324C4.66218 8.44324 4.74146 8.44985 4.81413 8.45646C4.69521 7.94775 4.62254 7.41922 4.62254 6.87087C4.62914 3.07868 7.70122 0 11.5 0C14.9288 0 17.7631 2.51051 18.2784 5.79399C20.6964 6.37538 22.4934 8.54234 22.4934 11.1387C22.4934 14.1778 20.0291 16.6354 16.9967 16.6354Z'
          fill='white'
        />
        <path
          d='M11.8766 13.4844C12.0285 13.7222 12.2796 13.7222 12.4315 13.4844L15.1799 9.19011C15.3318 8.95227 15.2261 8.75408 14.942 8.75408H13.2838L12.927 5.92645C12.927 5.64236 12.6958 5.41113 12.4117 5.41113H11.8964C11.6123 5.41113 11.3811 5.64236 11.3811 5.92645L11.0243 8.75408H9.36607C9.08199 8.75408 8.97628 8.95227 9.12823 9.19011L11.8766 13.4844Z'
          fill='#033F85'
        />
      </g>
      <defs>
        <clipPath id='clip0_932_6'>
          <rect
            width='22'
            height='16.6354'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
