import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

const useGetInvoices = (
  searchTerm = '',
  submittedStart = '',
  submittedEnd = ''
) => {
  const [currentInvoicesPage, setCurrentInvoicesPage] = useState(1);
  const { hsId } = useUserContext();

  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    error: isInvoicesError,
    refetch: invoiceRefetch,
    isFetching: isFetchingInvoices,
  } = useQuery(
    ['invoices', currentInvoicesPage, searchTerm, submittedStart, submittedEnd],
    async () => {
      if (hsId) {
        if (searchTerm) {
          return await api({
            objectName: 'invoices',
            searchValue: searchTerm,
            hsId: hsId,
            submittedStart: submittedStart,
            submittedEnd: submittedEnd,
          }).invoices.searchInvoices();
        } else if (submittedStart || submittedEnd) {
          return await api({
            objectName: 'invoices',
            searchValue: searchTerm,
            hsId: hsId,
            submittedStart: submittedStart,
            submittedEnd: submittedEnd,
          }).invoices.searchInvoices();
        } else {
          return await api({
            hsId: hsId,
            currentPage: currentInvoicesPage,
            nextPage: currentInvoicesPage + 1,
          }).invoices.getInvoices();
        }
      } else {
        return [];
      }
    },
    { enabled: !!hsId, keepPreviousData: true }
  );

  return {
    invoicesData,
    isInvoicesLoading,
    isInvoicesError,
    invoiceRefetch,
    isFetchingInvoices,
    currentInvoicesPage,
    setCurrentInvoicesPage,
  };
};

export { useGetInvoices };
