import PropTypes from 'prop-types';
import { ContainerStyled } from './styles/container.styles';

export default function Container({ className, children }) {
  return <ContainerStyled className={className}>{children}</ContainerStyled>;
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
