import { MessagesInboxFilterButtonStyled } from '../../style/messagesInboxFilter.styles';

type InboxFilterButtonProps = {
  className: string;
  label: string;
  count?: number;
  action: (label: string) => void;
  setActiveButton: (label: string) => void;
};

const MessagesInboxFilterButton = ({
  className,
  label,
  action,
  count,
  setActiveButton,
}: InboxFilterButtonProps) => {
  return (
    <MessagesInboxFilterButtonStyled
      className={className}
      onClick={() => {
        action(label);
        setActiveButton(label);
      }}
    >
      {label}
      {count && count > 0 ? <span>({count})</span> : null}
    </MessagesInboxFilterButtonStyled>
  );
};

export { MessagesInboxFilterButton };
