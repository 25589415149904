export default function ArrowDownBlue() {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <rect width='25' height='25' fill='url(#pattern0)' />
      <defs>
        <pattern
          id='pattern0'
          patternContentUnits='objectBoundingBox'
          width='1'
          height='1'
        >
          <use xlinkHref='#image0_1495_2894' transform='scale(0.0104167)' />
        </pattern>
        <image
          id='image0_1495_2894'
          width='96'
          height='96'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA30lEQVR4nO3UMQ5CMQxEwQ8HW45FCcXn3CBKLoCdeEZyHWmflOMAAAAAAAAAAAAAAAAAYKxrzvv19nqPvJyPo4OREdJk/JER0mz8URHSdPwREdJ8/K0jZJHxt4yQxcbfKkIWHX+LCFl8/KUjZJPxl4yQzcZfKkI2HX+JCNl8/NYRMmT8lhEybPxWETJ0/BYRMnz80gjG//XXCMYvjBDfTl2EGL8uQoxfFyHGr4sQ49dFiPHrIsT4dRFi/LoIMX5dhBi/LkKMXxchxi9xub2e36t5HQAAAAAAAAAAAACOHx8uCCuWMh5tlAAAAABJRU5ErkJggg=='
        />
      </defs>
    </svg>
  );
}
