export const AUTHO_DEV_CONFIG = {
  domain: process.env.REACT_APP_AUTH_DOMAIN_DEVELOPMENT,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID_DEVELOPMENT,
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI_DEVELOPMENT,
};

export const AUTHO_PROD_CONFIG = {
  domain: process.env.REACT_APP_AUTH_DOMAIN_PRODUCTION,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID_PRODUCTION,
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI_PRODUCTION,
};

export const AUTHO_LOCAL_CONFIG = {
  domain: process.env.REACT_APP_AUTH_DOMAIN_LOCAL,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID_LOCAL,
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI_LOCAL,
};
