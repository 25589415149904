import styled from 'styled-components';

export const SkeletonListStyled = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-top: 3.2rem;
`;

export const SkeletonLoaderStyled = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
  padding: 1.6rem;
  background: var(--white);
  border-radius: 0.8rem;
  box-shadow: 1.5px 3px 7px rgba(2, 63, 133, 0.1);

  --glare-bg: linear-gradient(
    90deg,
    transparent,
    hsla(0, 0%, 100%, 0.4),
    transparent
  );

  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--glare-bg);
    transform: translateX(-100%);
    animation: skeleton-glare 1.75s infinite ease-out;
    z-index: 1;
  }

  @keyframes skeleton-glare {
    to {
      transform: translateX(100%);
    }
  }
`;

export const SkeletonLoaderLabelStyled = styled.div`
  display: flex;
  width: 60%;
  height: 1.2rem;
  margin-bottom: 1.2rem;
  background: var(--nbs-blue-light);
  border-radius: 0.4rem;
`;

export const SkeletonLoaderItemStyled = styled.div`
  display: flex;
  width: 40%;
  height: 1.6rem;
  margin-bottom: 1rem;
  background: var(--nbs-blue-light);
  border-radius: 0.4rem;
`;
