import { appHelpers } from '../../../helpers';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

type AccessRequestData = {
  objectType: string;
  objectRecordId: string;
  associationData: {
    objectFromType: string;
    recordId: string;
  };
};

const verifyContactAccess = async (requestData: AccessRequestData) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error('User not authenticated');
  }

  const accessToken = await currentUser.getIdToken();

  const CONFIG = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/access/verify-access`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: requestData,
  };

  try {
    const { data } = await axios(CONFIG);

    return data.results;
  } catch (err) {
    console.error(err);
  }
};

export { verifyContactAccess };
