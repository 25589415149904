import { MainLayout } from '../../../../components/layouts/mainLayout';
import { Hero } from '../../../../components/hero';
import forecastsHeroImage from '../../../../img/hero-sample-image.png';
import { Modal } from '../../../../components/modal';
import { Row } from '../../../../components/layout/row';
import {
  UploadButtonDesktop,
  RefreshButton,
} from '../../../../components/buttons';
import { ForecastsList } from '../components/forecastsList';

// Hooks
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useGetForecasts } from '../../../../hooks/forecasts';
import { DownloadButton } from '../../../../components/buttons/downloadButton/downloadButton';
import { AddQuery } from '../../../../components/forms/queries/addQuery';
import { ForecastsForm } from '../../../../components/forms/forecasts';

import { createForecast } from '../api/createForecast';
import { ForecastFormData } from '../types';

import { useUserContext } from '../../../../providers/user/useUserContext';
import { InfoResources } from '../../../../components/infoResources';

const Forecasts = () => {
  const [modalActive, setModalActive] = useState(false);
  const [modalType, setModalType] = useState('add-new');
  const [activeItem, setActiveItem] = useState({});

  const { refetch } = useGetForecasts();
  const { handleCreateForecast } = createForecast();
  const { hsId, user } = useUserContext();

  const handleFetchItems = async () => {
    await refetch();
  };

  const handleModalClose = () => {
    refetch();
    setModalActive(false);
  };

  const loadItems = () => {
    toast.promise(handleFetchItems(), {
      loading: 'Loading forecasts ...',
      success: 'Forecasts updated',
      error: 'Error updating forecasts',
    });
  };

  const handleInvoiceUpload = async (values: ForecastFormData) => {
    const forecastData = {
      attachment_forecastsForm: values.attachment_forecastsForm,
      forecast_from: values.forecastFrom,
      forecast_to: values.forecastTo,
      hs_pipeline_stage: '1',
      mpxn: values.forecastReference,
      site_name: values.forecastSiteName,
      submitted_by: user ? user.name : null,
      hsId: hsId,
      contractId: values.contract_id,
      forecastTotal: values.forecastTotal,
    };
    try {
      await handleCreateForecast(forecastData);
      setModalActive(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <MainLayout>
      <Hero
        title='Forecasts'
        subTitle="How much energy can you provide? It's quick and easy to let us know - simply download the forecast template, complete all relevant fields, and click on 'Submit new forecast'."
        bgImage={forecastsHeroImage}
      >
        <Row className='p-t-sm p-b-md'>
          <DownloadButton
            downloadUrl='https://firebasestorage.googleapis.com/v0/b/gen-serivces-app-dev.appspot.com/o/downloads%2Fforecasts%2FCustomer-Forecast-Template.xlsx?alt=media&token=d0eadace-8f36-489c-a1e0-1914f8593c22'
            label='Download Forecast Template'
          />
          <UploadButtonDesktop
            modalFunction={setModalActive}
            setModalType={setModalType}
            label='Submit New Forecast'
          />
          <RefreshButton refreshFunction={loadItems} label='Refresh list' />
        </Row>
        <ForecastsList
          setModalType={setModalType}
          setModalActive={setModalActive}
          setActiveItem={setActiveItem}
        />
      </Hero>
      <InfoResources />
      <Modal
        modalTitle='Upload new forecast'
        modalActiveState={modalActive}
        setModalActiveState={setModalActive}
        updater={handleFetchItems}
      >
        {modalType === 'add-new' && (
          <>
            <ForecastsForm
              formSubmitHandler={handleInvoiceUpload}
              id='forecastsForm'
              setModalActiveState={setModalActive}
            />
          </>
        )}
        {modalType === 'add-query' && (
          <>
            <AddQuery
              queryType='forecasts'
              queryItem={activeItem}
              setModalActiveState={handleModalClose}
            />
          </>
        )}
      </Modal>
    </MainLayout>
  );
};

export { Forecasts };
