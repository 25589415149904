import { IonGrid, IonRow } from '@ionic/react';
import CtaBlockCallBack from './ctaBlockCallback';
import CtaBlockContact from './ctaBlockContact';
import CtaBlockOpeningHours from './ctaBlockOpeningHours';
import Container from '../../layout/container/container';
import styled from 'styled-components';
const Ctablock = styled(IonGrid)`
  padding: 12rem 0;
`;
function CtaBlock() {
  return (
    <section className='has-bg-blue-100'>
      <Container>
        <Ctablock className=' ion-no-padding'>
          <IonRow>
            <CtaBlockContact
              contactTitle='Contact us'
              contactText='For a new quote or a renewal please call:'
              contactExistingText='For existing customer service please call:'
            />
            <CtaBlockOpeningHours />
            <CtaBlockCallBack />
          </IonRow>
        </Ctablock>
      </Container>
    </section>
  );
}
CtaBlock.propTypes = {};
export default CtaBlock;
