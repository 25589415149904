import PropTypes from 'prop-types';
import { CardColDropdownToggleStyled } from './styles/cardColDropdownToggle.styles';
import { CaretDownBlue } from '../svg';

const CardColDropdownToggle = ({ setDropdownState, dropdownState }) => {
  return (
    <CardColDropdownToggleStyled
      className={`card-dropdown-toggle ${dropdownState ? 'is-active' : ''}`}
    >
      <button
        onClick={() => setDropdownState(!dropdownState)}
        aria-label='dropdown-toggle'
      >
        <CaretDownBlue />
      </button>
    </CardColDropdownToggleStyled>
  );
};

export default CardColDropdownToggle;

CardColDropdownToggle.propTypes = {
  setDropdownState: PropTypes.func,
  dropdownState: PropTypes.bool,
};
