import { NotificationsDropdownToggleStyled } from './styles/notificationsDropdown.styles';
import { NotificationsBlue } from '../svg';

type NotificationsDropdownToggleProps = {
  dropdownState: boolean;
  setDropdownState: (dropdownState: boolean) => void;
};

const NotificationsDropdownToggle = ({
  dropdownState,
  setDropdownState,
}: NotificationsDropdownToggleProps) => {
  return (
    <NotificationsDropdownToggleStyled
      onClick={() => setDropdownState(!dropdownState)}
      aria-label='Notifications'
    >
      <NotificationsBlue />
    </NotificationsDropdownToggleStyled>
  );
};

export { NotificationsDropdownToggle };
