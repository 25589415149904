import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function FooterListItem({ footerItemUrl, footerItemTitle }) {
  return (
    <li>
      {footerItemUrl ? (
        <Link to={footerItemUrl}>{footerItemTitle}</Link>
      ) : (
        <span>{footerItemTitle}</span>
      )}
    </li>
  );
}

FooterListItem.propTypes = {
  footerItemUrl: PropTypes.string,
  footerItemTitle: PropTypes.string,
};
