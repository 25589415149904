import { AuthGuard } from './authGuard/authGuard';
import { DocumentUploadGuard } from './documentUploadGuard';
import { Dashboard } from '../features/dashboard';
import { Documents } from '../features/energyGenerators/documents';
import {
  Forecasts,
  ForecastsSingle,
} from '../features/energyGenerators/forecasts';
import {
  Invoices,
  InvoicesSingle,
} from '../features/energyGenerators/invoices';
import { Messages } from '../features/energyGenerators/messages';
import { Queries, QueriesSingle } from '../features/energyGenerators/queries';
import {
  Statements,
  StatementSingle,
} from '../features/energyGenerators/statements';
import { MyAccount } from '../features/myAccount';
import { UploadFile } from '../features/energyGenerators/files';

export const PrivateRoutes = [
  { path: '/app/dashboard', element: <AuthGuard component={Dashboard} /> },
  { path: '/app/documents', element: <AuthGuard component={Documents} /> },
  { path: '/app/forecasts', element: <AuthGuard component={Forecasts} /> },
  {
    path: '/app/forecasts/:id',
    element: <AuthGuard component={ForecastsSingle} />,
  },
  { path: '/app/invoices', element: <AuthGuard component={Invoices} /> },
  {
    path: '/app/invoices/:id',
    element: <AuthGuard component={InvoicesSingle} />,
  },
  { path: '/app/messages', element: <AuthGuard component={Messages} /> },
  { path: '/app/queries/', element: <AuthGuard component={Queries} /> },
  {
    path: '/app/queries/:id',
    element: <AuthGuard component={QueriesSingle} />,
  },
  { path: '/app/query/:id', element: <AuthGuard component={QueriesSingle} /> },
  { path: '/app/statements', element: <AuthGuard component={Statements} /> },
  {
    path: '/app/statements/:id',
    element: <AuthGuard component={StatementSingle} />,
  },
  {
    path: '/app/my-account',
    element: <AuthGuard component={MyAccount} />,
  },
  {
    path: '/app/files/upload/:hsId/:objectType/:recordId',
    element: <DocumentUploadGuard component={UploadFile} />,
  },
];
