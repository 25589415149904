import styled from 'styled-components';

export const DashboardWidgetsStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.6rem;
  @media (max-width: 630px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(auto, 24rem);
    grid-gap: 3.2rem;
  }
`;

export const DashboardwidgetStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24rem;
  margin: 0;
  margin-inline: 0;
  border-radius: 0;
  box-shadow: none;
  @media (max-width: 992px) {
    height: auto;
    aspect-ratio: 1 / 1;
  }
  &.dashboard-widget--purple-blue {
    background: linear-gradient(
      119.42deg,
      #d50032 -206.17%,
      #753bbd -40.86%,
      #023f85 92.62%
    );
  }

  &.dashboard-widget--purple {
    background: linear-gradient(
      120.41deg,
      #d50032 -134.97%,
      #753bbd 10.31%,
      #023f85 154.25%
    );
  }

  &.dashboard-widget--pink-purple {
    background: linear-gradient(
      121.08deg,
      #d50032 -64.75%,
      #753bbd 109.5%,
      #023f85 234.78%
    );
  }

  &.dashboard-widget--red-purple {
    background: linear-gradient(
      119.22deg,
      #d50032 9.25%,
      #753bbd 143.91%,
      #023f85 175.89%
    );
  }

  a {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .dashboard-widget__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .dashboard-widget__icon {
    position: relative;
    width: 3.2rem;
    height: 3.2rem;
    font-size: 1rem;
    svg {
      margin: 0px;
      width: 3.2rem;
      height: 3.2rem;
      font-size: 1rem;
    }
    @media (min-width: 992px) {
      font-size: 4.8rem;
      width: auto;
      height: auto;
      svg {
        width: auto;
        height: auto;
        font-size: 4.8rem;
      }
    }
  }

  .dashboard-widget__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1.2rem;
    right: -1.2rem;
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1.2rem;
    color: var(--nbs-blue);
    background: var(--white);
    border-radius: 999px;
  }

  h2 {
    margin: 0;
    color: var(--white);
    font-size: 1.6rem;
    font-weight: bold;
    margin: 1rem 0px 0px;
    @media (min-width: 992px) {
      font-size: 2.8rem;
      margin: 2rem 0;
    }
  }
`;
