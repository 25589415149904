import PropTypes from 'prop-types';
import { DownloadCloudBlue } from '../svg';
import { CardColDownloadStyled } from './styles/cardColDownload.styles';

const CardColDownload = ({ className, url, clickHandler }) => {
  return (
    <CardColDownloadStyled className='pod-card__download-button'>
      <a
        href={url}
        download
        className={className}
        target='_blank'
        rel='noreferrer'
        onClick={() => clickHandler}
        aria-label='Download'
      >
        <DownloadCloudBlue />
      </a>
    </CardColDownloadStyled>
  );
};

export default CardColDownload;

CardColDownload.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  clickHandler: PropTypes.func,
};
