import styled from 'styled-components';

export const FormWrapperStyled = styled.div`
  width: 100%;
  max-width: 42rem;
  margin: auto;
  padding: 3.2rem;
  background-color: #fff;
  box-shadow: 2px 4px 9px rgba(2, 63, 133, 0.1);
  border-radius: 0.8rem;

  h1 {
    margin: 0 0 2rem;
    color: var(--nbs-blue);
    font-size: 3.2rem;
    font-weight: bold;
  }

  h2 {
    margin: 0 0 2.4rem;
    color: var(--nbs-black);
    font-size: 1.6rem;
    font-weight: bold;
  }

  p {
    color: var(--nbs-black);
    margin: 2rem 0;
  }
`;
