import React from 'react'; // Import the 'React' module
import styled from 'styled-components';
import MenuCloseBlue from '../svg/menuCloseBlue';

// Styled components for the SlideOut and its overlay
const SlideOutWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  max-width: 800px;
  width: 100%; // Adjust width as needed
  height: 100%;
  background: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transform: ${(props) =>
    props.isOpen ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.3s ease-in-out;
  z-index: 3000;
  padding: 50px 20px 50px;
`;
const SlideOutHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const SlideOutHeader = styled.h2`
  color: #304c85;
`;

const SlideoutOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #023f85;
  z-index: 100;
  opacity: ${(props) => (props.isOpen ? 0.5 : 0)};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  transition-delay: ${(props) => (props.isOpen ? '0s' : '0.4s')};
`;

interface SlideOutProps {
  isOpen: boolean; // Prop to control visibility of the SlideOut
  setIsOpen: (isOpen: boolean) => void; // Function to toggle the visibility
  children: React.ReactNode; // Content of the SlideOut
  slideOutHeader?: string; // Optional header for the SlideOut
}

const SlideOut = ({
  isOpen,
  setIsOpen,
  children,
  slideOutHeader,
}: SlideOutProps) => {
  return (
    <>
      <SlideoutOverlay isOpen={isOpen} />
      <SlideOutWrapper isOpen={isOpen}>
        <SlideOutHeaderContainer>
          <SlideOutHeader>{slideOutHeader}</SlideOutHeader>
          <button onClick={() => setIsOpen(false)} aria-label='close slideout'>
            <MenuCloseBlue />
          </button>
        </SlideOutHeaderContainer>
        {children}
      </SlideOutWrapper>
    </>
  );
};

export default SlideOut;
