import { svg } from '../../../../components';
import { FooterColumnTitleStyled } from '../styles/footer.styles';
import { FooterSocialLinksStyled } from './styles/footerSocialLinks.styles';

const FooterSocialLinks = () => {
  return (
    <FooterSocialLinksStyled>
      <FooterColumnTitleStyled>Follow</FooterColumnTitleStyled>
      <ul>
        <li>
          <a
            className='button button-secondary button-secondary--white'
            href='https://twitter.com/npower_nbs'
            target='_blank'
            rel='noreferrer'
          >
            <svg.TwitterIcon />
          </a>
        </li>
        <li>
          <a
            className='button button-secondary button-secondary--white'
            href='https://www.linkedin.com/company/npower-business-solutions'
            target='_blank'
            rel='noreferrer'
          >
            <svg.LinkedInIcon />
          </a>
        </li>
        <li>
          <a
            className='button button-secondary button-secondary--white'
            href='https://www.youtube.com/channel/UCRVvcAhKd_XBwlNZUDIKpKQ'
            target='_blank'
            rel='noreferrer'
          >
            <svg.YouTubeIcon />
          </a>
        </li>
      </ul>
    </FooterSocialLinksStyled>
  );
};

export default FooterSocialLinks;
