import { Loader } from '../../global';
import { FormSendingStyled } from './styles/formSending.styles';

type FormSendingProps = {
  title: string;
  message: string;
};

const FormSending = ({ title, message }: FormSendingProps) => {
  return (
    <FormSendingStyled>
      <Loader />
      <h3>{title}</h3>
      <p>{message}</p>
    </FormSendingStyled>
  );
};

export { FormSending };
