import styled from 'styled-components';

export const MessagesInboxListMessagesItemStyled = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  color: var(--nbs-black);
  border-color: var(--nbs-ui-grey);
  border-style: solid;
  border-width: 0.1rem 0.1rem 0 0;
  cursor: pointer;

  &:last-child {
    border-bottom-width: 0.1rem;
  }

  .message-item {
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 2rem;

    &.is-unread {
      border-left: solid 0.8rem var(--nbs-blue);
      @media only screen and (max-width: 992px) {
        border-left: solid 1.8rem var(--nbs-blue);
      }
    }

    &.is-active {
      background: var(--nbs-blue-light);
    }

    &__content {
      grid-column: 1 / 4;
    }

    &__date {
      display: flex;
      justify-content: flex-end;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  h4 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 700;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    .message-item-from {
      font-size: 1.2rem;
    }

    .badge {
      margin-top: 1rem;
      padding-left: 2.8rem;
      padding-right: 2.8rem;
    }
  }
}
`;
