import { appHelpers } from '../../../helpers';
import axios from 'axios';

const createHubspotUser = async (data) => {
  const body = {
    email: data.email,
    firebase_user_id: data.uid,
    // energy_generators_user_role: 'User',
  };

  const config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/users/createHubspotUser`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: body,
  };

  try {
    const hsUser = await axios(config);

    return hsUser;
  } catch (err) {
    return err;
  }
};

export { createHubspotUser };
