import { ids, properties } from '../../../helpers';
import axios from 'axios';
import { SearchRequestData } from '../../../types/search';

import { appHelpers } from '../../../helpers';

const searchInvoices = async (searchRequestData: SearchRequestData) => {
  const { objectName, searchValue, hsId, submittedStart, submittedEnd } =
    searchRequestData;

  if (!objectName) {
    throw new Error('objectName is null');
  }

  const objectIds = ids.getObjectIds() as { [key: string]: string };
  const objectId = objectIds ? objectIds[objectName] : null;
  const propertiesList = properties.getObjectProperties(objectName);

  // const bodyBuilder = searchHelpers.searchBodyBuilder(
  //   searchValue,
  //   objectName,
  //   recordIds
  // );

  const pipelineIds = ids.getPipelineIds() as { [key: string]: string };
  const pipelineId = pipelineIds ? pipelineIds[objectName] : null;

  const body = {
    objectId: objectId,
    objectNameString: objectName,
    hsId: hsId,
    searchValue: searchValue,
    pipelineId: pipelineId,
    properties: propertiesList,
    startDate: submittedStart,
    endDate: submittedEnd,
  };

  const config = {
    url: `${appHelpers.getApiUrl()}/api/v2/invoices/search`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    method: 'post',
    data: body,
  };

  try {
    const { data } = await axios(config);
    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching search results: ${err.message}`);
    } else {
      throw new Error(`Error fetching results`);
    }
  }
};

export { searchInvoices };
