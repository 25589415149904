import PropTypes from 'prop-types';
import InfoResourcesItem from './infoResourcesItem';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper';
import { IonGrid } from '@ionic/react';
import { skeleton } from '..';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
// Styles
import {
  InfoResourcesSection,
  SectionHeader,
  InfoResourcesTitle,
} from './styles/infoResources.styles';
// Styles
import './infoResources.styles.scss';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';

import {
  EG_BLOG_TAG_ID_DEV,
  EG_BLOG_TAG_ID_STAGING,
  EG_BLOG_TAG_ID_PROD,
} from '../../constants/appConstants';

let tagId;
switch (process.env.REACT_APP_MODE) {
  case 'local':
    tagId = EG_BLOG_TAG_ID_DEV;
    break;
  case 'staging':
    tagId = EG_BLOG_TAG_ID_STAGING;
    break;
  case 'production':
  default:
    tagId = EG_BLOG_TAG_ID_PROD;
    break;
}

function InfoResourcesList({ title }) {
  const { data, isLoading } = useQuery(
    ['posts'],
    async () => {
      return await api(tagId).posts.getPosts();
    },
    {
      enabled: !!tagId,
    }
  );

  return (
    <InfoResourcesSection>
      <SectionHeader className='ion-text-center'>
        <InfoResourcesTitle className='is-font-bold'>
          {title}
        </InfoResourcesTitle>
      </SectionHeader>

      <IonGrid className='swiperGridWrapper'>
        {isLoading ? (
          <>
            <skeleton.SkeletonBlogCards />
          </>
        ) : (
          <div className='swiperContainer'>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              scrollbar={{
                draggable: true,
              }}
              breakpoints={{
                540: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              modules={[Scrollbar]}
              className='mySwiper'
            >
              {data && data.data.results.length > 0
                ? data.data.results.map((post, key) => {
                    return (
                      <SwiperSlide key={key}>
                        <InfoResourcesItem post={post} />
                      </SwiperSlide>
                    );
                  })
                : null}
            </Swiper>
          </div>
        )}
      </IonGrid>
    </InfoResourcesSection>
  );
}
InfoResourcesList.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  posts: PropTypes.array,
  loadingStatus: PropTypes.bool,
};

InfoResourcesList.defaultProps = {
  title: 'Information & Resources',
  text: 'Stay up to date with the all the latest business energy news here.',
  posts: [],
  loadingStatus: false,
};

export default InfoResourcesList;
