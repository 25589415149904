import styled from 'styled-components';
import PropTypes from 'prop-types';
import ModalOverlay from './modalOverlay';
import ModalPopup from './modalPopup';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const ModalWrapper = styled.div``;

export default function Modal({
  modalActiveState,
  setModalActiveState,

  children,
  updater,
}) {
  const handleModalClose = () => {
    setModalActiveState(false);
    updater();
  };

  return (
    <>
      <ModalWrapper
        className={`modal ${modalActiveState ? 'is-active' : 'inactive'}`}
      >
        <ModalOverlay modalActiveState={modalActiveState} />
        <ModalPopup modalActiveState={modalActiveState}>
          <button
            onClick={() => {
              handleModalClose();
            }}
          >
            X
          </button>
          <SimpleBar
            autoHide={true}
            style={{
              maxHeight: 700,
              width: '100%',
              overflowX: 'hidden',
              position: 'static',
              padding: '4rem 0',
            }}
            forcehidden='x'
          >
            {children}
          </SimpleBar>
        </ModalPopup>
      </ModalWrapper>
    </>
  );
}

Modal.propTypes = {
  modalTitle: PropTypes.string,
  modalActiveState: PropTypes.bool,
  setModalActiveState: PropTypes.func,
  children: PropTypes.node.isRequired,
  updater: PropTypes.func,
};
