import { Container } from '../../../components/container';
import { Wrapper } from '../../../components/layout';
import { MainLayout } from '../../../components/layouts/mainLayout';
import { AuthLoadingStyled } from '../styles/authLoading.styles';
import { Loader } from '../../../components/loader';

export const AuthLoading = () => {
  return (
    <MainLayout>
      <Wrapper>
        <Container>
          <AuthLoadingStyled>
            <Loader />
            <span>Loading your account, please wait.</span>
          </AuthLoadingStyled>
        </Container>
      </Wrapper>
    </MainLayout>
  );
};
