import styled from 'styled-components';

export const QueriesSingleHeaderStyled = styled.header`
  align-items: center;
  margin-bottom: 4rem;
  padding: 4rem;

  background: var(--white);

  .query-header-content {
    display: grid;
    align-items: center;
    width: 100%;

    @media (min-width: 992px) {
      grid-template-columns: 1fr 16rem;
      // padding: 0 7.2rem;

      .query-header-back {
        display: flex;
        justify-content: flex-end;
        // grid-column: 4 / 5;
      }
    }
  }

  h1 {
    font-size: 2.6rem;
    font-weight: 700;
  }

  h2 {
    font-size: 2rem;
  }

  ul {
    display: flex;
    align-items: center;
    margin: 0 0 2rem;
    padding: 0;
    list-style: none;

    li {
      // margin-right: 0.8rem;

      &:after {
        display: inline-flex;
        margin: 0 0.8rem;
        color: var(--nbs-ui-grey);
        content: '●';
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &.badges {
        span {
          margin-right: 0.4rem;
        }
      }
    }
  }

  .badge {
    padding: 0.4rem 1.2rem;
    font-size: 1.2rem;
    border-radius: 0.8rem;
  }

  a {
    font-weight: bold;
  }
`;

export const QueriesSinglePriorityStyled = styled.span`
  display: inline-flex;
  align-self: flex-start;
  padding: 0.4rem 1.2rem;
  color: var(--white);
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0.8rem;

  &.ticket-priority--LOW {
    background: var(--green);
  }

  &.ticket-priority--MEDIUM {
    background: var(--yellow);
  }

  &.ticket-priority--HIGH {
    background: var(--nbs-red);
  }
`;
