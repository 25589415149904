import { QueriesSingleEmailItemStyled } from '../styles/querySingleEmails.styles';

import { QueriesEmailItem } from '../types';
import { useParams } from 'react-router';
import { formatters } from '../../../../helpers';

interface QueriesEmail {
  email: QueriesEmailItem;
}

const QueriesSingleEmailListItem = ({ email }: QueriesEmail) => {
  const { id } = useParams();

  const formatEmailDate = (createdDate: string) => {
    const date = new Date(createdDate);
    const timeStamp = date.getTime();

    return formatters.formatUnixDate(timeStamp / 1000, 'DD/MM/YY - HH:mm');
  };
  const formattedSubject = email.subject
    ? email.subject.replace(/ticket/gi, 'query')
    : 'No subject';

  return (
    <QueriesSingleEmailItemStyled>
      <div className='email__message'>
        <p className='email__subject'>
          <strong>Subject: {formattedSubject}</strong>
          <br />
          <span>ID: {id}</span>
        </p>
        <span className='email__from-date'>
          {email.fromFirstName ? (
            <>
              From: {email.fromFirstName} {email.fromLastName} -
            </>
          ) : null}{' '}
          {formatEmailDate(email.createdDate)}
        </span>
        <div className='email__content'>
          {email.html ? (
            <div
              className='email__html-content'
              dangerouslySetInnerHTML={{ __html: email.html }}
            ></div>
          ) : (
            <p>{email.text}</p>
          )}
        </div>
      </div>
      <div className='email__meta'></div>
    </QueriesSingleEmailItemStyled>
  );
};

export { QueriesSingleEmailListItem };
