import PropTypes from 'prop-types';
import { CardColContentStyled } from './styles/cardColContent.styles';

const CardColContent = ({ className, content }) => {
  return (
    <CardColContentStyled className={className}>{content}</CardColContentStyled>
  );
};

export default CardColContent;

CardColContent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
};
