// Components
import { Alert } from '../../../../components/alert';
import { EmptyState } from '../../../../components/emptyState';
import { SkeletonLoader } from '../../../../components/skeletonLoader';
import { Pagination } from '../../../../components/pagination';
import { QueriesListStyled } from '../styles/queriesList.styles';
// Hooks
import { useGetTickets } from '../../../../hooks/tickets';
import { QueriesListItem } from './queriesListItem';
import { QueriesItem } from '../types';
import { useEffect, useState } from 'react';
import { SearchForm } from '../../../../components/search';

import { formatters } from '../../../../helpers';
import { SlideOut } from '../../../../components/slideOut';
import FilterButton from '../../../../components/buttons/filterButton/filterButton';
import { Filter } from '../../../../components/filter';

const QueriesList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  //Final ones
  const [submittedStart, setSubmittedStart] = useState('');
  const [submittedEnd, setSubmittedEnd] = useState('');
  const [dateFilterMessage, setDateFilterMessage] = useState('');
  const [isSlideOutOpen, setIsSlideOutOpen] = useState(false);

  const {
    ticketsData,
    isTicketsLoading,
    error,
    isFetchingTickets,
    currentPage,
    refetchTickets,
    setCurrentPage,
  } = useGetTickets(searchTerm, submittedStart, submittedEnd);

  useEffect(() => {
    if (!searchTerm) {
      refetchTickets();
    }
  }, [searchTerm, refetchTickets]);

  const handleFilterClick = (startDate: Date | null, endDate: Date | null) => {
    // Adjust endDate to the end of the day if it's not null
    const adjustedEndDate = endDate
      ? new Date(endDate.setHours(23, 59, 59, 999))
      : null;
    //Convert timestamp to unix
    //Only format dates if they are not null
    const formattedStart = startDate
      ? formatters.formatSearchUnixDate(startDate).unix().toString()
      : '';
    const formattedEnd = adjustedEndDate
      ? formatters.formatSearchUnixDate(adjustedEndDate).unix().toString()
      : '';

    // Set the formatted dates, handle cases where dates are null
    setSubmittedStart(formattedStart);
    setSubmittedEnd(formattedEnd);
    // Close the SlideOut
    setIsSlideOutOpen(false);
    //Convert to seconds as Moment doesnt work otherwise
    let messageStart = parseInt(formattedStart, 10);
    messageStart = messageStart / 1000;
    let messageEnd = parseInt(formattedEnd, 10);
    messageEnd = messageEnd / 1000;
    // Update the date filter message
    updateDateFilterMessage(messageStart, messageEnd);
  };

  // Clears the start and end dates
  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
    setSubmittedStart('');
    setSubmittedEnd('');
    updateDateFilterMessage(0, 0);
  };

  const updateDateFilterMessage = (start: number, end: number) => {
    if (start && end) {
      setDateFilterMessage(
        `Showing results between ${formatters.formatUnixDate(
          start,
          'DD/MM/YYYY'
        )} and ${formatters.formatUnixDate(end, 'DD/MM/YYYY')}`
      );
    } else if (start) {
      setDateFilterMessage(
        `Showing results after ${formatters.formatUnixDate(
          start,
          'DD/MM/YYYY'
        )}`
      );
    } else if (end) {
      setDateFilterMessage(
        `Showing results before ${formatters.formatUnixDate(end, 'DD/MM/YYYY')}`
      );
    } else {
      setDateFilterMessage('');
    }
  };
  const toggleSlideOut = () => setIsSlideOutOpen(!isSlideOutOpen);
  // Calculate the number of active filters
  const activeFilterCount = (submittedStart ? 1 : 0) + (submittedEnd ? 1 : 0);

  if (error) {
    return (
      <Alert type='error' message='There was an error loading your queries' />
    );
  }

  return (
    <>
      <SearchForm
        searchType='tickets'
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
      <FilterButton
        toggleSlideOut={toggleSlideOut}
        filterCount={activeFilterCount}
      />
      <a
        className='is-font-bold is-text-blue lg-m-l-sm'
        onClick={handleClearDates}
      >
        Clear
      </a>
      <SlideOut
        isOpen={isSlideOutOpen}
        setIsOpen={setIsSlideOutOpen}
        slideOutHeader='Date Filter'
      >
        <Filter
          onSubmit={handleFilterClick}
          onClear={handleClearDates}
          initialStartDate={startDate}
          initialEndDate={endDate}
        />
      </SlideOut>
      {isTicketsLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          {dateFilterMessage && (
            <div className='p-y-sm is-font-bold '>{dateFilterMessage}</div>
          )}
          {/* search result number */}
          {ticketsData &&
            ticketsData.results?.items?.length > 0 &&
            (searchTerm || submittedStart || submittedEnd) && (
              <div className='p-y-sm is-font-bold '>
                {ticketsData.results.pagination.totalItems} queries found
              </div>
            )}
          <QueriesListStyled className={isFetchingTickets ? 'is-fetching' : ''}>
            {ticketsData &&
              ticketsData.results?.items?.length > 0 &&
              ticketsData.results.items.map((query: QueriesItem) => (
                <QueriesListItem key={query.id} query={query} />
              ))}
            {ticketsData && ticketsData.results?.items?.length === 0 && (
              <EmptyState label='No queries found' />
            )}
          </QueriesListStyled>
          {!(searchTerm || submittedStart || submittedEnd) &&
            ticketsData?.results?.pagination && (
              <Pagination
                totalItems={ticketsData.results.pagination.totalItems}
                itemsPerPage={20}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            )}
        </>
      )}
    </>
  );
};

export { QueriesList };
