export const CONTACT_OBJECT_ID = '0-1';
export const FORECASTS_OBJECT_ID = '2-110720878';

export const OBJECT_IDS = {
  contacts: '0-1',
  companies: '0-2',
  forecasts: '2-111099956',
  statements: '2-111099955',
  invoices: '2-110935104',
  documents: '2-111319070',
  tickets: '0-5',
  contracts: '2-114305752',
  loas: '2-118455805',
};

export const OBJECT_IDS_DEV = {
  companies: '0-2',
  contacts: '0-1',
  contracts: '2-132015744',
  documents: '2-132015749',
  forecasts: '2-132015746',
  invoices: '2-132015739',
  loas: '2-118455805',
  statements: '2-132015748',
  tickets: '0-5',
};

export const OBJECT_IDS_PROD = {
  contacts: '0-1',
  companies: '0-2',
  forecasts: '2-111099774',
  statements: '2-110721563',
  invoices: '2-110721908',
  documents: '2-111318994',
  tickets: '0-5',
  contracts: '2-114280286',
  loas: '2-118455805',
};
