import { ForecastsSingleHeaderStyled } from '../styles/forecastsSingleHeader.styles';
import { Link } from 'react-router-dom';
import { StatusBadge } from '../../../../components';

import { ForecastsSingleHeaderProps } from '../types';

const ForecastsSingleHeader = ({ forecast }: ForecastsSingleHeaderProps) => {
  return (
    <ForecastsSingleHeaderStyled>
      <div>
        <h1>Forecast: {forecast.mpxn}</h1>
        <StatusBadge statusType={forecast.hs_pipeline_stage} />
      </div>
      <Link to={`/app/forecasts`}>Back to forecasts</Link>
    </ForecastsSingleHeaderStyled>
  );
};

export { ForecastsSingleHeader };
