import styled from 'styled-components';

export const FooterStyled = styled.footer`
  padding: 15rem 0 12rem;
  background: linear-gradient(123deg, #b32442, #6d2566 50%, #095798);
`;

export const FooterRowStyled = styled.div`
  display: grid;
  grid-gap: 3.2rem;
  margin-bottom: 4rem;

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const FooterColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterColumnTitleStyled = styled.h3`
  font-size: 20px;
  font-size: 2rem;
  padding-bottom: 2.5rem;
  color: var(--white);
  text-transform: uppercase;
  border-bottom: solid 0.1rem var(--white);
`;

export const FooterTelNumberStyled = styled.a`
  color: var(--white);
  &:hover {
    text-decoration: underline;
  }
`;
