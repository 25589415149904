import { SingleInvoiceHeaderStyled } from '../styles/singleInvoiceHeader.styles';
import { SingleInvoiceHeaderProps } from '../types';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { StatusBadge } from '../../../../components';

const SingleInvoiceHeader = ({
  invoiceReference,
  invoicePipelineStage,
}: SingleInvoiceHeaderProps) => {
  const { accountId } = useParams();
  return (
    <SingleInvoiceHeaderStyled>
      <div>
        <h1>Invoice: {invoiceReference}</h1>
        <StatusBadge statusType={invoicePipelineStage} />
      </div>

      <Link to={accountId ? `/${accountId}/invoices` : `/invoices`}>
        Back to invoices
      </Link>
    </SingleInvoiceHeaderStyled>
  );
};

export { SingleInvoiceHeader };
