import styled from 'styled-components';

export const FooterSocialLinksStyled = styled.div`
  margin-top: 2rem;

  ul {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    padding: 0;
    list-style: none;

    li {
      margin-right: 2rem;
      a {
        width: 5.2rem;
        height: 5.2rem;
        padding: 0;

        svg {
          width: 2rem;
          fill: var(--white);
        }

        &:hover {
          background: var(--white);

          svg {
            fill: var(--nbs-navy-blue);
          }
        }
      }
    }
  }
`;
