import { useState } from 'react';

const useRednerStatusBadge = () => {
  const [badgeStatus, setBadgeStatus] = useState({});

  const handleBadgeStatus = (status) => {
    if (!status) return;
    const statusLower = status.toLowerCase().replace(/\s/g, '-');

    switch (statusLower) {
      case 'draft':
      case 'in-progress':
      case 'negotiation':
      case 'processed':
      case 'support':
      case 'chat':
      case 'awaiting-approval':
      case 'rejected':
        setBadgeStatus('purple');
        break;
      case 'published':
      case 'sent':
      case 'customer-submitted':
      case 'Customer Submitted':
      case 'submitted':
      case 'call':
      case 'invoice':
      case 'Invoice':
        setBadgeStatus('blue-light');
        break;
      case 'under-review':
      case 'received':
      case 'read':
        setBadgeStatus('nbs-navy-blue');
        break;
      case 'closed':
      case 'invoice-submitted':
      case 'forecast':
      case 'Forecast':
        setBadgeStatus('nbs-violet');
        break;
      case 'payment-scheduled':
      case 'email':
      case 'ticket':
      case 'Ticket':
      case 'escalated':
        setBadgeStatus('nbs-rose');
        break;
      case 'paid':
      case 'resolved':
      case 'statement':
      case 'acknowledged-reciept':
        setBadgeStatus('nbs-pink');
        break;

      case 'customer-rejected':
      case 'awaiting-customer-response':
      case 'invoice-rejected':
        setBadgeStatus('nbs-red');
        break;
      default:
        setBadgeStatus('nbs-navy-blue');
        break;
    }
  };

  return { badgeStatus, handleBadgeStatus };
};

export { useRednerStatusBadge };
