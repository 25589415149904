import {
  FIREBASE_DEV_CONFIG,
  FIREBASE_PROD_CONFIG,
  FIREBASE_STAGING_CONFIG,
} from '../../constants/firebaseConfigs';

const getFirebaseConfig = () => {
  if (process.env.REACT_APP_FIREBASE_MODE === 'development') {
    return FIREBASE_DEV_CONFIG;
  } else if (process.env.REACT_APP_FIREBASE_MODE === 'production') {
    return FIREBASE_PROD_CONFIG;
  } else if (process.env.REACT_APP_FIREBASE_MODE === 'staging') {
    return FIREBASE_STAGING_CONFIG;
  }
};

export { getFirebaseConfig };
