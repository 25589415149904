import axios from 'axios';
import { appHelpers } from '../../../helpers';
import { ids } from '../../../helpers';

interface FormPropertyValues {
  reference: string;
  statement_ref: string;
  invoice_number: string;
  net_total: string;
  submitted_by: string;
  date: string;
  invoice_attachment_url: Record<string, unknown>;
  hs_pipeline_stage: string;
}

type InvoicesRequestData = {
  hsId: string;
  uid: string;
  companyId: string;
  properties: FormPropertyValues;
};

type CreateAssociation = {
  to: { id: string };
  types: {
    associationCategory: string;
    associationTypeId: number | null;
  }[];
};

export type CreateAssociationRequestData = CreateAssociation[];

let associations: CreateAssociationRequestData = [];

const createInvoice = async (requestData: InvoicesRequestData) => {
  if (!requestData.hsId) {
    throw new Error('hsId is required');
  }

  const objectIds = ids.getObjectIds();
  const associationIds = ids.getAssociationIds();
  const pipelineStageIds = ids.getPipelineStageIds();

  if (requestData.companyId) {
    associations = [
      {
        to: { id: requestData.companyId },
        types: [
          {
            associationCategory: 'USER_DEFINED',
            associationTypeId: associationIds
              ? associationIds.gen_services_invoice_to_company
              : null,
          },
        ],
      },
    ];
  } else {
    associations = [
      {
        to: { id: requestData.hsId },
        types: [
          {
            associationCategory: 'USER_DEFINED',
            associationTypeId: associationIds
              ? associationIds.gen_services_invoice_to_contact
              : null,
          },
        ],
      },
    ];
  }

  // if (requestData.properties.contract_id) {
  //   associations.push({
  //     to: { id: requestData.properties.contract_id },
  //     types: [
  //       {
  //         associationCategory: 'USER_DEFINED',
  //         associationTypeId: associationIds
  //           ? associationIds.gen_services_document_to_contract
  //           : null,
  //       },
  //     ],
  //   });
  // }

  if (requestData.properties.statement_ref) {
    associations.push({
      to: { id: requestData.properties.statement_ref },
      types: [
        {
          associationCategory: 'USER_DEFINED',
          associationTypeId: associationIds
            ? associationIds.gs_invoice_to_gen_services_statement
            : null,
        },
      ],
    });
  }

  const BODY = {
    objectType: objectIds ? objectIds.invoices : null,
    hsId: requestData.hsId,
    uid: requestData.uid,
    companyId: requestData.companyId ? requestData.companyId : null,
    properties: requestData.properties,
    associations: associations,
    pipelineUpdates: {
      objectId: objectIds ? objectIds.statements : null,
      id: requestData.properties.statement_ref,
      pipelineStage: pipelineStageIds
        ? pipelineStageIds.statementInvoiced
        : null,
    },
  };

  const CONFIG = {
    url: `${appHelpers.getApiUrl()}/api/v2/invoices/createInvoice`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error creating invoice: ${err.message}`);
    } else {
      throw new Error(`Error creating invoice`);
    }
  }

  // let associations = [
  //

  //   {
  //     to: { id: requestData.properties.statement_ref },
  //     types: [
  //       {
  //         associationCategory: 'USER_DEFINED',
  //         associationTypeId:
  //           ids.getAssociationIds().gs_invoice_to_gen_services_statement,
  //       },
  //     ],
  //   },
  // ];

  // if (requestData.companyId) {
  //   requestData.companyId.forEach((id) => {
  //     associations.push({
  //       to: { id: id },
  //       types: [
  //         {
  //           associationCategory: 'USER_DEFINED',
  //           associationTypeId:
  //             ids.getAssociationIds().gen_services_invoice_to_company,
  //         },
  //       ],
  //     });
  //   });
  // }

  // const body = {

  // };

  // let response = await axios(
  //   `${appHelpers.getApiUrl()}/api/v2/invoices/createInvoice`,
  //   {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${currentUser.accessToken}`,
  //     },
  //     data: body,
  //   }
  // );

  // return response.data;
};

export { createInvoice };
