import PropTypes from 'prop-types';
import { CardActionMenuDropdownStyled } from './styles/cardActionMenuDropdown.styles';

const CardActionMenuDropdown = ({ children, dropdownState }) => {
  return (
    <CardActionMenuDropdownStyled
      className={dropdownState ? 'is-active' : null}
    >
      {children}
    </CardActionMenuDropdownStyled>
  );
};

export default CardActionMenuDropdown;

CardActionMenuDropdown.propTypes = {
  children: PropTypes.any,
  dropdownState: PropTypes.bool,
};
