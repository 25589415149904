// Components
import { Hero } from '../../../../components/hero';
import { MainLayout } from '../../../../components/layouts/mainLayout';
import invoicesHeroImage from '../../../../img/Invoices_hero_image.png';
import { Row } from '../../../../components/layout/row';
import { InvoicesList } from '../components/invoicesList';
import {
  UploadButtonDesktop,
  RefreshButton,
} from '../../../../components/buttons';
import { Modal } from '../../../../components/modal';
import { AddInvoice } from '../../../../components/forms/invoices';

// Hooks
import { useState } from 'react';
import toast from 'react-hot-toast';

import { useGetInvoices } from '../../../../hooks/invoices';
import { AddQuery } from '../../../../components/forms/queries/addQuery';
import { InfoResources } from '../../../../components/infoResources';

const Invoices = () => {
  const [modalActive, setModalActive] = useState(false);
  const [modalType, setModalType] = useState('add-new');
  const [activeInvoice, setActiveInvoice] = useState({});
  const { invoiceRefetch } = useGetInvoices();

  const handleFetchItems = async () => {
    await invoiceRefetch();
  };

  const loadItems = () => {
    toast.promise(handleFetchItems(), {
      loading: 'Loading invoices ...',
      success: 'Invoices updated',
      error: 'Error updating invoices',
    });
  };

  const handleModalClose = () => {
    invoiceRefetch();
    setModalActive(false);
  };

  return (
    <MainLayout>
      <Hero
        title='Invoices'
        subTitle='Keep track of your invoices, monitor their status, and create new ones when needed. With Energy Generators, you can have all your invoicing information at your fingertips.'
        bgImage={invoicesHeroImage}
      >
        <Row className='p-t-sm p-b-md'>
          <UploadButtonDesktop
            modalFunction={setModalActive}
            setModalType={setModalType}
            label='Upload New Invoice'
          />
          <RefreshButton refreshFunction={loadItems} label='Refresh list' />
        </Row>

        <InvoicesList
          setModalType={setModalType}
          setModalActive={setModalActive}
          setActiveInvoice={setActiveInvoice}
        />
      </Hero>
      <InfoResources />
      <Modal
        modalTitle='Upload new document'
        modalActiveState={modalActive}
        setModalActiveState={setModalActive}
        updater={handleFetchItems}
      >
        {modalType === 'add-new' && (
          <AddInvoice
            type='invoices-page'
            id='newInvoiceForm'
            setModalActiveState={setModalActive}
          />
        )}
        {modalType === 'add-query' && (
          <>
            <AddQuery
              queryType='invoices'
              queryItem={activeInvoice}
              setModalActiveState={handleModalClose}
            />
          </>
        )}
      </Modal>
    </MainLayout>
  );
};

export { Invoices };
