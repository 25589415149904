import { appHelpers } from '../../../helpers';
import axios from 'axios';

const updateTicket = async (requestData) => {
  let config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/tickets/updateTicket`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: requestData,
  };

  return await axios(config);
};

export { updateTicket };
