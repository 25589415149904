import { FilterButtonStyled, Badge } from './styles/filterButton.styles';
import React from 'react';

interface FilterButtonProps {
  toggleSlideOut: () => void; // Function to toggle slide-out visibility
  filterCount: number; // Number of active filters
}

const FilterButton: React.FC<FilterButtonProps> = ({
  toggleSlideOut,
  filterCount,
}) => {
  return (
    <FilterButtonStyled onClick={toggleSlideOut} className='m-y-sm lg-m-y-none'>
      Date Filter {filterCount > 0 && <Badge>{filterCount}</Badge>}
    </FilterButtonStyled>
  );
};

export default FilterButton;
