import PropTypes from 'prop-types';
import { DividerStyled } from './divider.styles';

export default function Divider({ text }) {
  return (
    <DividerStyled>
      <span>{text}</span>
    </DividerStyled>
  );
}

Divider.propTypes = {
  text: PropTypes.string.isRequired,
};
