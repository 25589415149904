import { Link } from 'react-router-dom';
import {
  ForecastsIcon,
  StatementsIcon,
  TicketsIcon,
  MessagesIcon,
} from '../../../components/svg';
import { Loader } from '../../../components/loader';
import { DashboardwidgetStyled } from '../styles/dashboardWidgets.styles';

import { DashboardWidgetProps } from '../types';

const DashboardWidget = ({
  widgetContent,
  widgetLoading,
  path,
  title,
  gradient,
  icon,
}: DashboardWidgetProps) => {
  return (
    <DashboardwidgetStyled
      className={`dashboard-widget dashboard-widget--${gradient}`}
      data-testid='widget'
    >
      <Link to={path}>
        <div className='dashboard-widget__content'>
          {widgetLoading ? (
            <>
              <Loader />
            </>
          ) : null}
          {widgetContent ? (
            <div className='is-display-flex is-flex-col justify-center align-center'>
              {icon ? (
                <div className='dashboard-widget__icon'>
                  {icon == 'forecasts' ? <ForecastsIcon /> : null}
                  {icon == 'statements' ? <StatementsIcon /> : null}
                  {icon == 'tickets' ? <TicketsIcon /> : null}
                  {icon == 'messages' ? <MessagesIcon /> : null}
                  {widgetContent.newItems > 0 ? (
                    <div
                      className='dashboard-widget__badge'
                      data-testid='widgetBadge'
                    >
                      {widgetContent.newItems}
                    </div>
                  ) : null}
                </div>
              ) : null}

              <h2>{title}</h2>
            </div>
          ) : null}
        </div>
      </Link>
    </DashboardwidgetStyled>
  );
};

export { DashboardWidget };
