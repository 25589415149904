import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

const useGetTicket = () => {
  const { id } = useParams();
  const { hsId } = useUserContext();

  const { data, isLoading, error } = useQuery({
    queryKey: ['ticket', id],
    queryFn: async () => {
      if (id) {
        return await api({
          ticketId: id,
          hsId: hsId,
        }).tickets.getSingleTicket();
      } else {
        return [];
      }
    },
    enabled: !!hsId,
  });

  return {
    singleTicketData: data,
    singleTicketError: error,
    singleTicketLoading: isLoading,
  };
};

export { useGetTicket };
