import PropTypes from 'prop-types';
import { CardColDropdownContentStyled } from './styles/cardColDropdownContent.styles';

const CardColDropdownContent = ({ dropdownState, children }) => {
  return (
    <CardColDropdownContentStyled className={dropdownState ? 'is-active' : ''}>
      {children}
    </CardColDropdownContentStyled>
  );
};

export default CardColDropdownContent;

CardColDropdownContent.propTypes = {
  dropdownState: PropTypes.bool,
  children: PropTypes.node,
};
