import PropTypes from 'prop-types';
import { IonCol } from '@ionic/react';
import styled from 'styled-components';

const CtaTitle = styled.h3`
  border-bottom: solid 0.4rem #304c85;
  font-size: 35px;
  font-size: 3.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 4rem;
`;

const CallbackButton = styled.a`
  display: inline-flex;
  color: #fff;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  padding: 1rem 1.5rem;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.8;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #0a5ca9;
  border: solid 0.1rem #0a5ca9;
  border-radius: 999px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    color: var(--white);
    border-color: var(--white);
    box-shadow: 0.2rem 0.2rem 1rem rgb(10 92 169 / 42%);
    background-color: #524388;
  }
`;
function CtaBlockCallBack({
  callBackTitle,
  callBackButtonText,
  callBackButtonurl,
}) {
  const displayCallBackTitle = () => {
    if (callBackTitle) {
      return (
        <CtaTitle className='is-font-bold is-text-blue'>
          {callBackTitle}
        </CtaTitle>
      );
    }
    return (
      <CtaTitle className='is-font-bold is-text-blue'>
        Request a call back
      </CtaTitle>
    );
  };

  const displayCallBackButton = () => {
    if (callBackButtonText) {
      return (
        <CallbackButton href={callBackButtonurl}>
          {callBackButtonText}
        </CallbackButton>
      );
    }
    return (
      <CallbackButton
        rel='noreferrer'
        target='_blank'
        href='https://npowerbusinesssolutions.com/callback'
      >
        Request a call back
      </CallbackButton>
    );
  };

  return (
    <IonCol>
      {displayCallBackTitle()}
      {displayCallBackButton()}
    </IonCol>
  );
}
CtaBlockCallBack.propTypes = {
  callBackTitle: PropTypes.string,
  callBackButtonText: PropTypes.string,
  callBackButtonurl: PropTypes.string,
};
export default CtaBlockCallBack;
