// Components
import {
  Card,
  CardCol,
  CardColLabel,
  CardColContent,
  CardColDate,
  CardColDownload,
  CardColStatus,
  CardActionMenuToggle,
  CardActionMenuDropdown,
} from '../../../../components/card';
import { InvoicesListItemStyled } from '../styles/invoicesListItem.styles';
import { Link } from 'react-router-dom';
import { QuestionMarkCircle, Information } from '../../../../components/svg';

// Hooks
import { useState } from 'react';

// Types
import { InvoicesListItemProps } from '../types';

// Helpers
import { formatters } from '../../../../helpers';

const accountId = null;

const InvoicesListItem = ({
  invoice,
  setModalActive,
  setModalType,
  setActiveInvoice,
}: InvoicesListItemProps) => {
  const [dropdownState, setDropdownState] = useState(false);

  return (
    <>
      <Card>
        <InvoicesListItemStyled data-testid='invoices-list-item'>
          <CardCol className='invoice-date'>
            <CardColLabel label='Date' />
            <CardColDate date={invoice.date} />
          </CardCol>
          <CardCol className='invoice-number'>
            <CardColLabel label='Invoice number' />
            <CardColContent content={invoice.invoiceNumber} />
          </CardCol>
          <CardCol className='invoice-pmi'>
            <CardColLabel label='Pmi number' />
            <CardColContent content={invoice.reference} />
          </CardCol>
          <CardCol className='invoice-total'>
            <CardColLabel label='Total(GBP)' />
            <CardColContent
              content={formatters.formatCurrency(invoice.total)}
            />
          </CardCol>
          <CardCol className='pod-card__status invoice-status'>
            <CardColStatus status={invoice.pipelineStage} />
          </CardCol>
          <CardCol className='pod-card__download invoice-download'>
            {invoice.attachmentUrl ? (
              <CardColDownload url={invoice.attachmentUrl} />
            ) : null}
          </CardCol>
          <CardActionMenuToggle
            dropdownState={dropdownState}
            setDropdownState={setDropdownState}
          />
        </InvoicesListItemStyled>
        <CardActionMenuDropdown dropdownState={dropdownState}>
          <ul>
            <li>
              <Link
                to={
                  accountId
                    ? `/app/${accountId}/invoices/${invoice.id}`
                    : `/app/invoices/${invoice.id}`
                }
              >
                <Information /> View Invoice
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  setModalActive(true);
                  setModalType('add-query');
                  setActiveInvoice(invoice);
                  setDropdownState(false);
                }}
              >
                <QuestionMarkCircle /> Query Invoice
              </button>
            </li>
          </ul>
        </CardActionMenuDropdown>
      </Card>
    </>
  );
};

export { InvoicesListItem };
