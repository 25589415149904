import { AddQueryFormItemProps } from '../types';
import { AddQueryCategories } from './addQueryCategories';

const AddQueryStatements = ({
  categories,
  register,
  recordNumber,
  pmRef,
}: AddQueryFormItemProps) => {
  return (
    <div>
      <AddQueryCategories categories={categories} register={register} />
      <div className='form-group'>
        <label htmlFor='statementNumber'>Statement Number</label>
        <input
          id='statementNumber'
          type='text'
          className='form-control'
          readOnly
          value={pmRef}
          defaultValue={pmRef}
        />
      </div>
      <div className='form-group'>
        <input
          type='text'
          className='form-control'
          {...register('statementNumber')}
          readOnly
          hidden
          value={recordNumber}
          defaultValue={recordNumber}
        />
      </div>
    </div>
  );
};

export { AddQueryStatements };
