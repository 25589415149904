import { api } from '../../../../api/apiHandler';
import { ids } from '../../../../helpers';

const useResolveTicket = async (ticketId: string, status: string) => {
  const piplineStageIds = ids.getPipelineStageIds();
  // if (status === 'ticketClosed')

  const pipelineId = piplineStageIds
    ? piplineStageIds[status as keyof typeof piplineStageIds]
    : null;

  const TICKET_DATA = {
    id: ticketId,
    body: {
      properties: {
        hs_pipeline_stage: pipelineId,
      },
    },
  };

  try {
    return await api(TICKET_DATA).tickets.updateTicket();
  } catch (err) {
    throw new Error(`Error updating ticket #${ticketId}: ${err}`);
  }
};

export { useResolveTicket };
