export default function StatementsBlue() {
  return (
    <svg
      width='18'
      height='23'
      viewBox='0 0 18 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M10.3662 0H2.74131C1.23344 0 0 1.21696 0 2.70468V20.2953C0 21.783 1.23344 23 2.74131 23H15.2587C16.7666 23 18 21.783 18 20.2953V7.53177L10.3662 0ZM9.73771 8.44304V2.20101L16.0643 8.44304H9.73771Z'
          fill='#023F85'
        />
      </g>
      <defs>
        <clipPath id='clip0_580_14050'>
          <rect width='18' height='23' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
