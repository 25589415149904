import axios, { AxiosRequestConfig } from 'axios';
import { appHelpers } from '../../helpers';

const api = axios.create({
  baseURL: `${appHelpers.getApiUrl()}/api` as string,
});

type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE';

type ApiRequestParams = {
  endpoint: string;
  method: HttpMethod;
  requestData?: Record<string, unknown>;
  headers?: Record<string, string>;
};

const useAxios = async <T>({
  endpoint,
  method,
  requestData,
  headers,
}: ApiRequestParams): Promise<T> => {
  const CONFIG: AxiosRequestConfig = {
    method,
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    data: method !== 'GET' ? requestData : undefined,
  } as AxiosRequestConfig;

  const { data } = await api.request<T>(CONFIG);

  return data;
};

export { useAxios };
