import styled from 'styled-components';

export const SingleItemAttachmentStyled = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  max-width: 40rem;
  margin: 2rem 0;
  padding: 2rem;
  background: var(--white);
  border-radius: 0.8rem;

  h2 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: bold;
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 4rem;
  }
`;
