import styled from 'styled-components';

export const FooterLogoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 6rem;

  &:before,
  &:after {
    position: absolute;
    height: 0.1rem;
    width: calc(50% - 20rem);
    background: var(--white);
    content: '';
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  img {
    width: 100%;
    max-width: 30rem;
    height: auto;
  }
`;
