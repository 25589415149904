import { NotificationsDropdownListStyled } from './styles/notificationsDropdownList.styles';

import { NotificationsDropdownListItem } from './notificationsDropdownListItem';
import { Notification, NotificationsDropdownListProps } from './types';

import { Link } from 'react-router-dom';

const NotificationsDropdownList = ({
  notifications,
}: NotificationsDropdownListProps) => {
  return (
    <NotificationsDropdownListStyled>
      <header>
        <h4>Notifications</h4>
      </header>
      <div className='notifications-divider'></div>
      <ul>
        {notifications && notifications.length > 0 ? (
          notifications.map((notification: Notification) => {
            return (
              <NotificationsDropdownListItem
                key={notification.id}
                notification={notification}
              />
            );
          })
        ) : (
          <li className='no-items'>
            <p>You have no unread notifications</p>
          </li>
        )}
      </ul>
      <footer>
        <Link to='/app/messages'>View all notifications</Link>
      </footer>
    </NotificationsDropdownListStyled>
  );
};

export { NotificationsDropdownList };
