export const PIPELINE_IDS_DEV = {
  documents: '467571947',
  forecasts: '467571948',
  invoices: '467571949',
  statements: '468013761',
  tickets: '467571950',
};

export const PIPELINE_STAGE_IDS_DEV = {
  invoiceRecieved: '718170328',
  statementInvoiced: '718139104',
  forecastSubmitted: '718170323',
  ticketSubmitted: '718170335',
  ticketClosed: '718170340',
};

export const PIPELINE_IDS_STAGING = {
  statements: '75447485',
  forecasts: '75764679',
  invoices: '75722717',
  tickets: '58861791',
};

export const PIPELINE_STAGE_IDS_STAGING = {
  invoiceRecieved: '179393739',
  statementInvoiced: '179474376',
  forecastSubmitted: '179481786',
  ticketSubmitted: '145242856',
  ticketClosed: '145242859',
};

export const PIPELINE_IDS_PROD = {
  invoices: '75722717',
  forecasts: '75764679',
  statements: '75447485',
  tickets: '58861791',
};

export const PIPELINE_STAGE_IDS_PROD = {
  invoiceRecieved: '179393739',
  statementInvoiced: '179474376',
  forecastSubmitted: '179481786',
  ticketSubmitted: '145242856',
  ticketClosed: '145242859',
};
