export const INVOICES_PROPERTIES = [
  'invoice_reference',
  'status',
  'invoice_date',
  'invoice_attachment_url',
  'invoice_number',
  'invoice_total',
  'reference',
  'date',
  'statement_number',
  'hs_pipeline_stage',
  'hs_pipeline',
  'net_total',
];
