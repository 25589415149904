import { Navigate, useLocation } from 'react-router';

type DocumentAuthGuardProps = {
  component: React.ComponentType;
};

const DocumentUploadGuard = ({ component }: DocumentAuthGuardProps) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  if (!token || token != process.env.REACT_APP_HS_CRM_KEY) {
    return (
      <>
        <Navigate to='/401' />
      </>
    );
  }

  const Component = component;

  return <Component />;
};

export { DocumentUploadGuard };
