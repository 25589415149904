export const TICKET_CATEGORIES = [
  { value: '', label: 'Select a category' },
  { value: 'Invoice Query', label: 'Invoice Query' },
  { value: 'Statement Query', label: 'Statement Query' },
  {
    value: 'Statement Due Date Query',
    label: 'Statement Due Date Query',
  },
  {
    value: 'Payment Due Date Query',
    label: 'Payment Due Date Query',
  },
  { value: 'Backing Data Query', label: 'Backing Data Query' },
  {
    value: 'Volume Forecast Query',
    label: 'Volume Forecast Query',
  },
  {
    value: 'Contract Query',
    label: 'Contract Query',
  },
];
type TicketFormCategoriesType = {
  [key: string]: { value: string; label: string }[];
};

const TICKET_FORM_CATEGORIES_STATEMENTS = [
  { value: '', label: 'Select a category' },
  { value: 'Statement Query', label: 'Statement Query' },
  {
    value: 'Statement Due Date Query',
    label: 'Statement Due Date Query',
  },
  {
    value: 'Payment Due Date Query',
    label: 'Payment Due Date Query',
  },
  { value: 'Backing Data Query', label: 'Backing Data Query' },
];

const TICKET_FORM_CATEGORIES_INVOICES = [
  {
    value: 'Invoice Query',
    label: 'Invoice Query',
  },
];

const TICKET_FORM_CATEGORIES_FORECASTS = [
  {
    value: 'Volume Forecast Query',
    label: 'Volume Forecast Query',
  },
];

export const TICKET_FORM_CATEGORIES: TicketFormCategoriesType = {
  statements: TICKET_FORM_CATEGORIES_STATEMENTS,
  invoices: TICKET_FORM_CATEGORIES_INVOICES,
  forecasts: TICKET_FORM_CATEGORIES_FORECASTS,
  all: TICKET_CATEGORIES,
};
