import { Link } from 'react-router-dom';
import { NavStyled, NavListStyled } from './styles/nav.styles';

import { Container } from '../container';
import { useUserContext } from '../../providers/user/useUserContext';
import { useMatchMedia } from '../../hooks/useMatchMedia';

const activeAccountId = null;

const Nav = () => {
  const { user } = useUserContext();
  const isDeskTopResolution = useMatchMedia('(min-width: 992px)', true);

  if (!user) return <NavStyled />;

  if (!isDeskTopResolution) return <NavStyled />;

  return (
    <NavStyled>
      <Container>
        <NavListStyled>
          <li>
            <Link to='/app/messages'>Messages</Link>
          </li>
          <li>
            <Link
              to={
                activeAccountId
                  ? `/app/${activeAccountId}/queries`
                  : '/app/queries'
              }
            >
              Queries
            </Link>
          </li>
          <li>
            <Link
              to={
                activeAccountId
                  ? `/app/${activeAccountId}/forecasts`
                  : '/app/forecasts'
              }
            >
              Forecasts
            </Link>
          </li>
          <li>
            <Link
              to={
                activeAccountId
                  ? `/app/${activeAccountId}/statements`
                  : '/app/statements'
              }
            >
              Statements
            </Link>
          </li>
          <li>
            <Link
              to={
                activeAccountId
                  ? `/app/${activeAccountId}/invoices`
                  : '/app/invoices'
              }
            >
              Invoices
            </Link>
          </li>
          <li>
            <Link
              to={
                activeAccountId
                  ? `/app/${activeAccountId}/documents`
                  : '/app/documents'
              }
            >
              Documents
            </Link>
          </li>
        </NavListStyled>
      </Container>
    </NavStyled>
  );
};

export { Nav };
