// Types
import { TicketItem } from '../../../types/tickets';

import { Link, useParams } from 'react-router-dom';
import { CardColDropdownContent } from '../../card';
import TicketsListItemPriority from './ticketsListItemPriority';
import {
  TicketListItemBulletsStyled,
  ReadTicketButton,
} from './styles/ticketItem.styles';

// Helpers
import { formatters } from '../../../helpers';

type TicketListItemDropdownProps = {
  ticket: TicketItem;
  dropdownState: boolean;
};

const TicketListItemDropdown = ({
  ticket,
  dropdownState,
}: TicketListItemDropdownProps) => {
  const { accountId } = useParams();

  return (
    <CardColDropdownContent dropdownState={dropdownState}>
      <header>
        <TicketsListItemPriority priority={ticket.priority} />
        <h4>{ticket.subject}</h4>
      </header>
      <TicketListItemBulletsStyled>
        {ticket.estimatedResponseDate ? (
          <li>
            Estimated response date :{' '}
            {formatters.formatDate(ticket.estimatedResponseDate, 'DD/MM/YY')}
          </li>
        ) : null}
        {ticket.lastActivityDate ? (
          <li>
            Last activity date :{' '}
            {formatters.formatDate(ticket.lastActivityDate, 'DD/MM/YY - HH:mm')}
          </li>
        ) : null}
        {ticket.lastMessageSentAt ? (
          <li>
            Last response date :{' '}
            {formatters.formatDate(
              ticket.lastMessageSentAt,
              'DD/MM/YY - HH:mm'
            )}
          </li>
        ) : null}
        {ticket.closedDate ? (
          <li>
            Closed date : {formatters.formatDate(ticket.closedDate, 'DD/MM/YY')}
          </li>
        ) : null}
      </TicketListItemBulletsStyled>
      {ticket.content ? (
        <>
          <h5>Query description</h5>
          {ticket.category ? <p>Category: {ticket.category}</p> : null}
          {ticket.statementId ? (
            <Link
              to={
                accountId
                  ? `/app/${accountId}/statements/${ticket.statementId}`
                  : `/app/statements/${ticket.statementId}`
              }
            >
              View Statement
            </Link>
          ) : null}
          {ticket.invoiceId ? (
            <Link
              to={
                accountId
                  ? `/app/${accountId}/invoices/${ticket.invoiceId}`
                  : `/app/invoices/${ticket.invoiceId}`
              }
            >
              View Invoice
            </Link>
          ) : null}
          {ticket.forecastId ? (
            <Link
              to={
                accountId
                  ? `/app/${accountId}/forecasts/${ticket.forecastId}`
                  : `/app/forecasts/${ticket.forecastId}`
              }
            >
              View Forecast
            </Link>
          ) : null}
          <p>{ticket.content}</p>
          {ticket.owner ? (
            <p>Team member handling your query : {ticket.owner}</p>
          ) : null}
        </>
      ) : null}
      <p>
        <ReadTicketButton
          className='ticket-link'
          to={
            accountId
              ? `/app/${accountId}/query/${ticket.id}`
              : `/app/query/${ticket.id}`
          }
        >
          View query
        </ReadTicketButton>
      </p>
    </CardColDropdownContent>
  );
};

export default TicketListItemDropdown;
