// API Stuff
export const HS_CONTACT_PROPERTIES = [
  'firstname',
  'lastname',
  'company',
  'jobtitle',
  'phone',
  'email',
];

export const HS_PORTAL_ID = '26652090';
export const HS_API_BASE_URL = 'https://api.hubapi.com';
export const EG_BLOG_TAG_ID_DEV = '113467953368';
export const EG_BLOG_TAG_ID_STAGING = '74293757412';
export const EG_BLOG_TAG_ID_PROD = '59953090545';
export const HS_DEV_FUNCTIONS_URL =
  'https://us-central1-nbs-energy-generators-dev.cloudfunctions.net/';
export const HS_PROD_FUNCTIONS_URL =
  'https://us-central1-nbs-energy-generators-app.cloudfunctions.net/';

export const API_CONSTANTS = {
  localApiUrlBase:
    'http://127.0.0.1:5001/nbs-energy-generators-dev/us-central1/app',
  devApiUrlBase:
    'https://us-central1-nbs-energy-generators-dev.cloudfunctions.net/app',
  prodApiUrlBase:
    'https://us-central1-nbs-energy-generators-app.cloudfunctions.net/app',
};

export const APP_API_VERSION = 2;

export const FUNCTIONS_CONFIG = {
  local: {
    apiUrl: 'http://127.0.0.1:5001/nbs-energy-generators-dev/us-central1/app',
  },
  development: {
    apiUrl:
      'https://us-central1-nbs-energy-generators-dev.cloudfunctions.net/app',
  },
  production: {
    apiUrl:
      'https://us-central1-nbs-energy-generators-app.cloudfunctions.net/app',
  },
  staging: {
    apiUrl:
      'https://us-central1-nbs-energy-generators-dev.cloudfunctions.net/app',
  },
};
