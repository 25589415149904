import styled from 'styled-components';

export const PostItem = styled.article`
  display: grid;
  grid-template-columns: 1fr 8.4rem;
  grid-gap: 2rem;
  align-items: center;
  margin-bottom: 2.8rem;
  padding-bottom: 2.8rem;
  border-bottom: solid 0.1rem var(--nbs-ui-grey);

  h3 {
    margin: 0;
    color: var(--nbs-blue);
    font-size: 1.4rem;
    font-weight: 700;
  }

  span {
    color: var(--nbs-black);
    font-size: 1.2rem;
  }
`;

export const PostItemImage = styled.figure`
  width: 100%;
  height: 100%;
  margin: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
