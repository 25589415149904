import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

import { useParams } from 'react-router';

const useGetStatementTickets = () => {
  const { hsId } = useUserContext();
  const { id } = useParams();

  const { data, isLoading, error, refetch } = useQuery(
    ['Statement Tickets', id],
    async () => {
      if (hsId) {
        return await api({
          objectType: 'statements',
          hsId: hsId,
          recordId: id,
        }).tickets.getTickets();
      } else {
        return [];
      }
    }
  );

  return {
    tickets: data,
    ticketsLoading: isLoading,
    ticketsError: error,
    refetchTickets: refetch,
  };
};

export { useGetStatementTickets };
