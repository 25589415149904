import { ReactNode, useState, useEffect } from 'react';
import { UserContext } from './userContext';
import { useAuth0 } from '@auth0/auth0-react';
// import { useQuery } from '@tanstack/react-query';
// import { useAxios } from '../../lib/axios';

import { useGetAuth0User } from '../../hooks/auth';
import { api } from '../../api/apiHandler';

type UserProviderProps = {
  children: ReactNode;
};

interface Auth0User {
  email: string;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const { user, isAuthenticated } = useAuth0();
  const auth0User = useGetAuth0User();

  const [contextValue, setContextValue] = useState({
    accountStatus: auth0User?.accountStatus || null,
    companyIds: auth0User?.companyIds || [],
    hsId: auth0User?.hsId || null,
    user: user || undefined,
    isAuthenticated: isAuthenticated,
  });

  const getHsUser = async (user: Auth0User) => {
    return await api({ email: user.email }).users.getHubspotUser();
  };

  useEffect(() => {
    if (!user || !user.email) return;

    const fetchHsUser = async () => {
      const hsUser = await getHsUser({ email: user.email } as Auth0User);

      setContextValue({
        accountStatus: hsUser.properties.accountStatus || null,
        companyIds: auth0User?.companyIds || [],
        hsId: hsUser.id || null,
        user: user || undefined,
        isAuthenticated: isAuthenticated,
      });
    };

    fetchHsUser();
  }, [auth0User, user, isAuthenticated]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
