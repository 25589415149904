import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useParams } from 'react-router';
import { useState } from 'react';

const useGetTickets = (
  searchTerm = '',
  submittedStart = '',
  submittedEnd = ''
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { accountId } = useParams();
  const { hsId } = useUserContext();

  const requestData: {
    hsId: string | null | undefined;
    recordId?: string | null | undefined;
    objectType?: string;
    currentPage?: number;
    nextPage?: number;
    searchQuery?: string;
  } = {
    hsId: hsId,
    currentPage: currentPage,
    nextPage: currentPage + 1,
    searchQuery: searchTerm,
  };

  if (accountId) {
    requestData.recordId = accountId;
    requestData.objectType = 'companies';
  } else {
    requestData.recordId = hsId;
    requestData.objectType = 'contacts';
  }

  const {
    data: ticketsData,
    isLoading: isTicketsLoading,
    error,
    refetch: refetchTickets,
    isFetching: isFetchingTickets,
  } = useQuery(
    ['tickets', currentPage, searchTerm, submittedStart, submittedEnd],
    async () => {
      if (hsId) {
        if (searchTerm) {
          return await api({
            objectName: 'tickets',
            searchValue: searchTerm,
            hsId: hsId,
            submittedStart: submittedStart,
            submittedEnd: submittedEnd,
          }).tickets.searchTickets();
        } else if (submittedStart || submittedEnd) {
          return await api({
            objectName: 'tickets',
            searchValue: searchTerm,
            hsId: hsId,
            submittedStart: submittedStart,
            submittedEnd: submittedEnd,
          }).tickets.searchTickets();
        } else {
          return await api(requestData).tickets.getTickets();
        }
      } else {
        return [];
      }
    },
    { enabled: !!hsId }
  );

  return {
    ticketsData,
    isTicketsLoading,
    error,
    refetchTickets,
    isFetchingTickets,
    currentPage,
    setCurrentPage,
  };
};

export { useGetTickets };
