import { SingleItemErrorProps } from './types';
import { layout } from '../';
import { Alert } from '../alert';

const SingleItemError = ({ errorMessage, className }: SingleItemErrorProps) => {
  return (
    <div className={`${className} p-y-sm`}>
      <layout.Container>
        <Alert type='message' message={errorMessage} />
      </layout.Container>
    </div>
  );
};

export default SingleItemError;
