import PropTypes from 'prop-types';
import moment from 'moment';
import {
  PostCard,
  PostCardImage,
  PostCardHeader,
  PostCardFooter,
  Button,
} from './styles/infoResourcesItem.styles';

function InfoResourcesItem({ post }) {
  return (
    <PostCard>
      <PostCardImage>
        <img src={post.featuredImage} alt='' />
      </PostCardImage>

      <PostCardHeader>
        <h3 className='is-font-bold is-text-blue'>{post.title}</h3>
        <span>{moment(post.createdDate).format('DD/MM/YYYY')}</span>
      </PostCardHeader>
      <PostCardFooter>
        <Button
          className='ion-padding-start ion-padding-bottom'
          href={post.url}
          rel='noopener noreferrer'
          target='_blank'
          aria-label={'Read more about ' + post.title}
        >
          Read More
        </Button>
      </PostCardFooter>
    </PostCard>
  );
}

InfoResourcesItem.propTypes = {
  post: PropTypes.object,
};

export default InfoResourcesItem;
