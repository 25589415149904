const formatNumber = (number, decimals = 2) => {
  if (typeof number !== 'number') {
    return number;
  }

  return number.toLocaleString('en', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

export { formatNumber };
