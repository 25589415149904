import axios from 'axios';
import { appHelpers } from '../../../helpers';

const generateResetLink = async (email: string) => {
  if (!email) {
    throw new Error('Email address is required');
  }

  const BODY = {
    email,
  };

  const CONFIG = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/auth0/generateResetLink`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: BODY,
  };

  const { data } = await axios(CONFIG);

  return data;
};

export { generateResetLink };
