import { EmptyState } from '../../../../components/emptyState';
import { QueriesEmailListProps } from '../types';
import { QueriesSingleEmailListItem } from './queriesSingleEmailsListItem';

const QueriesSingleEmailList = ({ emails }: QueriesEmailListProps) => {
  if (emails?.length === 0) {
    return <EmptyState label='No replies yet' icon={'none'} />;
  }

  return (
    <>
      {emails &&
        emails
          .sort(
            (a, b) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime()
          )
          .map((email) => (
            <QueriesSingleEmailListItem key={email.id} email={email} />
          ))}
    </>
  );
};

export { QueriesSingleEmailList };
