import { MainLayout } from '../../../../components/layouts/mainLayout';

import { MessagesInbox } from '../components/desktop/messagesInbox';
import { useMatchMedia } from '../../../../hooks/useMatchMedia';
import { MessagesInboxMobile } from '../components/mobile/messagesInboxMobile';

const Messages = () => {
  const isDeskTopResolution = useMatchMedia('(min-width: 992px)', true);
  return (
    <MainLayout>
      {isDeskTopResolution ? <MessagesInbox /> : <MessagesInboxMobile />}
    </MainLayout>
  );
};

export { Messages };
