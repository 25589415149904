import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

const useGetDocuments = (
  searchTerm = '',
  submittedStart = '',
  submittedEnd = ''
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { hsId } = useUserContext();

  const {
    data: documentsData,
    error: isDocumentsError,
    isLoading: isDocumentsLoading,
    refetch,
    isFetching: isFetchingdocuments,
  } = useQuery(
    ['documents', currentPage, searchTerm, submittedStart, submittedEnd],
    async () => {
      if (hsId) {
        if (searchTerm) {
          return await api({
            objectName: 'documents',
            searchValue: searchTerm,
            hsId: hsId,
            submittedStart: submittedStart,
            submittedEnd: submittedEnd,
          }).documents.searchDocuments();
        } else if (submittedStart || submittedEnd) {
          return await api({
            objectName: 'documents',
            searchValue: searchTerm,
            hsId: hsId,
            submittedStart: submittedStart,
            submittedEnd: submittedEnd,
          }).documents.searchDocuments();
        } else {
          return await api({
            hsId: hsId,
            currentPage: currentPage,
            nextPage: currentPage + 1,
          }).documents.getDocuments();
        }
      } else {
        return [];
      }
    },
    { enabled: !!hsId, keepPreviousData: true }
  );

  return {
    documentsData,
    isDocumentsError,
    isDocumentsLoading,
    refetch,
    isFetchingdocuments,
    setCurrentPage,
    currentPage,
  };
};

export { useGetDocuments };
