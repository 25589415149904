export default function StatementsIcon() {
  return (
    <svg
      width='37'
      height='47'
      viewBox='0 0 37 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.3084 0H5.63492C2.53541 0 0 2.48684 0 5.52696V41.473C0 44.5132 2.53541 47 5.63492 47H31.3651C34.4646 47 37 44.5132 37 41.473V15.391L21.3084 0ZM20.0164 17.2532V4.49772L33.021 17.2532H20.0164Z'
        fill='white'
      />
    </svg>
  );
}
