import styled from 'styled-components';

export const InfoResourcesSection = styled.section`
  padding: 6rem 0 0;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100rem;
  margin: auto;
  padding: 0 2.4rem;
  @media (min-width: 992px) {
    padding: 0;
  }
`;

export const InfoResourcesTitle = styled.h2`
  margin-bottom: 5.2rem;
  padding-bottom: 3rem;
  border-bottom: solid 0.2rem #304c85;
  font-size: 30px;
  font-size: 3rem;
  color: #304c85 !important;
  @media (min-width: 992px) {
    font-size: 50px;
    font-size: 5rem;
  }
`;

export const InfoResourcesText = styled.p`
  color: var(--nbs-black);
  font-size: 20px;
  font-size: 2rem;
  text-align: center;
`;
