export default function MenuMessagesBlue() {
  return (
    <svg
      width='24'
      height='15'
      viewBox='0 0 24 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 1.1957L12.004 8.23556L0 1.1957V0.67846C0 0.302284 0.372595 0 0.82619 0H23.1738C23.6274 0 24 0.302284 24 0.67846V1.1957ZM24 12.4474L15.7219 7.59069L24 2.8146V12.4541V12.4474ZM24 14.3148C24 14.691 23.6274 15 23.1738 15H0.82619C0.372595 15 0 14.691 0 14.3148V13.9857L9.67938 8.39678L11.996 9.73354L14.3125 8.39678L23.9919 13.9857V14.3148H24ZM0 2.80788L8.2862 7.59069L0 12.4541V2.80788Z'
        fill='#033F85'
      />
    </svg>
  );
}
