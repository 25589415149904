import { ids } from '../../../../helpers';
import { CreateForecastAssociation, CreateForecastRequestData } from '../types';

const generateForecastAssociations = (
  assiciationData: CreateForecastRequestData
) => {
  let associations: CreateForecastAssociation[];

  const associationIds = ids.getAssociationIds();

  if (assiciationData.companyId) {
    associations = [
      {
        to: { id: assiciationData.companyId },
        types: [
          {
            associationCategory: 'USER_DEFINED',
            associationTypeId: associationIds
              ? associationIds.gen_services_forecast_asset_to_company
              : null,
          },
        ],
      },
    ];
  } else {
    associations = [
      {
        to: { id: assiciationData.hsId },
        types: [
          {
            associationCategory: 'USER_DEFINED',
            associationTypeId: associationIds
              ? associationIds.gen_services_forecast_asset_to_contact
              : null,
          },
        ],
      },
    ];
  }

  if (assiciationData.contractId) {
    associations.push({
      to: { id: assiciationData.contractId },
      types: [
        {
          associationCategory: 'USER_DEFINED',
          associationTypeId: associationIds
            ? associationIds.gen_services_forecast_asset_to_contract
            : null,
        },
      ],
    });
  }

  return associations;
};

export { generateForecastAssociations };
