import { Link } from 'react-router-dom';
import {
  QueriesSingleHeaderStyled,
  QueriesSinglePriorityStyled,
} from '../styles/queriesSingleHeader.styles';

import { QueriesSingleItemProps } from '../types';
import { Container } from '../../../../components/container';
import { StatusBadge } from '../../../../components';

const QueriesSingleHeader = ({ ticket }: QueriesSingleItemProps) => {
  return (
    <QueriesSingleHeaderStyled>
      <Container>
        <div className='query-header-content'>
          <div>
            <h1>{ticket.subject}</h1>
            <ul>
              <li>Submitted by: {ticket.submittedBy}</li>
              {ticket.priority ? (
                <li className='badges'>
                  <QueriesSinglePriorityStyled
                    className={`ticket-priority ticket-priority--${ticket.priority}`}
                  >
                    {ticket.priority}
                  </QueriesSinglePriorityStyled>
                </li>
              ) : null}
            </ul>
            <StatusBadge statusType={ticket.pipelineStage} />
          </div>
          <div className='query-header-back'>
            <Link to='/queries'>Back to queries</Link>
          </div>
        </div>
      </Container>
    </QueriesSingleHeaderStyled>
  );
};

export { QueriesSingleHeader };
