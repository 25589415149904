import styled from 'styled-components';
import { IonCard, IonCardHeader, IonCardContent } from '@ionic/react';

export const PostCard = styled(IonCard)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 0;
  background: var(--white);
`;

export const PostCardImage = styled.figure`
  display: flex;
  position: relative;
  width: 100%;
  height: 25.5rem;
  margin: 0;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }

  &:before {
    background-color: var(--nbs-purple-dark);
    opacity: 0.3;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PostCardContent = styled(IonCardContent)`
  padding: 0 3rem;
  color: var(--nbs-black);
`;

export const PostCardHeader = styled(IonCardHeader)`
  padding: 3rem;

  h3 {
    font-size: 2rem;
  }

  span {
    font-size: 1.4rem;
  }
`;

export const PostCardFooter = styled.footer`
  margin-top: auto;
  padding: 3rem;
`;

export const Button = styled.a`
  display: inline-flex;
  color: #fff;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  padding: 1rem 1.5rem;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.8;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #0a5ca9;
  border: solid 0.1rem #fff;
  border-radius: 999px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    color: var(--white);
    border-color: var(--white);
    box-shadow: 0.2rem 0.2rem 1rem rgb(10 92 169 / 42%);
    background-color: #524388;
  }
`;
