import { useState } from 'react';
import { useParams } from 'react-router';
import { FieldValues, useForm } from 'react-hook-form';
import { FileUpload } from '../fileUpload';

import { useUploadFile } from '../../../hooks/files/useUploadFile';
import { useUpdateCustomObject } from '../../../hooks/customObjects';
import { ids } from '../../../helpers';
import { FormSending } from '../formSending';
import FormSuccess from '../formSuccess/formSuccess';

const HubspotDocUploadForm = () => {
  const { hsId, objectType, recordId } = useParams();
  const { setValue, handleSubmit } = useForm();
  const { handleUploadFile } = useUploadFile();
  const { handleCustomObjectUpdate } = useUpdateCustomObject();
  const [formStatus, setFormStatus] = useState('start');

  const handleDocFormUpload = async (values: FieldValues) => {
    let attachmentKey: string | number | undefined;
    if (typeof values === 'object' && values !== null) {
      attachmentKey = Object.keys(values).find((key) =>
        key.startsWith('attachment_')
      );
    }

    const objectName = objectType ? objectType : '';
    const objectIds = ids.getObjectIds() as Record<string, string>;

    try {
      setFormStatus('sending');
      let file: File;
      let fileUrl: string | undefined;
      if (attachmentKey !== undefined && hsId) {
        file = values[attachmentKey] as File;
        fileUrl = await handleUploadFile(file, hsId, objectName);
      }

      // Create the data to update
      const updateData = {
        properties: {},
        recordId: recordId,
        objectId: '',
      };
      if (objectType && objectType in objectIds) {
        updateData.objectId = objectIds[objectType as keyof typeof objectIds];
        //If object type is statement, add the statement_attachment_url in properties along with fileUrl
        if (objectType === 'statements') {
          updateData.properties = {
            statement_attachment_url: fileUrl,
          };
        }
        //if object type is Document, add the document_attachment in properties along with fileUrl
        if (objectType === 'documents') {
          updateData.properties = {
            document_attachment: fileUrl,
          };
        }
        //If object type is invoice, add the invoice_attachment_url in properties along with fileUrl
        if (objectType === 'invoices') {
          updateData.properties = {
            invoice_attachment_url: fileUrl,
          };
        }
        //If object type is forecast, add the forecast_attachment in properties along with fileUrl
        if (objectType === 'forecasts') {
          updateData.properties = {
            forecast_attachment: fileUrl,
          };
        }
      }

      // Associate This now
      await handleCustomObjectUpdate(updateData);
      setFormStatus('sent');
    } catch (err) {
      setFormStatus('error');
    }
  };

  return (
    <>
      {formStatus === 'start' && (
        <form onSubmit={handleSubmit(handleDocFormUpload)}>
          <FileUpload setValue={setValue} id='statementDoc' />
          <button className='button button--full button-primary--blue'>
            Submit
          </button>
        </form>
      )}
      {formStatus === 'sending' && (
        <FormSending
          title='Uploading document'
          message='Please wait whilst we upload your document'
        />
      )}
      {formStatus === 'sent' && (
        <FormSuccess
          title='Success'
          message='Your document has been uploaded'
        />
      )}
    </>
  );
};

export { HubspotDocUploadForm };
