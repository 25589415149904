import { Dispatch, SetStateAction, createContext } from 'react';

type Message = {
  id: string;
  isArchived: boolean;
  hsUserId: string;
  firebaseUserId: string;
  firebaseNotificationToken: string;
  hsCompanyIds: string;
  action: string;
  objectId: string;
  object: string;
  messageAttachment: null | string;
  updatedOn: number;
  messageBody: string;
  messageId: string;
  subject: string;
  isUnread: boolean;
  from: string;
};

type UserContextState = {
  messages: Array<Message>;
  activeMessage: Message;
  setActiveMessage: Dispatch<SetStateAction<Message>>;
  notificationsList: Array<Message>;
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
  sort: string;
  setSort: Dispatch<SetStateAction<string>>;
  newItems: number;
  messagesLoading: boolean;
  messagesError: boolean | unknown;
};

const defaultMessagesContextState: UserContextState = {
  messages: [
    {
      id: '',
      isArchived: false,
      hsUserId: '',
      firebaseUserId: '',
      firebaseNotificationToken: '',
      hsCompanyIds: '',
      action: '',
      objectId: '',
      object: '',
      messageAttachment: '',
      updatedOn: 0,
      messageBody: '',
      messageId: '',
      subject: '',
      isUnread: false,
      from: '',
    },
  ],
  activeMessage: {
    id: '',
    isArchived: false,
    hsUserId: '',
    firebaseUserId: '',
    firebaseNotificationToken: '',
    hsCompanyIds: '',
    action: '',
    objectId: '',
    object: '',
    messageAttachment: '',
    updatedOn: 0,
    messageBody: '',
    messageId: '',
    subject: '',
    isUnread: false,
    from: '',
  },
  setActiveMessage() {},
  notificationsList: [],
  filter: 'all',
  sort: 'date-newest',
  setFilter: function (): void {
    throw new Error('Function not implemented.');
  },
  setSort: function (): void {
    throw new Error('Function not implemented.');
  },
  newItems: 0,
  messagesLoading: false,
  messagesError: false,
};

const MessagesContext = createContext(defaultMessagesContextState);

export { MessagesContext };
