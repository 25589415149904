import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ReadTicketButton = styled(Link)`
  display: inline-flex;
  align-self: flex-start;
  padding: 1.2rem 2.8rem;
  color: var(--white);
  font-size: 1.4rem;
  font-weight: bold;
  background: var(--nbs-navy-blue);
  border-radius: 99rem;
`;

export const TicketsListPriorityStyled = styled.span`
  display: inline-flex;
  align-self: flex-start;
  padding: 0.4rem 1.2rem;
  color: var(--white);
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0.8rem;

  &.ticket-priority--LOW {
    background: var(--green);
  }

  &.ticket-priority--MEDIUM {
    background: var(--yellow);
  }

  &.ticket-priority--HIGH {
    background: var(--nbs-red);
  }
`;

export const TicketListItemBulletsStyled = styled.ul`
  padding: 0;
  list-style: none;
  font-size: 1.4rem;
`;
