import styled from 'styled-components';

export const HeaderLogoStyled = styled.figure`
  width: 100%;
  max-width: 40rem;
  @media (min-width: 992px) {
    width: 40rem;
  }
  img {
    width: 100%;
    height: auto;
  }
`;
