import { ProfileBlue } from '../../svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
const HeaderProfile = styled(Link)`
  svg {
    width: 4.4rem;
    height: 4.4rem;
    padding: 1rem;
  }
`;
const HeaderProfileMobile = () => {
  return (
    <>
      <HeaderProfile to='/app/my-account'>
        <ProfileBlue />
      </HeaderProfile>
    </>
  );
};

export { HeaderProfileMobile };
