import styled from 'styled-components';

export const FooterContactListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
  padding: 0;
  list-style: none;
  color: var(--white);

  li {
    margin-bottom: 2rem;

    &.contact-button {
      margin-top: 2rem;
    }
  }
`;
