import { Container } from '../../../components/container';
import { Wrapper } from '../../../components/layout';
import { MainLayout } from '../../../components/layouts/authLayout';
import { MigrateUserForm } from '../components/migrateUserForm';

const Migrate = () => {
  return (
    <MainLayout>
      <Wrapper gradient marginTop>
        <Container>
          <MigrateUserForm />
        </Container>
      </Wrapper>
    </MainLayout>
  );
};

export { Migrate };
