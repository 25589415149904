import styled from 'styled-components';

export const TicketsListHeaderStyles = styled.div`
  display: flex;
  align-items: center;
  padding: 1.2rem 0 0;
`;

export const TicketsListSortSelectWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 1.6rem 0;

  .select-wrapper {
    width: 100%;
    max-width: 24rem;
    margin-right: auto;
  }
`;

export const TicketsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3.2rem;
`;

export const TicketsListEmptyStateStyled = styled.div`
  margin-top: 3.2rem;
`;
