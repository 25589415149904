import styled from 'styled-components';

export const SingleStatementInvoicesStyled = styled.section`
  padding: 3.2rem;
  color: var(--nbs-navy-blue);
  background-color: var(--nbs-blue-light);

  h2 {
    margin-bottom: 2rem;
  }
`;

export const SingleStatementInvoicesItemStyled = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 20rem 2.8rem 2.8rem;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
  }
`;

export const SindleStatementInvoicesFormStyled = styled.div`
  margin-top: 3.2rem;
  padding: 3.2rem;
  background: var(--white);
  border-radius: 0.8rem;
  box-shadow: 1.5px 3px 7px rgba(2, 63, 133, 0.1);
`;
