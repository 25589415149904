import styled from 'styled-components';

export const HeaderAccountMenuStyled = styled.div`
  position: relative;
`;

export const DropdownButtonName = styled.button`
  display: flex;
  padding: 0;
  background: none;

  svg {
    margin-left: 0.5rem;
    width: 24px;
    height: 24px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;

  figure {
    width: 100%;
    margin: 0;

    img {
      max-width: 3.2rem;
      mx-height: 3.2rem;
    }
  }
`;
