import HeaderLogo from './headerLogo';
import { HeaderStyled } from './styles/header.styles';
import { LoginButton } from '../buttons';
import { AccountDropdown } from '../accountDropdown';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { useUserContext } from '../../providers/user/useUserContext';
import { NotificationsDropdown } from '../notificationsDropdown';
import { RegisterButton } from '../buttons/registerButton/registerButton';

const Header = () => {
  const { isAuthenticated } = useUserContext();
  const isDeskTopResolution = useMatchMedia('(min-width: 992px)', true);

  return (
    <HeaderStyled>
      <div className='header-items'>
        <HeaderLogo />
        <div className='header-items__actions'>
          {isAuthenticated && isDeskTopResolution ? (
            <NotificationsDropdown />
          ) : null}
          {isAuthenticated && isDeskTopResolution ? <AccountDropdown /> : null}

          {!isAuthenticated && isDeskTopResolution ? (
            <>
              <LoginButton /> <RegisterButton />
            </>
          ) : null}
        </div>
      </div>
    </HeaderStyled>
  );
};

export { Header };
