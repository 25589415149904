import styled from 'styled-components';

export const FormSuccessStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4.8rem;

  h3 {
    color: var(--nbs-blue);
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    margin: 0;
    color: var(--nbs-black);
    text-align: center;
  }
`;
