import { useState } from 'react';
import { useMessagesContext } from '../../providers/messages/useMessagesContext';
import { NotificationsDropdownToggle } from './notificationsDropdownToggle';
import { NotificationsDropdownStyled } from './styles/notificationsDropdown.styles';
import { NotificationsDropdownBadge } from './notificationsDropdownBadge';
import { NotificationsDropdownList } from './notificationsDropdownList';

const NotificationsDropdown = () => {
  const [dropdownState, setDropdownState] = useState(false);
  const { newItems, notificationsList } = useMessagesContext();

  return (
    <NotificationsDropdownStyled>
      {newItems > 0 && <NotificationsDropdownBadge value={newItems} />}
      <NotificationsDropdownToggle
        dropdownState={dropdownState}
        setDropdownState={setDropdownState}
      />
      {dropdownState ? (
        <NotificationsDropdownList notifications={notificationsList} />
      ) : null}
    </NotificationsDropdownStyled>
  );
};

export { NotificationsDropdown };
