export default function DocumentsBlue() {
  return (
    <svg
      width='21'
      height='25'
      viewBox='0 0 21 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M19.0739 4.38477H6.00309C4.93847 4.38477 4.07693 5.2463 4.07693 6.31092V22.7694C4.07693 23.834 4.93847 24.6955 6.00309 24.6955H19.0769C20.1416 24.6955 21.0031 23.834 21.0031 22.7694V6.31092C21.0031 5.2463 20.1416 4.38477 19.0769 4.38477H19.0739ZM16.5385 20.2309H8.53847V17.7694H16.5385V20.2309ZM16.5385 15.6155H8.53847V13.154H16.5385V15.6155ZM16.5385 11.3078H8.53847V8.8463H16.5385V11.3078Z'
          fill='#023F85'
        />
        <path
          d='M1.84615 2.15385H16V0H1.80308C0.806154 0 0 0.806154 0 1.80308V18.1538H1.84615V2.15385Z'
          fill='#023F85'
        />
      </g>
      <defs>
        <clipPath id='clip0_580_14054'>
          <rect width='21' height='24.6923' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
