import {
  SingleItemTickets,
  SingleItemTicketsForm,
  SingleItemLoader,
  SingleItemError,
} from '../../../../components/singleItem';
import { AddQuery } from '../../../../components/forms/queries/addQuery';
import { RefreshButton } from '../../../../components/buttons';

// Hooks
import { useState } from 'react';
import { useGetForecastTickets } from '../../../../hooks/forecasts/useGetForecastTickets';
import { Container } from '../../../../components/container';
import { TicketsList } from '../../../../components/tickets/ticketsList';
import { ForecastsItem } from '../types';
import toast from 'react-hot-toast';

type ForecastsSingleTicketsProps = {
  forecast: ForecastsItem;
};

const ForecastsSingleTickets = ({ forecast }: ForecastsSingleTicketsProps) => {
  const { tickets, ticketsLoading, ticketsError, ticketsRefetch } =
    useGetForecastTickets();
  const [showTicketForm, setShowTicketForm] = useState(false);

  const handleTicketRefresh = () => {
    return ticketsRefetch();
  };

  const onSentCallback = () => {
    ticketsRefetch();
  };

  const loadTickets = () => {
    toast.promise(handleTicketRefresh(), {
      loading: 'Loading queries ...',
      success: 'Forecast queries updated',
      error: 'Error updating forecast queries',
    });
  };

  if (ticketsLoading) {
    return <SingleItemLoader label='Loading queries' />;
  }

  if (ticketsError) {
    return (
      <SingleItemError
        errorMessage='Error loading queries, please refresh your browser'
        className='has-bg-blue-100'
      />
    );
  }

  return (
    <SingleItemTickets>
      <Container>
        <header className='tickets-list-header'>
          <h2>
            Forecast queries <span>{tickets?.results.items.length}</span>
          </h2>
          <div className='tickets-list-actions'>
            <RefreshButton
              refreshFunction={loadTickets}
              label='Update queries'
            />

            <button
              className='button button-primary--blue'
              onClick={() => setShowTicketForm(!showTicketForm)}
            >
              {!showTicketForm ? 'Add Query' : 'View Queries'}
            </button>
          </div>
        </header>
        {showTicketForm ? (
          <SingleItemTicketsForm>
            <AddQuery
              queryType='forecasts'
              queryItem={forecast}
              onSentCallback={onSentCallback}
            />
          </SingleItemTicketsForm>
        ) : (
          //
          <>
            {tickets && (
              <TicketsList showFilters={false} tickets={tickets.results} />
            )}
          </>
        )}
      </Container>
    </SingleItemTickets>
  );
};

export { ForecastsSingleTickets };
