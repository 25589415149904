import { ReactNode, useEffect, useState } from 'react';
import { MessagesContext } from './messagesContext';
import { useMessagesQuery } from '../../hooks/messages';
import { sorters } from '../../helpers';

import { Message } from './types';

type MessagesProviderProps = {
  children: ReactNode;
};

export const MessagesProvider = ({ children }: MessagesProviderProps) => {
  const [notificationsList, setNotificationsList] = useState([]);
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('date-newest');
  const [activeMessage, setActiveMessage] = useState<Message>({
    id: '',
    isArchived: false,
    hsUserId: '',
    firebaseUserId: '',
    firebaseNotificationToken: '',
    hsCompanyIds: '',
    action: '',
    objectId: '',
    object: '',
    messageAttachment: '',
    updatedOn: 0,
    messageBody: '',
    messageId: '',
    subject: '',
    isUnread: false,
    from: '',
  });
  const { messagesData, messagesLoading, messagesError } = useMessagesQuery();

  const updateNotificationsDropdownItems = () => {
    const notifications = messagesData.messages.filter(
      (message: Message) => message.isUnread === true
    );

    setNotificationsList(notifications.slice(0, 8));
  };

  useEffect(() => {
    if (!messagesData) return;

    setActiveMessage(messagesData.messages[0]);
    updateNotificationsDropdownItems();
  }, [messagesData]);

  return (
    <MessagesContext.Provider
      value={{
        messages: messagesData?.messages
          ?.filter((message: Message) => {
            if (filter === 'all') {
              return true;
            } else if (filter === 'unread') {
              return message.isUnread;
            } else if (filter === 'archived') {
              return message.isArchived;
            }
          })
          ?.sort(() => {
            if (sort === 'date-newest') {
              return sorters.sortByDesc(messagesData?.messages, 'updatedOn');
            } else if (sort === 'date-oldest') {
              return sorters.sortByAsc(messagesData?.messages, 'updatedOn');
            } else if (sort === 'type-asc') {
              return sorters.sortByAsc(messagesData?.messages, 'object');
            } else if (sort === 'type-desc') {
              return sorters.sortByDesc(messagesData?.messages, 'object');
            }
          }),
        activeMessage: activeMessage,
        setActiveMessage,
        notificationsList,
        filter: filter,
        setFilter: setFilter,
        sort: sort,
        setSort: setSort,
        newItems: messagesData?.newItems,
        messagesLoading,
        messagesError,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};
