import PropTypes from 'prop-types';
import { AlertStyled } from './styles/alert.styles.js';
import { Link } from 'react-router-dom';

export default function Alert({ type, message, link }) {
  const handleAlertClass = (type) => {
    return type;
  };

  return (
    <AlertStyled className={`alert alert--` + handleAlertClass(type)}>
      <p>
        {message} {link ? <Link to={link}>here</Link> : null}
      </p>
    </AlertStyled>
  );
}

Alert.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  link: PropTypes.string,
};
