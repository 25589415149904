import { CrossBlue } from '../svg';
import { formatters } from '../../helpers';

import { NotificationsDropdownListItemStyled } from './styles/notificationsDropdownListItem.styles';
import { NotificationsDropdownListItemProps } from './types';

import { useMessagesStatusUpdate } from '../../hooks/messages/useMessagesStatusUpdate';

const NotificationsDropdownListItem = ({
  notification,
}: NotificationsDropdownListItemProps) => {
  const { handleUpdateStatus } = useMessagesStatusUpdate();
  return (
    <NotificationsDropdownListItemStyled>
      <div>
        <h5>{notification.subject}</h5>
        <span>
          {formatters.formatUnixDate(
            notification.updatedOn,
            'DD/MM/YYYY HH:mm'
          )}
        </span>
      </div>
      <button onClick={() => handleUpdateStatus(notification.id)}>
        <CrossBlue />
      </button>
    </NotificationsDropdownListItemStyled>
  );
};

export { NotificationsDropdownListItem };
