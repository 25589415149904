import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  // query,
  // getDocs,
  collection,
  // where,
  addDoc,
  connectFirestoreEmulator,
} from 'firebase/firestore';

import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import {
  GoogleAuthProvider,
  getAuth,
  setPersistence,
  browserSessionPersistence,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  getAdditionalUserInfo,
  onAuthStateChanged,
} from 'firebase/auth';

import { appHelpers } from '../helpers';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = appHelpers.getFirebaseConfig();

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const messaging = getMessaging(app);

// eslint-disable-next-line no-restricted-globals

if (
  location.hostname === 'localhost' &&
  process.env.REACT_APP_FIRESTORE_MODE == 'local'
) {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

if (
  location.hostname === 'localhost' &&
  process.env.REACT_APP_FUNCTIONS_MODE == 'local'
) {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}
if (
  location.hostname === 'localhost' &&
  process.env.REACT_APP_AUTH_MODE == 'local'
) {
  // connectAuthEmulator(auth, 'http://localhost:9099');
}

// clear session when closing browser
setPersistence(auth, browserSessionPersistence);

// Authentication
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const { isNewUser } = getAdditionalUserInfo(res);

    if (isNewUser) {
      await addDoc(collection(db, 'users'), {
        userid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
      });
    }

    localStorage.setItem('isSignedIn', true);
  } catch (err) {
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (values) => {
  let { firstName, lastName, email, password } = values;
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      firstName: firstName,
      lastName: lastName,
      authProvider: 'local',
      email: email,
    });
  } catch (err) {
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const getMessagingToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidkey:
      'BEmDazXaRXFeA10ga4bd9CDY0hbk81_Q7mO7gCblXwPHiFTQDuBKCkLbUAJM3XtNQUnAneXSSgEEhqSgtD9MUxI',
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(true);

        return currentToken;
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        );
        setTokenFound(false);
      }
    })
    .catch(() => {
      return null;
    });
};

const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};

export {
  auth,
  db,
  signInWithGoogle,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  functions,
  getMessagingToken,
  onMessageListener,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
};
