import styled from 'styled-components';

export const HeaderStyled = styled.header`
  padding: 2.4rem;

  .header-items {
    display: grid;
    align-items: center;
    width: 100%;
    max-width: 128rem;
    margin: auto;

    @media (min-width: 992px) {
      grid-template-columns: 40rem 1fr 40rem;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 2rem;

      @media (min-width: 992px) {
        grid-column: 3 / 4;
      }
    }
  }

  &.header--onboarding {
    button {
      align-self: flex-end;
      margin-top: 2.4rem;
      @media (min-width: 992px) {
        grid-column: 3 / 4;
        margin-top: 0;
        margin-left: auto;
      }
    }
  }
`;
