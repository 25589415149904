import PropTypes from 'prop-types';
import { CardColDateStyled } from './styles/CardColDate.styles';
import { formatters } from '../../helpers';

const CardColDate = ({ date }) => {
  return (
    <CardColDateStyled>
      {formatters.formatDate(date, 'DD/MM/YYYY')}
    </CardColDateStyled>
  );
};

export default CardColDate;

CardColDate.propTypes = {
  date: PropTypes.string,
};
