import PropTypes from 'prop-types';
import { CardColLabelStyled } from './styles/cardColLabel.styles';

const CardColLabel = ({ className, label }) => {
  return (
    <span className={`pod-card__label ${className}`}>
      <CardColLabelStyled className={className}>{label}</CardColLabelStyled>
    </span>
  );
};

export default CardColLabel;

CardColLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};
