import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

import { useParams } from 'react-router';

const useGetForecastTickets = () => {
  const { hsId } = useUserContext();
  const { id } = useParams();

  const { data, isLoading, error, refetch } = useQuery(
    ['Forecast Tickets'],
    async () => {
      if (hsId) {
        return await api({
          objectType: 'forecasts',
          hsId: hsId,
          recordId: id,
        }).tickets.getTickets();
      } else {
        return [];
      }
    }
  );

  return {
    tickets: data,
    ticketsLoading: isLoading,
    ticketsError: error,
    ticketsRefetch: refetch,
  };
};

export { useGetForecastTickets };
