import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--nbs-black);

  svg {
    width: 4rem;
    height: 4rem;
    margin: auto;
    background: none;
    display: block;
    shape-rendering: auto;
  }

  p {
    margin-left: 0.8rem;
    font-weight: bold;
  }
`;
