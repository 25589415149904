import styled from 'styled-components';

export const PostsList = styled.section`
  padding: 3rem 4rem 6rem;

  h2 {
    color: var(--nbs-blue);
    font-size: 1.8rem;
    font-weight: 700;
  }
`;
