import styled from 'styled-components';

export const QueriesSingleEmailReplyStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;

  h4 {
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  textarea {
    width: 100%;
    height: 16rem;
    margin-bottom: 2rem;
    padding: 1.6rem 2rem;
    border: none;
    border-radius: 0.8rem;
  }

  .form-error {
    margin-bottom: 1.2rem;
  }

  button {
    width: auto;
    height: auto;
    align-items: center;
    align-self: flex-start;
    margin-top: 0.8rem;
    padding: 1.6rem 2.8rem;
    color: var(--white);
    font-weight: bold;
    text-transform: uppercase;
    background-color: var(--nbs-navy-blue);
    border-radius: 999px;
  }
`;
