const formatCurrency = (value: string) => {
  const currencyFormat = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  return currencyFormat.format(Number(value));
};

export { formatCurrency };
