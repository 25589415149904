import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';

const useUpdateCustomObject = () => {
  const queryClient = useQueryClient();

  const mutateCustomObject = async (updateData: unknown) => {
    // Call the mutation apu thing
    await api(updateData).customObjects.updateCustomObject();
  };

  const mutation = useMutation({
    mutationFn: mutateCustomObject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['statements'] });
    },
  });

  const handleCustomObjectUpdate = async (updateData: unknown) => {
    // create

    mutation.mutate(updateData);
  };

  return { handleCustomObjectUpdate };
};

export { useUpdateCustomObject };
