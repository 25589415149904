import styled from 'styled-components';
import menuLine from '../../../assets/img/nav-bar-bg.webp';

export const NotificationsDropdownListStyled = styled.div`
  position: absolute;
  top: 100%;
  right: -90px;
  width: 90vw;
  background-color: var(--white);
  box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
  @media (min-width: 992px) {
    width: 44rem;
    right: 0;
  }
  header {
    padding: 2rem 4rem;

    h4 {
      margin: 0;
      color: var(--nbs-navy-blue);
    }
  }

  .notifications-divider {
    background: url(${menuLine}) no-repeat;
    background-size: cover;
    background-position: center;
    height: 15px;
  }

  ul {
    margin: 0;
    padding: 0 4rem;

    .no-items {
      margin: 1.2rem 0;
      padding: 0;
      list-style: none;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    padding: 1.2rem 4rem;
    font-size: 1.4rem;
  }
`;
