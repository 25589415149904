import { useParams } from 'react-router';
import { api } from '../../api/apiHandler';
import { useQuery } from '@tanstack/react-query';

import { useUserContext } from '../../providers/user/useUserContext';

const useGetSingleInvoice = () => {
  const { id } = useParams();
  const { hsId } = useUserContext();

  const { data, isLoading, error } = useQuery({
    queryKey: ['invoice', id],
    queryFn: async () => {
      if (hsId) {
        return await api({
          invoiceId: id,
          hsId: hsId,
        }).invoices.getInvoice();
      } else {
        return [];
      }
    },
    enabled: !!hsId,
  });

  return {
    singleInvoiceData: data,
    singleInvoiceError: error,
    singleInvoiceLoading: isLoading,
  };
};

export { useGetSingleInvoice };
