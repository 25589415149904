export default function ProfileBlue() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='30'
      viewBox='0 0 32 30'
    >
      <defs>
        <clipPath id='clip-path'>
          <rect width='32' height='30' fill='none' />
        </clipPath>
      </defs>
      <g id='Avatar' transform='translate(-16.909 -16.395)'>
        <g id='User' transform='translate(16.909 16.395)'>
          <path
            id='Union_1'
            data-name='Union 1'
            d='M0,32V28c0-4.4,7.2-8,16-8s16,3.6,16,8v4ZM8,8a8,8,0,1,1,8,8A8,8,0,0,1,8,8Z'
            transform='translate(0.001 0)'
            fill='#214081'
          />
        </g>
      </g>
    </svg>
  );
}
