import styled from 'styled-components';
import menuBg from '../../../assets/img/nav-bar-bg.webp';

export const NavStyled = styled.nav`
  width: 100%;
  min-height: 2.4rem;
  padding: 0;
  background: url(${menuBg}) no-repeat 50% center;
  background-size: cover;
  background-color: #1b427f;
`;

export const NavListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 128rem;
  margin: 0 auto;
  li {
    margin: 0;
    padding: 0;

    a {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      height: 88px;
      padding: 0 3.2rem;
      color: var(--white);
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;

      .nav-link {
        margin: 0;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
`;
