import { TicketsList } from '../../../../components/tickets/ticketsList';
import { useGetStatementTickets } from '../../../../hooks/statements/useGetStatementTickets';
import { Container } from '../../../../components/container';

import {
  SingleItemTickets,
  SingleItemTicketsForm,
  SingleItemLoader,
  SingleItemError,
} from '../../../../components/singleItem';

import toast from 'react-hot-toast';
import { useState } from 'react';
import { RefreshButton } from '../../../../components/buttons';
import { AddQuery } from '../../../../components/forms/queries/addQuery';
import { StatementItem } from '../types';

type SingleStatementTicketProps = {
  statementData: StatementItem;
};

const StatementSingleTickets = ({
  statementData,
}: SingleStatementTicketProps) => {
  const [showTicketForm, setShowTicketForm] = useState(false);
  const { tickets, ticketsLoading, ticketsError, refetchTickets } =
    useGetStatementTickets();

  const handleTicketRefresh = () => {
    return refetchTickets();
  };

  const onSentCallback = () => {
    refetchTickets();
  };

  const loadTickets = () => {
    toast.promise(handleTicketRefresh(), {
      loading: 'Loading queries ...',
      success: 'Statement queries updated',
      error: 'Error updating statement queries',
    });
  };

  if (ticketsLoading) {
    return <SingleItemLoader label='Loading queries' />;
  }

  if (ticketsError) {
    return (
      <SingleItemError
        errorMessage='Error loading queries, please refresh your browser'
        className='has-bg-blue-100'
      />
    );
  }

  return (
    <SingleItemTickets>
      <Container>
        <header className='tickets-list-header'>
          <h2>
            Statement Queries <span>{tickets?.results.items.length}</span>
          </h2>
          <div className='tickets-list-actions'>
            <RefreshButton
              refreshFunction={loadTickets}
              label='Update queries'
            />

            <button
              className='button button-primary--blue'
              onClick={() => setShowTicketForm(!showTicketForm)}
            >
              {!showTicketForm ? 'Add Query' : 'View Queries'}
            </button>
          </div>
        </header>
        {showTicketForm ? (
          <SingleItemTicketsForm>
            <AddQuery
              queryType='statements'
              queryItem={statementData}
              onSentCallback={onSentCallback}
            />
          </SingleItemTicketsForm>
        ) : (
          <>
            {tickets && (
              <TicketsList showFilters={false} tickets={tickets.results} />
            )}
          </>
        )}
      </Container>
    </SingleItemTickets>
  );
};

export { StatementSingleTickets };
