export const FIREBASE_DEV_CONFIG = {
  apiKey: 'AIzaSyCJpfg6IVQZV7Jjt0A72atB6OPg9z_wMKc',
  authDomain: 'nbs-energy-generators-dev.firebaseapp.com',
  projectId: 'nbs-energy-generators-dev',
  storageBucket: 'nbs-energy-generators-dev.appspot.com',
  messagingSenderId: '1061480075821',
  appId: '1:1061480075821:web:8f8dfcafa4461651061ea4',
};

export const FIREBASE_PROD_CONFIG = {
  apiKey: 'AIzaSyC7KPGouL13iyr882ETzYCW_ds1VK9d4Mc',
  authDomain: 'nbs-energy-generators-app.firebaseapp.com',
  projectId: 'nbs-energy-generators-app',
  storageBucket: 'nbs-energy-generators-app.appspot.com',
  messagingSenderId: '257835511179',
  appId: '1:257835511179:web:c950f74f2bc3852f0a538e',
};

export const FIREBASE_STAGING_CONFIG = {
  apiKey: 'AIzaSyCG2uj1VS_2jARqE8HakTlQHv_oTzoq5vc',
  authDomain: 'nbs-energy-generators-staging.firebaseapp.com',
  projectId: 'nbs-energy-generators-staging',
  storageBucket: 'nbs-energy-generators-staging.appspot.com',
  messagingSenderId: '330067093423',
  appId: '1:330067093423:web:9a6aff3d3ff6804d46b15a',
};
