import axios from 'axios';
import { appHelpers } from '../../../helpers';

const getPosts = async (tagId) => {
  let body = {
    tagId: tagId,
  };

  let config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/posts/getPosts`,
    data: body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
  };

  return await axios(config);
};

export { getPosts };
