import { FORECAST_PROPERTIES } from '../../../constants/forecastObjectProperties';
import { appHelpers } from '../../../helpers';

import { ids } from '../../../helpers';

import axios from 'axios';

import { ApiRequestData } from '../../../types/api';

const getForecast = async (requestData: ApiRequestData) => {
  if (!requestData) {
    throw new Error('Unable to fetch invoices');
  }

  const { hsId, companyId, uid, recordId } = requestData;

  const objectIds = ids.getObjectIds();
  const pipelineIds = ids.getPipelineIds();

  const BODY = {
    uid: uid,
    companyId: companyId,
    hsId: hsId,
    objectType: objectIds ? objectIds.forecasts : null,
    properties: FORECAST_PROPERTIES,
    pipelineId: pipelineIds ? pipelineIds.forecasts : null,
  };

  const CONFIG = {
    url: `${appHelpers.getApiUrl()}/api/v2/forecasts/getForecast/${recordId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching forecast #${recordId}: ${err.message}`);
    } else {
      throw new Error(`Error fetching forecast #${recordId}`);
    }
  }
};

export { getForecast };
