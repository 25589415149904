import { MyAccountFormStyled } from '../styles/myAccountForm.styles';
import { useGetHubspotUser } from '../../../hooks/user';
import { Loader } from '../../../components/loader';
import { FieldValues, useForm } from 'react-hook-form';
import { useUpdateUser } from '../hooks/useUpdateUser';
import { UserFormValues } from '../types';
import { Alert } from '../../../components/alert';

const MyAccountForm = () => {
  const { hsUserData, isLoadingUser, userError } = useGetHubspotUser();
  const { handleSubmit, register } = useForm();

  const { handleUpdateUser, formStatus } = useUpdateUser();

  const handleFormSubmit = async (userValues: FieldValues) => {
    await handleUpdateUser(userValues as UserFormValues);
  };

  if (isLoadingUser) {
    return (
      <MyAccountFormStyled>
        <h2>My account</h2>
        <Loader />
      </MyAccountFormStyled>
    );
  }

  if (userError) {
    return (
      <MyAccountFormStyled>
        <h2>My account</h2>
        <p>Error loading your user details</p>
      </MyAccountFormStyled>
    );
  }

  return (
    <MyAccountFormStyled>
      <h2>My account</h2>
      {formStatus === 'saving' && <p>Please wait...</p>}
      {formStatus === 'success' && (
        <Alert type='success' message='User details updated' />
      )}
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className='form-group'>
          <label>First Name</label>
          <input
            className='form-control'
            {...register('firstName')}
            defaultValue={hsUserData?.properties?.firstname || ''}
          />
        </div>
        <div className='form-group'>
          <label>Last Name</label>
          <input
            className='form-control'
            {...register('lastName')}
            defaultValue={hsUserData?.properties?.lastname || ''}
          />
        </div>
        <div className='form-group'>
          <label>Job Title</label>
          <input
            className='form-control'
            {...register('jobTitle')}
            defaultValue={hsUserData?.properties?.jobtitle || ''}
          />
        </div>
        <div className='form-group'>
          <label>Email address</label>
          <input
            type='email'
            className='form-control'
            {...register('email')}
            defaultValue={hsUserData?.properties?.email}
            disabled
          />
        </div>
        <div className='form-group'>
          <button
            className='button button-primary--blue button--full'
            type='submit'
          >
            Save changes
          </button>
        </div>
      </form>
    </MyAccountFormStyled>
  );
};

export { MyAccountForm };
