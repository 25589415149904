import styled from 'styled-components';

export const FooterListStyled = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none;

    li {
      padding: 0.8rem 0;
      a {
        color: var(--white);
        font-size: 1.5rem;
      }
    }
  }
`;
