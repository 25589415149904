import {
  OBJECT_IDS,
  OBJECT_IDS_DEV,
  OBJECT_IDS_PROD,
} from '../../constants/hsObjectIds';

const getObjectIds = () => {
  if (
    process.env.REACT_APP_MODE === 'development' ||
    process.env.REACT_APP_MODE === 'local'
  ) {
    return OBJECT_IDS_DEV;
  } else if (process.env.REACT_APP_MODE === 'staging') {
    return OBJECT_IDS;
  } else if (process.env.REACT_APP_MODE === 'production') {
    return OBJECT_IDS_PROD;
  } else {
    return null;
  }
};

export { getObjectIds };
