import styled from 'styled-components';

export const SingleItemHeaderStyled = styled.header`
  display: grid;
  grid-gap: 3.2rem;
  max-width: 123.6rem;
  margin: auto;
  padding: 2rem 4rem;
  color: var(--nbs-navy-blue);

  h1 {
    font-weight: bold;
  }

  a {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 992px) {
    align-items: center;
    grid-template-columns: 1fr 20rem;

    a {
      justify-content: flex-end;
    }
  }
`;
