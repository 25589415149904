import { VALID_FILE_EXTENSIONS } from '../../constants/fileUploadRules';

const isValidFileType = function (fileName: string) {
  if (!fileName) return false;

  return (
    fileName &&
    VALID_FILE_EXTENSIONS.indexOf(fileName.split('.').pop() || '') > -1
  );
};

export { isValidFileType };
