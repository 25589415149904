import PropTypes from 'prop-types';

import { CardStyled } from './styles/card.styles';

const Card = ({ cols, children, className }) => {
  return (
    <CardStyled cols={cols} data-testid='card-item' className={className}>
      {children}
    </CardStyled>
  );
};

export default Card;

Card.propTypes = {
  cols: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
