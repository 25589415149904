const MenuCloseWhite = () => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <circle cx='17' cy='17' r='16.1905' stroke='white' />
        <path
          d='M23 12.2086L21.7914 11L17 15.7914L12.2086 11L11 12.2086L15.7914 17L11 21.7914L12.2086 23L17 18.2086L21.7914 23L23 21.7914L18.2086 17L23 12.2086Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_499_2559'>
          <rect width='34' height='34' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { MenuCloseWhite };
