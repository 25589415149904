import PropTypes from 'prop-types';
import { CardColStatusStyled } from './styles/cardColStatus.styles';
import { StatusBadge } from '../statusBadge';

const CardColStatus = ({ className, status }) => {
  return (
    <CardColStatusStyled className={className}>
      <StatusBadge statusType={status} />
    </CardColStatusStyled>
  );
};

export default CardColStatus;

CardColStatus.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
};
