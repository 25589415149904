import { MessagesWrapperStyled } from '../../style/messagesInbox.styles';
import { MessagesInboxActiveMessage } from './messagesInboxActiveMessage';
import { MessagesInboxList } from './messagesInboxList';

const MessagesInbox = () => {
  return (
    <MessagesWrapperStyled>
      <MessagesInboxList />
      <MessagesInboxActiveMessage />
    </MessagesWrapperStyled>
  );
};

export { MessagesInbox };
