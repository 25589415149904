export default function InvoicesBlue() {
  return (
    <svg
      width='26'
      height='24'
      viewBox='0 0 26 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <rect
          width='24.4841'
          height='24'
          transform='translate(0.979828)'
          fill='white'
        />
        <path
          d='M12.1685 18.8761C12.1685 18.5655 12.1867 18.2611 12.2233 17.9627H4.48103V15.5271H12.9113C14.1718 12.8296 16.9088 10.9603 20.0842 10.9603C20.5257 10.9603 20.958 10.9968 21.3782 11.0669V0H19.0948C19.0948 0.67284 18.5498 1.21781 17.8769 1.21781C17.2041 1.21781 16.6591 0.67284 16.6591 0H14.8324C14.8324 0.67284 14.2875 1.21781 13.6146 1.21781C12.9418 1.21781 12.3968 0.67284 12.3968 0H10.2656C10.2656 0.67284 9.72066 1.21781 9.04782 1.21781C8.37498 1.21781 7.83001 0.67284 7.83001 0H5.69884C5.69884 0.67284 5.15387 1.21781 4.48103 1.21781C3.80819 1.21781 3.26322 0.67284 3.26322 0H0.979828V18.5716C0.979828 20.581 2.62387 22.225 4.63326 22.225H12.9113C12.4364 21.2082 12.1685 20.0726 12.1685 18.8761ZM4.48103 5.48015H17.8769V7.91577H4.48103V5.48015ZM4.48103 10.9603H13.3102V13.3959H4.48103V10.9603Z'
          fill='#023F85'
        />
        <path
          d='M19.9837 13.0397C16.9575 13.0397 14.5036 15.4936 14.5036 18.5198C14.5036 21.5461 16.9575 24 19.9837 24C23.01 24 25.4639 21.5461 25.4639 18.5198C25.4639 15.4936 23.01 13.0397 19.9837 13.0397ZM19.7767 21.1472L19.725 21.0955L19.3688 21.4517L16.1385 18.2215L17.6456 16.7144L19.4205 18.4894L22.3219 15.5879L23.829 17.095L19.7798 21.1442L19.7767 21.1472Z'
          fill='#023F85'
        />
      </g>
      <defs>
        <clipPath id='clip0_580_14047'>
          <rect
            width='24.4841'
            height='24'
            fill='white'
            transform='translate(0.979828)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
