import { TICKETS_PROPERTIES } from '../../constants/ticketObjectProperties';
import { CONTACT_PROPERTIES } from '../../constants/contactProperties';
import { FORECAST_PROPERTIES } from '../../constants/forecastObjectProperties';
import { STATEMENT_PROPERTIES } from '../../constants/statementObjectProperties';
import { INVOICES_PROPERTIES } from '../../constants/invoicesObjectProperties';
import { DOCS_PROPERTIES } from '../../constants/docsObjectProperties';

const getObjectProperties = (objectName: string) => {
  switch (objectName) {
    case 'tickets':
      return TICKETS_PROPERTIES;
    case 'contacts':
      return CONTACT_PROPERTIES;
    case 'forecasts':
      return FORECAST_PROPERTIES;
    case 'statements':
      return STATEMENT_PROPERTIES;
    case 'invoices':
      return INVOICES_PROPERTIES;
    case 'documents':
      return DOCS_PROPERTIES;
    default:
      return null;
  }
};

export { getObjectProperties };
