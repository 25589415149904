import { AssociationsItem } from '../../types/associations';

import { api } from '../../api/apiHandler';
import { ids } from '../../helpers';
import { FieldValues } from 'react-hook-form';

// interface TicketValues {
//   ticketSubject: string;
//   ticketContent: string;
//   category: string;
//   statementId: string;
//   invoiceId: string;
//   forecastId: string;
// }
interface TicketData {
  fileUrl: string | undefined;
  associations: AssociationsItem | unknown;
  values: FieldValues;
  hsId: string | null | undefined;
}

const useCreateTicket = async (ticketData: TicketData) => {
  const pipelineIds = ids.getPipelineStageIds();

  const requestData = {
    hsId: ticketData.hsId,
    associations: ticketData.associations,
    properties: {
      subject: ticketData.values.ticketSubject,
      content: ticketData.values.ticketContent,
      hs_ticket_category: ticketData.values.category,
      statement_id: ticketData.values.statementId,
      invoice_id: ticketData.values.invoiceId,
      forecast_id: ticketData.values.forecastId,
      hs_pipeline_stage: pipelineIds ? pipelineIds.ticketSubmitted : '',
      ticket_file_attachment: ticketData.fileUrl,
      // submitted_by: `${ticketData.firstname} ${ticketData.lastname}`,
      source_type: 'FORM',
      notify_customer: true,
    },
  };

  try {
    const { data } = await api(requestData).tickets.createTicket();
    return {
      status: 'success',
      message: 'Query created successfully',
      data: data,
    };
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error creating ticket: ${err.message}`);
    } else {
      throw new Error(`Error creating ticket`);
    }
  }
};

export { useCreateTicket };
