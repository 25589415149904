import styled from 'styled-components';

export const SkeletonDashboardWidgetStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 24rem;
  background: var(--nbs-blue-light-100);

  --glare-bg: linear-gradient(
    90deg,
    transparent,
    hsla(0, 0%, 100%, 0.4),
    transparent
  );

  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--glare-bg);
    transform: translateX(-100%);
    animation: skeleton-glare 1.75s infinite ease-out;
    z-index: 1;
  }

  @keyframes skeleton-glare {
    to {
      transform: translateX(100%);
    }
  }

  .skeleton-dashboard-widget__icon {
    width: 5.2rem;
    height: 5.2rem;
    margin-bottom: 2rem;
    background: var(--nbs-blue-light);
    border-radius: 50%;
  }

  .skeleton-dashboard-widget__content {
    width: 100%;
    max-width: 60%;
    height: 3.2rem;
    background: var(--nbs-blue-light);
    border-radius: 0.8rem;
  }
`;
