import { useGetAllInvoices } from '../../../../hooks/invoices';
import { AddQueryDropdownProps, InvoiceItem } from './types';

const AddQueryInvoicesDropdown = ({ register }: AddQueryDropdownProps) => {
  const { invoicesData, isInvoicesLoading } = useGetAllInvoices();

  return (
    <div className='form-group form-group--select'>
      <label htmlFor='invoiceNumber'>Select Invoice</label>
      <div className='select-wrapper'>
        <select id='invoiceNumber' {...register('invoiceNumber')}>
          {isInvoicesLoading ? (
            <option selected disabled value=''>
              Loading invoices
            </option>
          ) : null}
          {!isInvoicesLoading && invoicesData.results.length > 0 ? (
            <>
              <option selected disabled value=''>
                Select invoice
              </option>
              {invoicesData.results.map((invoice: InvoiceItem) => (
                <option key={invoice.id} value={invoice.id}>
                  {invoice.invoiceNumber}
                </option>
              ))}
            </>
          ) : (
            <>
              <option selected disabled value=''>
                No invoices
              </option>
            </>
          )}
        </select>
      </div>
    </div>
  );
};

export { AddQueryInvoicesDropdown };
