import { global } from '..';
import { SindleItemLoaderStyled } from './styles/singleItemLoader.styles';

type SingleItemLoaderProps = {
  label: string;
};

const SingleItemLoader = ({ label }: SingleItemLoaderProps) => {
  return (
    <SindleItemLoaderStyled>
      <div>
        <global.Loader />
        <h2>{label}</h2>
      </div>
    </SindleItemLoaderStyled>
  );
};

export default SingleItemLoader;
