import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

const useGetCompanyMembers = () => {
  const { hsId, companyIds } = useUserContext();

  const handleGetCompanyMembers = async () => {
    return await api({
      hsId: hsId,
      companyIds: companyIds,
    }).companies.fetchCompanyMembers();
  };

  const { data } = useQuery({
    queryKey: ['comapnyMembers'],
    queryFn: handleGetCompanyMembers,
    enabled: !!hsId,
  });

  return { data };
};

export { useGetCompanyMembers };
