import { useState } from 'react';
import {
  MessagesInboxFilterWrapper,
  MessagesInboxFilterList,
} from '../../style/messagesInboxFilter.styles';

import { MessagesInboxFilterButton } from './messagesInboxFilterButton';
import { useMessagesContext } from '../../../../../providers/messages/useMessagesContext';

const MessagesInboxFilter = () => {
  const [activeButton, setActiveButton] = useState('all'); // ['all', 'unread', 'archived'
  const { newItems, setFilter } = useMessagesContext();

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  return (
    <MessagesInboxFilterWrapper>
      <MessagesInboxFilterList>
        <li>
          <MessagesInboxFilterButton
            className={`${activeButton === 'all' ? 'is-active' : ''}`}
            label='all'
            action={handleFilter}
            setActiveButton={setActiveButton}
          />
        </li>
        <li>
          <MessagesInboxFilterButton
            className={`${activeButton === 'unread' ? 'is-active' : ''}`}
            label='unread'
            action={handleFilter}
            count={newItems}
            setActiveButton={setActiveButton}
          />
        </li>
        <li>
          <MessagesInboxFilterButton
            className={`${activeButton === 'archived' ? 'is-active' : ''}`}
            label='archived'
            action={handleFilter}
            setActiveButton={setActiveButton}
            // count={archivedMessagesCount}
          />
        </li>
      </MessagesInboxFilterList>
    </MessagesInboxFilterWrapper>
  );
};

export { MessagesInboxFilter };
