import axios from 'axios';
import { GetTicketRequest } from './types';
import { appHelpers } from '../../../helpers';
import { EMAIL_PROPERTIES } from '../../../constants/emailProperties';

const getSingleTicketEmails = async (requestData: GetTicketRequest) => {
  if (!requestData) {
    throw new Error('Unable to fetch invoice');
  }

  const { ticketId, hsId } = requestData;

  const BODY = {
    ticketId: ticketId,
    hsId: hsId,
    properties: EMAIL_PROPERTIES,
  };

  const CONFIG = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/tickets/getSingleTicketEmails`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching ticket emails: ${err.message}`);
    } else {
      throw new Error(`Error fetching ticket emails`);
    }
  }
};

export { getSingleTicketEmails };
