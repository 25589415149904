export const EMAIL_PROPERTIES = [
  'hs_email_subject',
  'hs_email_text',
  'hs_email_direction',
  'hs_email_html',
  'hs_email_status',
  'hs_email_from',
  'hs_email_from_firstname',
  'hs_email_from_lastname',
  'hs_email_to_email',
  'hs_email_to_firstname',
  'hs_email_to_lastname',
  'hs_email_headers',
];
