import styled from 'styled-components';

export const QueryEmailHeader = styled.header`
  display: flex;
  align-items: center;
  margin: 4rem 0 0;
  padding: 4rem 0 2rem;
  border-top: solid 0.1rem var(--white);

  h2 {
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;

    span {
      background: none;
    }

    svg {
      position: relative;
      top: -0.2rem;
      width: 1.2rem;
      fill: var(--nbs-navy-blue);
    }

    &.is-active {
      span {
        transform: rotate(180deg);
      }
    }
  }
  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 0.8rem;
    color: var(--white);
    font-size: 1.2rem;
    font-weight: bold;
    background-color: var(--nbs-navy-blue);
    border-radius: 50%;
  }

  .actions {
    margin-left: auto;

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 auto;
      padding: 0.8rem 1.2rem;

      font-size: 1.4rem;
      font-weight: bold;
      text-transform: uppercase;

      border-radius: 0.8rem;
    }

    .button-refresh {
      color: var(--nbs-navy-blue);
      background: none;
    }

    .button-reply {
      margin-left: 0.8rem;
      color: var(--white);
      background-color: var(--nbs-navy-blue);
    }
  }
`;

export const QueriesSingleEmailItemStyled = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;

  .email__subject {
    margin-bottom: 0;
  }
  .email__message {
    padding: 1.6rem 2rem;
    background: var(--white);
    border-radius: 0.8rem;
    box-shadow: 1.5px 3px 7px rgba(2, 63, 133, 0.1);
  }

  .email__from-date {
    display: inline-flex;
    margin-bottom: 1.2rem;
    color: var(--nbs-black);
    font-size: 1.4rem;
  }

  .email__content {
    color: var(--nbs-black);
  }

  .email__html-content {
    margin-top: 1.6rem;
  }
`;
