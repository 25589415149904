import { useGetContracts } from '../../../../hooks/contracts';
import { AddQueryDropdownProps } from './types';

type ContractItem = {
  id: string;
  contract_account_name: string;
};

const AddQueryContractsDropdown = ({ register }: AddQueryDropdownProps) => {
  const { contractsData, isContractsLoading } = useGetContracts();

  if (!contractsData || !contractsData.results.isArray) {
    return null;
  }

  return (
    <div className='form-group form-group--select'>
      <label htmlFor='contract_id'>Select Contract</label>
      <div className='select-wrapper'>
        <select {...register('contract_id')}>
          {isContractsLoading ? (
            <option selected disabled value=''>
              Loading contracts
            </option>
          ) : null}
          {!isContractsLoading && contractsData.results.length > 0 ? (
            <>
              <option selected disabled value=''>
                Select contract
              </option>
              {contractsData.results.map((contract: ContractItem) => (
                <option key={contract.id} value={contract.id}>
                  {contract.contract_account_name}
                </option>
              ))}
            </>
          ) : (
            <>
              <option selected disabled value=''>
                No Contracts
              </option>
            </>
          )}
        </select>
      </div>
    </div>
  );
};

export { AddQueryContractsDropdown };
