import PropTypes from 'prop-types';
import FooterListItem from './footerItem';

import { FooterColumnTitleStyled } from './styles/footer.styles';
import { FooterListStyled } from './styles/footerList.styles';

export default function FooterList({ listTitle, listMap }) {
  return (
    <FooterListStyled>
      <FooterColumnTitleStyled className='is-uppercase is-text-white is-font-bold'>
        {listTitle}
      </FooterColumnTitleStyled>
      <ul>
        {listMap.map((item) => (
          <FooterListItem
            footerItemUrl={item.url}
            footerItemTitle={item.title}
            key={item.id}
          />
        ))}
      </ul>
    </FooterListStyled>
  );
}

FooterList.propTypes = {
  listTitle: PropTypes.string,
  listMap: PropTypes.array,
};
