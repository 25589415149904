import styled from 'styled-components';

export const SingleMessageMobileStyled = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: #fff;
  transform: translate3d(-100%, 0, 0);

  &.is-active {
    transform: translate3d(0, 0, 0);
  }
`;

export const SingleMessageMobileHeaderStyled = styled.header`
  background-color: var(--nbs-navy-blue);
  padding: 2.4rem 4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    color: var(--white);
    font-size: 2.4rem;
    font-weight: 700;
  }
`;

export const SingleMessageMobileEmptyStateStyled = styled.div`
  display: flex;
  justify-content: center;
`;
