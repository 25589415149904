const CrossBlue = () => {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_499_8729)'>
        <circle
          cx='11.5'
          cy='11.5'
          r='10.9524'
          fill='#023F85'
          stroke='#023F85'
          strokeWidth='1.09524'
        />
        <path
          d='M15.5588 8.25897L14.7412 7.44141L11.5 10.6827L8.25873 7.44141L7.44116 8.25897L10.6824 11.5002L7.44116 14.7415L8.25873 15.5591L11.5 12.3178L14.7412 15.5591L15.5588 14.7415L12.3175 11.5002L15.5588 8.25897Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_499_8729'>
          <rect width='23' height='23' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CrossBlue;
