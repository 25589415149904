export default function RefreshBlue() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='m-r-2xs'
    >
      <path
        d='M15.1208 2.87884C13.55 1.30801 11.3942 0.333008 9.00001 0.333008C4.21168 0.333008 0.344177 4.21134 0.344177 8.99967C0.344177 13.788 4.21168 17.6663 9.00001 17.6663C13.0408 17.6663 16.41 14.9038 17.3742 11.1663H15.1208C14.2325 13.6905 11.8275 15.4997 9.00001 15.4997C5.41418 15.4997 2.50001 12.5855 2.50001 8.99967C2.50001 5.41384 5.41418 2.49967 9.00001 2.49967C10.7983 2.49967 12.4017 3.24717 13.5717 4.42801L10.0833 7.91634H17.6667V0.333008L15.1208 2.87884Z'
        fill='#023F85'
      />
    </svg>
  );
}
