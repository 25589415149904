import { useParams } from 'react-router';
import { api } from '../../api/apiHandler';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from '../../providers/user/useUserContext';

const useGetForecast = () => {
  const { id, accountId } = useParams();
  const { hsId } = useUserContext();

  const { data, isLoading, error } = useQuery(
    ['ForecastSingle', id],
    async () => {
      if (id) {
        return await api({
          hsId: hsId,
          companyId: accountId,
          recordId: id,
        }).forecasts.getForecast();
      } else {
        return [];
      }
    },
    { enabled: !!hsId }
  );

  return {
    data,
    isLoading,
    error,
  };
};

export { useGetForecast };
