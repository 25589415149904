import { AddQueryFormItemProps } from '../types';
import { AddQueryCategories } from './addQueryCategories';
// import { useState } from 'react';
import {
  AddQueryForecastsDropdown,
  AddQueryInvoicesDropdown,
  AddQueryStatementsDropdown,
} from './dropdowns';

const AddQueryAll = ({
  categories,
  register,
  addQueryType,
  setAddQueryType,
}: AddQueryFormItemProps) => {
  // const [queryType, setQueryType] = useState<string>();

  return (
    <>
      <AddQueryCategories
        categories={categories}
        register={register}
        setQueryType={setAddQueryType}
      />
      {addQueryType === 'statements' && (
        <AddQueryStatementsDropdown register={register} />
      )}
      {addQueryType === 'invoices' && (
        <AddQueryInvoicesDropdown register={register} />
      )}
      {addQueryType === 'forecasts' && (
        <AddQueryForecastsDropdown register={register} />
      )}
    </>
  );
};

export { AddQueryAll };
