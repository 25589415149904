import styled from 'styled-components';

export const QueriesSingleInfoWidgetStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 3.2rem 0;

  h4 {
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  ul {
    margin: 0 0 2rem 0;
    padding: 0;
    list-style: none;
  }
`;
