import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getAuth0Config } from '../../config/auth0';

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const auth0Config = getAuth0Config();

  const domain = auth0Config.domain;
  const clientId = auth0Config.clientId;
  const redirectUri = auth0Config.redirectUri;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: `https://${auth0Config.domain}/api/v2/`,
        scope:
          'read:current_user update:current_user_metadata, openid, profile email phone',
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation='localstorage'
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithNavigate.propTypes = {
  children: PropTypes.node,
};
