import styled from 'styled-components';

export const MessagesInboxFilterWrapper = styled.div`
  padding: 1.2rem 2 rem;
  background-color: var(--nbs-ui-grey);
`;

export const MessagesInboxFilterList = styled.ul`
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    justify-content: center;
    width: 33.3333333%;
    max-width: 12rem;

    @media (min-width: 492px) {
      width: auto;
    }
  }
`;

export const MessagesInboxFilterButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1.6rem;
  text-transform: capitalize;
  background: none;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-left: 0.4rem;
    // color: var(--white);
    // font-size: 1.2rem;
    // font-weight: 700;
    // background: var(--nbs-red);
    // border-radius: 50%;
  }

  &.is-active {
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0.6rem;
      background-color: var(--nbs-blue);
      border-radius: 999px;
      content: '';
    }
  }
`;
