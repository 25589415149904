import styled from 'styled-components';

export const FooterTextStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  p {
    color: var(--white);
  }

  .footer-copyright-text {
    grid-column: 1 / 2;
  }

  .footer-address-text {
    grid-column: 3 / 4;
    text-align: right;
  }
`;
