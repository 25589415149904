import styled from 'styled-components';

export const CardActionMenuDropdownStyled = styled.div`
  display: none;
  position: absolute;
  width: 16rem;
  background: var(--white);
  box-shadow: 1.5px 3px 7px rgba(2, 63, 133, 0.1);
  border-radius: 0.8rem;
  z-index: 100;

  @media (min-width: 992px) {
    right: 2rem;
  }

  &.is-active {
    display: flex;
  }

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      padding: 0.8rem;
      font-size: 1.4rem;
      border-bottom: 0.1rem solid var(--nbs-blue-light);

      &:last-child {
        border: 0;
      }

      svg {
        width: 1.6rem;
        margin-right: 0.8rem;
      }

      a,
      button {
        display: flex;
        align-items: center;
        color: var(--nbs-navy-blue);
      }

      button {
        padding: 0;
        background: none;
      }
    }
  }
`;
