import { useGetAuth0Token } from './useGetAuth0Token';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuth0Config } from '../../config/auth0';
import { useState, useEffect } from 'react';

const useGetAuth0User = () => {
  const [userData, setUserData] = useState({
    accountStatus: null,
    companyIds: [],
    hsId: null,
  });
  const accessToken = useGetAuth0Token();
  const { user } = useAuth0();

  const auth0Config = getAuth0Config();

  const getUserDetails = async () => {
    if (!accessToken) {
      // Retry fetching the token if not available
      await accessToken;

      return;
    }
    try {
      const userDetails = await fetch(
        `https://${auth0Config?.domain}/api/v2/users/${user?.sub}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!userDetails.ok) {
        throw new Error(
          `Error fetching user details: ${userDetails.statusText}`
        );
      }

      const { user_metadata } = await userDetails.json();

      setUserData(user_metadata);
    } catch (error) {
      setUserData({ accountStatus: null, companyIds: [], hsId: null });
    }
  };

  useEffect(() => {
    if (!user) return;

    getUserDetails();
  }, [user, accessToken]);

  return userData;
};

export { useGetAuth0User };
