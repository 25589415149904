import styled from 'styled-components';

export const PaginationStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PaginationListStyled = styled.ul`
  display: flex;
  gap: 0.8rem;
  list-style: none;
`;

export const PaginationButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  color: var(--nbs-navy-blue);
  background: var(--white);
  border-radius: 0.8rem;

  &:hover,
  &.is-active {
    color: var(--white);
  }

  &:hover {
    background: var(--nbs-navy-blue);
  }

  &.is-active {
    background: var(--nbs-blue);
  }
`;
