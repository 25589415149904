import styled from 'styled-components';

export const LoginFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 42rem;
  margin: auto;
  padding: 3.2rem;
  box-shadow: 2px 4px 9px rgba(2, 63, 133, 0.1);
  background: var(--white);
  border-radius: 0.8rem;

  h1 {
    color: var(--nbs-navy-blue);
  }

  p {
    text-align: center;
  }

  button {
    width: 100%;
    margin-bottom: 1.2rem;
  }
`;
