import styled from 'styled-components';

export const CtaTitle = styled.h3`
  border-bottom: solid 0.4rem #304c85;
  font-size: 35px;
  font-size: 3.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 4rem;
`;

export const TelReveal = styled.button`
  display: inline-flex;
  color: #fff;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  min-width: 24rem;
  margin-bottom: 1.6rem;
  padding: 1.6rem 2.4rem;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.8;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #0a5ca9;
  border: solid 0.1rem #0a5ca9;
  border-radius: 999px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    color: var(--white);
    border-color: var(--white);
    box-shadow: 0.2rem 0.2rem 1rem rgb(10 92 169 / 42%);
    background-color: #524388;
  }

  a {
    color: var(--white);
  }
`;

export const CtaText = styled.p`
  color: var(--nbs-black);
`;

export const CtaNum = styled.a`
  font-size: 3.5rem;
  color: #1959d2;
  &:hover {
    color: #304c85;
    text-decoration: underline;
  }
`;
