import { Hero } from '../../../../components/hero';
import { MainLayout } from '../../../../components/layouts/mainLayout';

// Hooks
import { useState } from 'react';
import { useMatchMedia } from '../../../../hooks/useMatchMedia';
import { useGetDocuments } from '../../../../hooks/documents';

// Context
import { useUserContext } from '../../../../providers/user/useUserContext';

import SampleBGImage from '../../../../img/documents_hero_image.png';
import { Row } from '../../../../components/layout/row';
import { RefreshButton } from '../../../../components/buttons';
import { DocumentsList } from '../components/documentsList';
import { UploadButtonDesktop } from '../../../../components/buttons';

import toast from 'react-hot-toast';
import { Modal } from '../../../../components/modal';
import { DocumentsForm } from '../../../../components/forms/documents/documentsForm';

import { addDocuments } from '../api/addDocument';

import { DocumentData } from '../types';
import { InfoResources } from '../../../../components/infoResources';
import { InfoResourcesMobile } from '../../../../components/infoResourcesMobile';

const Documents = () => {
  const [modalActive, setModalActive] = useState(false);
  const { refetch } = useGetDocuments();
  const { handleCreateDocument } = addDocuments();
  const { hsId } = useUserContext();
  const isDeskTopResolution = useMatchMedia('(min-width: 992px)', true);

  const handleDocumentsUpload = async (values: DocumentData) => {
    const documentFormData = {
      attachment_documentsForm: values.attachment_documentsForm,
      hsId: hsId,
      document_reference: values.document_reference || '',
      contract_id: values.contract_id || '',
    };
    try {
      await handleCreateDocument(documentFormData);
      setModalActive(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleFetchDocuments = async () => {
    await refetch();
  };

  const loadDocuments = () => {
    toast.promise(handleFetchDocuments(), {
      loading: 'Loading documents ...',
      success: 'Documents updated',
      error: 'Error updating documents',
    });
  };

  return (
    <MainLayout>
      <Hero
        title='Documents'
        subTitle='Any other documents are conveniently gathered and securely stored in one place.'
        bgImage={SampleBGImage}
      >
        <Row className='hero-actions'>
          <UploadButtonDesktop
            modalFunction={setModalActive}
            label='Upload New Document'
          />
          <RefreshButton refreshFunction={loadDocuments} label='Refresh list' />
        </Row>

        <DocumentsList />
      </Hero>
      {isDeskTopResolution ? <InfoResources /> : <InfoResourcesMobile />}
      <Modal
        modalTitle='Upload new document'
        modalActiveState={modalActive}
        setModalActiveState={setModalActive}
        updater={handleFetchDocuments}
      >
        <DocumentsForm
          formSubmitHandler={handleDocumentsUpload}
          id='documentsForm'
        />
      </Modal>
    </MainLayout>
  );
};

export { Documents };
