/**
 * gen-services - apiHandler.js
 * API Handler - handles all the API requests to Firebase / HubSpot
 * HubSpot requests use Firebase Cloud Functions
 * Firebase requests use the Firebase SDK
 * @author Kris Bradbury
 */

// V2 API
import { documents } from './v2';
import { files } from './v2';
import { invoices } from './v2';
import { statements } from './v2';
import { forecasts } from './v2';
import { tickets } from './v2';
import { messages } from './v2';
import { posts } from './v2';
import { users } from './v2';
import { auth } from './v2';
import { companies } from './v2';
import { email } from './v2';
import { contracts } from './v2';
import { accounts } from './v2/';
import { access } from './v2';
import { app } from './v2';
import { search } from './v2';
import { customObjects } from './v2';

/**
 * Handles updated V2 API requests
 */
const api = (requestData) => {
  return {
    documents: {
      getDocuments: () => documents.getDocuments(requestData),
      createDocument: () => documents.createDocument(requestData),
      searchDocuments: () => documents.searchDocuments(requestData),
    },
    files: {
      uploadFile: () => files.uploadFile(requestData),
    },
    invoices: {
      getInvoices: () => invoices.getInvoices(requestData),
      getAllInvoices: () => invoices.getAllInvoices(requestData),
      getInvoice: () => invoices.getInvoice(requestData),
      createInvoice: () => invoices.createInvoice(requestData),
      searchInvoices: () => invoices.searchInvoices(requestData),
    },
    statements: {
      getStatements: () => statements.getStatements(requestData),
      getAllStatements: () => statements.getAllStatements(requestData),
      getStatement: () => statements.getStatement(requestData),
      getStatementInvoices: () => statements.getStatementInvoices(requestData),
      getStatementTickets: () => statements.getStatementTickets(requestData),
      searchStatements: () => statements.searchStatements(requestData),
    },
    forecasts: {
      getForecasts: () => forecasts.getForecasts(requestData),
      getAllForecasts: () => forecasts.getAllForecasts(requestData),
      getForecast: () => forecasts.getForecast(requestData),
      createForecast: () => forecasts.createForecast(requestData),
      searchForecasts: () => forecasts.searchForecasts(requestData),
    },
    tickets: {
      getTickets: () => tickets.getTickets(requestData),
      getSingleTicket: () => tickets.getSingleTicket(requestData),
      getSingleTicketEmails: () => tickets.getSingleTicketEmails(requestData),
      createTicket: () => tickets.createTicket(requestData),
      updateTicket: () => tickets.updateTicket(requestData),
      createTicketAssociation: () =>
        tickets.createTicketAssociation(requestData),
      searchTickets: () => tickets.searchTickets(requestData),
    },
    messages: {
      getMessages: () => messages.getMessages(requestData),
      updateMessageStatus: () => messages.updateMessageStatus(requestData),
    },
    posts: {
      getPosts: () => posts.getPosts(requestData),
    },
    users: {
      getHubspotUser: () => users.getHubspotUser(requestData),
      createHubspotUser: () => users.createHubspotUser(requestData),
      updateFirestoreUser: () => users.updateFirestoreUser(requestData),
      updateHubspotUser: () => users.updateHubspotUser(requestData),
      fetchFirebaseUser: () => users.fetchFirebaseUser(requestData),
      setFirestoreUser: () => users.setFirestoreUser(requestData),
      fetchEmailVerifyStatus: () => users.fetchEmailVerifyStatus(requestData),
      verifyFirebaseUser: () => users.verifyFirebaseUser(requestData),
      generateResetLink: () => users.generateResetLink(requestData),
    },
    auth: {
      createFirebaseEmailUser: () => auth.createFirebaseEmailUser(requestData),
      getHubspotUser: () => auth.getHubspotUser(requestData),
      getFirebaseUser: () => auth.getFirebaseUser(requestData),
      addUserToFirestore: () => auth.addUserToFirestore(requestData),
    },
    companies: {
      fetchCompanyMembers: () => companies.fetchCompanyMembers(requestData),
    },
    email: {
      createEmail: () => email.createEmail(requestData),
    },
    contracts: {
      getContracts: () => contracts.getContracts(requestData),
    },
    accounts: {
      fetchUsersAccounts: () => accounts.fetchUsersAccounts(requestData),
    },
    access: {
      verifyContactAccess: () => access.verifyContactAccess(requestData),
    },
    app: {
      getAppSettings: () => app.getAppSettings(requestData),
    },
    search: {
      searchObjects: () => search.searchObjects(requestData),
    },
    customObjects: {
      updateCustomObject: () => customObjects.updateCustomObject(requestData),
    },
  };
};

export { api };
