import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

const useGetAllForecasts = () => {
  const { hsId } = useUserContext();

  const {
    data: forecastsData,
    isLoading: isForecastsLoading,
    error: isForecastsError,
    refetch,
    isFetching: isFetchingForecasts,
  } = useQuery(
    ['all-forecasts'],
    async () => {
      if (hsId) {
        return await api({
          hsId: hsId,
        }).forecasts.getAllForecasts();
      } else {
        return [];
      }
    },
    { enabled: !!hsId }
  );

  return {
    forecastsData,
    isForecastsLoading,
    isForecastsError,
    refetch,
    isFetchingForecasts,
  };
};

export { useGetAllForecasts };
