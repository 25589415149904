import {
  PIPELINE_STAGE_IDS_DEV,
  PIPELINE_STAGE_IDS_PROD,
  PIPELINE_STAGE_IDS_STAGING,
} from '../../constants/pipelineIds';

const getPipelineStageIds = () => {
  if (process.env.REACT_APP_MODE === 'local') {
    return PIPELINE_STAGE_IDS_DEV;
  } else if (process.env.REACT_APP_MODE === 'staging') {
    return PIPELINE_STAGE_IDS_STAGING;
  } else if (process.env.REACT_APP_MODE === 'production') {
    return PIPELINE_STAGE_IDS_PROD;
  } else {
    return null;
  }
};

export { getPipelineStageIds };
