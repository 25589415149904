import { MainLayout } from '../../../../components/layouts/mainLayout';
import {
  SingleItemContent,
  SingleItemError,
  SingleItemLoader,
} from '../../../../components/singleItem';

import { QueriesSingleContent } from '../components/queriesSingleContent';
import { QueriesSingleEmails } from '../components/queriesSingleEmails';
import { QueriesSingleHeader } from '../components/queriesSingleHeader';

// Hooks
import { useGetTicket } from '../../../../hooks/tickets/useGetTicket';
import { QueriesSingleInfoWidget } from '../components/queriesSingleInfoWidget';
import { Container } from '../../../../components/container';
import { InfoResources } from '../../../../components/infoResources';

const QueriesSingle = () => {
  const { singleTicketData, singleTicketError, singleTicketLoading } =
    useGetTicket();

  if (singleTicketLoading) {
    return (
      <MainLayout>
        <SingleItemLoader label='Loading query' />
      </MainLayout>
    );
  }

  if (singleTicketError) {
    return (
      <MainLayout>
        <SingleItemError errorMessage='Error loading query, please refresh your browser' />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <QueriesSingleHeader ticket={singleTicketData.results} />
      <SingleItemContent>
        <Container>
          <QueriesSingleContent ticket={singleTicketData.results} />
          <QueriesSingleInfoWidget ticket={singleTicketData.results} />
          <QueriesSingleEmails />
        </Container>
      </SingleItemContent>
      <InfoResources />
    </MainLayout>
  );
};

export { QueriesSingle };
