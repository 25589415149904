import { MainLayout } from '../../../../components/layouts/mainLayout';
import { StatementSingleContent } from '../components/statementSingleContent';
import { StatementSingleHeader } from '../components/statementSingleHeader';
import {
  SingleItemLoader,
  SingleItemError,
} from '../../../../components/singleItem';

// Hooks
import { useGetSingleStatement } from '../../../../hooks/statements/useGetSingleStatement';
import { StatementSingleInvoices } from '../components/statementSingleInvoices';
import { StatementSingleTickets } from '../components/statementSingleTickets';
import { InfoResources } from '../../../../components/infoResources';

const StatementSingle = () => {
  const { data, isLoading, error } = useGetSingleStatement();

  if (isLoading) {
    return (
      <MainLayout>
        <SingleItemLoader label='Loading statement' />
        <InfoResources />
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <SingleItemError errorMessage='Error loading statement, please refresh your browser' />
        <InfoResources />
      </MainLayout>
    );
  }

  if (data.results.pipelineStageLabel === 'Prepared') {
    return (
      <MainLayout>
        <SingleItemError errorMessage='Statement is not available yet, please check back later' />
        <InfoResources />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <StatementSingleHeader
        reference={data.results.reference}
        status={data.results.pipelineStageLabel}
      />
      <StatementSingleContent statementData={data.results} />
      <StatementSingleInvoices statementData={data.results} />
      <StatementSingleTickets statementData={data.results} />
      <InfoResources />
    </MainLayout>
  );
};

export { StatementSingle };
