import styled from 'styled-components';

export const NotificationsDropdownListItemStyled = styled.li`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 2rem 0;
  border-bottom: solid 0.1rem var(--nbs-ui-grey);

  div {
    display: flex;
    flex-direction: column;
    h5,
    span {
      color: var(--nbs-black);
    }

    h5 {
      margin: 0;
      font-size: 1.4rem;
      font-weight: 700;
    }

    span {
      font-size: 1.2rem;
      font-weight: 400;
    }
  }

  button {
    margin-left: auto;
    padding: 0;
    background: none;
  }
`;
