import { appHelpers } from '../../../helpers';
import axios from 'axios';

const getAppSettings = async () => {
  const CONFIG = {
    method: 'GET',
    url: `${appHelpers.getApiUrl()}/api/v2/app/getAppSettings`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    throw new Error(`Error fetching settings`);
  }
};

export { getAppSettings };
