// Components
import {
  Card,
  CardCol,
  CardColLabel,
  CardColDate,
  CardColTotal,
  CardColDownload,
  CardColContent,
  CardColStatus,
  CardActionMenuToggle,
  CardActionMenuDropdown,
} from '../../../../components/card';
import { StatementsCardStyled } from '../styles/statementsListItem.styles';
import { Link } from 'react-router-dom';
import {
  QuestionMarkCircle,
  Information,
  PlusCircle,
} from '../../../../components/svg';

// Hooks
import { useState } from 'react';

// Types
import { StatementsItemProps } from '../types';

const accountId = null;

const StatementsListItem = ({
  statement,
  setModalActive,
  setModalType,
  setActiveItem,
}: StatementsItemProps) => {
  const [dropdownState, setDropdownState] = useState(false);

  return (
    <>
      <Card className={`pod-card-wrapper pod-card-statements`}>
        <StatementsCardStyled>
          <CardCol className='statement__date'>
            <CardColLabel label='Date' />
            {statement.releaseDate ? (
              <CardColDate date={statement.releaseDate} />
            ) : (
              <CardColDate date={statement.createdDate} />
            )}
          </CardCol>
          <CardCol>
            <CardColLabel label='Statement Reference' />
            <CardColContent content={statement.statementNumber} />
          </CardCol>
          <CardCol>
            <CardColLabel label='Type' />
            <CardColContent content={statement.statementType} />
          </CardCol>
          <CardCol className='statement__total'>
            <CardColLabel label='Total (GBP)' />
            {statement.total ? <CardColTotal total={statement.total} /> : <></>}
          </CardCol>
          <CardCol className='statement__status'>
            <CardColStatus status={statement.pipelineStage} />
          </CardCol>
          <CardCol className='statement__download'>
            {statement.attachment ? (
              <CardColDownload url={statement.attachment} />
            ) : null}
          </CardCol>

          <CardActionMenuToggle
            dropdownState={dropdownState}
            setDropdownState={setDropdownState}
          />
        </StatementsCardStyled>
        <CardActionMenuDropdown dropdownState={dropdownState}>
          <ul>
            <li>
              <Link
                to={
                  accountId
                    ? `/app/${accountId}/statements/${statement.id}`
                    : `/app/statements/${statement.id}`
                }
              >
                <Information /> View statement
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  setModalActive(true);
                  setModalType('add-invoice');
                  setActiveItem(statement);
                  setDropdownState(false);
                }}
              >
                <PlusCircle /> Submit invoice
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setModalActive(true);
                  setModalType('add-query');
                  setActiveItem(statement);
                  setDropdownState(false);
                }}
              >
                <QuestionMarkCircle /> Query Statement
              </button>
            </li>
          </ul>
        </CardActionMenuDropdown>
      </Card>
    </>
  );
};

export { StatementsListItem };
