import { ids } from '../../../../helpers';
import {
  CreateDocRequestData,
  CreateDocAssociationRequestData,
} from '../types';

const generateDocumentAssociations = (
  docAssociationData: CreateDocRequestData
) => {
  let associations: CreateDocAssociationRequestData = [];

  const associationIds = ids.getAssociationIds();

  if (docAssociationData.companyId) {
    associations = [
      {
        to: { id: docAssociationData.companyId },
        types: [
          {
            associationCategory: 'USER_DEFINED',
            associationTypeId: associationIds
              ? associationIds.gen_services_document_to_company
              : null,
          },
        ],
      },
    ];
  } else {
    associations = [
      {
        to: { id: docAssociationData.hsId },
        types: [
          {
            associationCategory: 'USER_DEFINED',
            associationTypeId: associationIds
              ? associationIds.gen_services_document_to_contact
              : null,
          },
        ],
      },
    ];
  }

  if (docAssociationData.contractId) {
    associations.push({
      to: { id: docAssociationData.contractId },
      types: [
        {
          associationCategory: 'USER_DEFINED',
          associationTypeId: associationIds
            ? associationIds.gen_services_document_to_contract
            : null,
        },
      ],
    });
  }

  return associations;
};

export { generateDocumentAssociations };
