import styled from 'styled-components';

export const CardColDropdownContentStyled = styled.div`
  display: none;
  padding: 2rem;
  color: var(--nbs-navy-blue);
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0.8rem;

  &.is-active {
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;
  }

  header {
    display: flex;
    flex-direction: column;
  }
`;
