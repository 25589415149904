import { useUserContext } from '../../providers/user/useUserContext';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';

const useGetHubspotUser = () => {
  const { hsId, user } = useUserContext();

  const fetchHubspotUser = async () => {
    return await api({
      email: user?.email,
    }).users.getHubspotUser();
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['user'],
    queryFn: fetchHubspotUser,
    enabled: !!hsId,
  });
  return { hsUserData: data, isLoadingUser: isLoading, userError: error };
};

export { useGetHubspotUser };
