import { CardColTotalStyled } from './styles/cardColTotal.styles';
import { formatters } from '../../helpers';

type CardColTotalProps = {
  className?: string;
  total: string | number;
};

const CardColTotal = ({ className, total }: CardColTotalProps) => {
  return (
    <CardColTotalStyled className={className}>
      <>{formatters.formatCurrency(total.toString())}</>
    </CardColTotalStyled>
  );
};

export default CardColTotal;
