const WarningIcon = () => {
  return (
    <svg
      width='27'
      height='24'
      viewBox='0 0 27 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_499_8755)'>
        <path
          d='M25.1444 23.9963H1.8529C0.200011 23.9963 -0.471213 22.83 0.351037 21.4037L12.3072 1.07396C12.7183 0.360783 13.2637 0 13.8091 0C14.3545 0 14.8998 0.360783 15.311 1.07396L26.6463 21.4037C27.4685 22.83 26.7973 23.9963 25.1444 23.9963Z'
          fill='#D50032'
        />
        <g clipPath='url(#clip1_499_8755)'>
          <path
            d='M14.0957 15.2623C14.6662 15.2623 15.1361 14.8008 15.1361 14.2219L15.564 9.81698C15.5472 9.25483 15.3039 8.39062 14.1124 8.39062C12.963 8.39062 12.6777 9.25483 12.6693 9.81698L13.0553 14.2219C13.0553 14.7924 13.5167 15.2623 14.0957 15.2623Z'
            fill='white'
          />
          <path
            d='M14.0873 16.7637C13.2567 16.7637 12.5854 17.4349 12.5854 18.2655C12.5854 19.0962 13.2567 19.7674 14.0873 19.7674C14.918 19.7674 15.5892 19.0962 15.5892 18.2655C15.5892 17.4349 14.918 16.7637 14.0873 16.7637Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_499_8755'>
          <rect width='26.9973' height='23.9963' fill='white' />
        </clipPath>
        <clipPath id='clip1_499_8755'>
          <rect
            width='3.00373'
            height='11.3689'
            fill='white'
            transform='translate(12.5854 8.39062)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningIcon;
