export default function MenuTicketsBlueMobile() {
  return (
    <svg
      width='25'
      height='29'
      viewBox='0 0 25 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25 17.6556C25 18.0367 24.6927 18.34 24.3067 18.34H23.6369V19.0011C23.6369 20.0122 22.7781 20.8289 21.7302 20.8289C20.6823 20.8289 19.8156 20.0122 19.8156 19.0011V13.0744C19.8156 12.0633 20.6666 11.2467 21.7302 11.2467H21.7933V10.8422C21.7933 8.30667 20.7532 6.02 19.075 4.35556C17.3889 2.69111 15.0725 1.67222 12.5039 1.67222C9.93539 1.67222 7.61897 2.69889 5.93287 4.35556C4.24677 6.02 3.21462 8.30667 3.21462 10.8422V11.2544H3.26978C4.32556 11.2544 5.17649 12.0711 5.17649 13.0744V19.0011C5.17649 20.0122 4.31768 20.8289 3.26978 20.8289C2.22187 20.8289 1.36306 20.0122 1.36306 19.0011V18.34H0.69335C0.315159 18.34 0 18.0367 0 17.6556V14.4044C0 14.0233 0.30728 13.72 0.69335 13.72H1.36306V13.0667C1.36306 12.81 1.41822 12.5611 1.52064 12.3356V10.8344C1.52064 4.85333 6.43713 0 12.5039 0C18.5708 0 23.4794 4.85333 23.4794 10.8422V12.3433C23.5818 12.5689 23.6369 12.81 23.6369 13.0744V13.7278H24.3067C24.6848 13.7278 25 14.0311 25 14.4122V17.6633V17.6556ZM12.7797 27.3778C12.567 27.8911 11.976 28.14 11.456 27.93L9.58084 27.1756C9.20265 27.0278 8.97416 26.6778 8.95052 26.3044L3.09644 24.1422C2.90734 24.0722 2.7734 23.8856 2.7734 23.6833V20.8211H3.75039V20.8756V23.3489L9.37598 25.4256C9.64387 25.2389 10.0063 25.1844 10.3372 25.3167L12.2124 26.0711C12.7324 26.2811 12.9846 26.8644 12.7718 27.3778'
        fill='#033F85'
      />
    </svg>
  );
}
