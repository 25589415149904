import { useState, useEffect } from 'react';

// Types
import { TicketItem } from '../../../types/tickets';

// Components
import {
  Card,
  CardCol,
  CardColLabel,
  CardColContent,
  CardColDate,
  CardColStatus,
  CardColDropdownToggle,
} from '../../card';

import TicketListItemDropdown from './ticketListItemDropdown';

type TicketItemProps = {
  ticket: TicketItem;
};

const TicketListItem = ({ ticket }: TicketItemProps) => {
  const [dropdownState, setDropdownState] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const queryId = params.get('queryId');

    if (ticket.id === queryId) {
      setDropdownState(true);
    }

    return () => {};
  }, []);

  return (
    <>
      <Card className='pod-card-tickets' cols='5' data-testid='ticket-card'>
        <div
          className='pod-card pod-card--tickets ticket'
          data-testid='ticket-list-item'
          onClick={() => setDropdownState(!dropdownState)}
        >
          <CardCol className='ticket__date'>
            {ticket.closedDate ? (
              <>
                <CardColLabel label='Date closed' />
                <CardColDate date={ticket.closedDate} />
              </>
            ) : (
              <>
                <CardColLabel label='Date opened' />
                <CardColDate date={ticket.createdAt} />
              </>
            )}
          </CardCol>
          <CardCol>
            <CardColLabel
              className='ticket__date-label'
              label='Query subject'
            />
            <CardColContent
              className='ticket__subject'
              content={ticket.subject}
            />
          </CardCol>
          <CardCol>
            {ticket.source ? (
              <>
                <CardColLabel label='Query source' />
                <CardColContent content={ticket.source} />
              </>
            ) : null}
          </CardCol>
          <CardCol className='ticket__submitted-by'>
            {ticket.submittedBy ? (
              <>
                <CardColLabel label='Submitted by' />
                <CardColContent content={ticket.submittedBy} />
              </>
            ) : null}
          </CardCol>
          {ticket.pipelineStage ? (
            <CardColStatus
              className='ticket__status full_width_badge'
              status={ticket.pipelineStage}
            />
          ) : null}
          <CardColDropdownToggle
            dropdownState={dropdownState}
            setDropdownState={setDropdownState}
          />
        </div>
      </Card>
      <TicketListItemDropdown dropdownState={dropdownState} ticket={ticket} />
    </>
  );
};

export default TicketListItem;
