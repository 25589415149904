import { useState } from 'react';
import { MigrateUserFormWrapperStyled } from '../styles/migrateUserForm.styles';
import { FieldValues, useForm } from 'react-hook-form';
import { Loader } from '../../../components/loader';

import { api } from '../../../api/apiHandler';

const MigrateUserForm = () => {
  const [formStatus, setFormStatus] = useState('start');
  const [emailLink, setEmailLink] = useState<string | undefined | null>(null);

  const { handleSubmit, register } = useForm();

  const handleGenerateEmailLink = async (email: string) => {
    const { resetLink } = await api(email).users.generateResetLink();

    return resetLink;
  };

  const handleForm = async (data: FieldValues) => {
    setFormStatus('verifying');
    const { user } = await api(data.emailAddress).users.verifyFirebaseUser();

    if (user) {
      setFormStatus('verified');
      const resetLink = await handleGenerateEmailLink(data.emailAddress);
      if (resetLink) {
        setFormStatus('complete');
        setEmailLink(resetLink);
      }
    } else {
      setFormStatus('error');
    }
  };

  return (
    <MigrateUserFormWrapperStyled>
      <h2>Request a new password reset link</h2>
      {formStatus === 'start' && (
        <>
          <p>
            If your link has exceeded the 5 day expiry. Please enter your email
            below. You will be sent an email that will contain a link to reset
            your password and to set up multifactor authentication.
          </p>
          <form onSubmit={handleSubmit(handleForm)}>
            <div className='form-group'>
              <label htmlFor='emailAddress'>Email Address</label>
              <input
                {...register('emailAddress')}
                className='form-control'
                type='email'
              />
            </div>
            <div className='form-group'>
              <input
                type='submit'
                className='button button-primary--blue button--full'
                value='submit'
              />
            </div>
          </form>
        </>
      )}
      {formStatus === 'verifying' && (
        <>
          <p>Please wait we are just verifying your account</p>
          <Loader />
        </>
      )}
      {formStatus === 'verified' && (
        <>
          <p>Please wait whilst we generate your reset link</p>
          <Loader />
        </>
      )}
      {formStatus === 'complete' && (
        <>
          <p>
            Please click on the following link to reset your password. This has
            also been emailed to you:
          </p>
          {emailLink ? (
            <a href={emailLink}>{emailLink}</a>
          ) : (
            <>Something went wrong, please try again</>
          )}
        </>
      )}
      {formStatus === 'error' && (
        <>
          <p>Something went wrong, please try again</p>
          <button
            className='button button-primary button-primary--blue'
            onClick={() => setFormStatus('start')}
          >
            Start Again
          </button>
        </>
      )}
    </MigrateUserFormWrapperStyled>
  );
};

export { MigrateUserForm };
