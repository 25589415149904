import { useGetStatements } from '../../../hooks/statements';
import { useMessagesQuery } from '../../../hooks/messages';
import { useGetTickets } from '../../../hooks/tickets';
import { useGetForecasts } from '../../../hooks/forecasts';
import { DashboardWidget } from './dashboardWidget';
import { DashboardWidgetsStyled } from '../styles/dashboardWidgets.styles';

const DashboardWidgets = () => {
  const { messagesData, messagesLoading } = useMessagesQuery();
  const { statementsData, isStatementsLoading } = useGetStatements();
  const { ticketsData, isTicketsLoading } = useGetTickets();
  const { forecastsData, isForecastsLoading } = useGetForecasts();

  return (
    <DashboardWidgetsStyled>
      <DashboardWidget
        widgetContent={messagesData}
        widgetLoading={messagesLoading}
        path='/app/messages'
        title='Messages'
        gradient='red-purple'
        icon='messages'
      />
      <DashboardWidget
        widgetContent={ticketsData}
        widgetLoading={isTicketsLoading}
        path='/app/queries'
        title='Queries'
        gradient='pink-purple'
        icon='tickets'
      />
      <DashboardWidget
        widgetContent={forecastsData}
        widgetLoading={isForecastsLoading}
        path='/app/forecasts'
        title='Forecasts'
        gradient='purple'
        icon='forecasts'
      />
      <DashboardWidget
        widgetContent={statementsData}
        widgetLoading={isStatementsLoading}
        path='/app/statements'
        title='Statements'
        gradient='purple-blue'
        icon='statements'
      />
    </DashboardWidgetsStyled>
  );
};

export { DashboardWidgets };
