import { useRoutes, Navigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { PublicRoutes } from './publicRoutes';
import { PrivateRoutes } from './privateRoutes';

import { AppRoute } from './appRoute';
import { Loader } from '../components/global';

const RedirectQuery = () => {
  const { id } = useParams();
  return (
    <>
      <Navigate to={`/app/queries/${id}`} replace />
    </>
  );
};

const RedirectForcast = () => {
  const { id } = useParams();
  return (
    <>
      <Navigate to={`/app/forecasts/${id}`} replace />
    </>
  );
};

const RedirectStatement = () => {
  const { id } = useParams();
  return (
    <>
      <Navigate to={`/app/statements/${id}`} replace />
    </>
  );
};

const RedirectInvoice = () => {
  const { id } = useParams();
  return (
    <>
      <Navigate to={`/app/invoices/${id}`} replace />
    </>
  );
};

const AppRoutes = () => {
  const { isLoading } = useAuth0();
  const publicRoutes = [
    {
      path: '/',
      children: [
        ...PublicRoutes,
        {
          path: '/app/',
          element: <Navigate to='/' replace />,
        },
        {
          path: '/messages',
          element: <Navigate to='/app/queries' replace />,
        },
        {
          path: '/queries',
          element: <Navigate to='/app/queries' replace />,
        },
        {
          path: '/queries/:id',
          element: <RedirectQuery />,
        },
        {
          path: '/query/:id',
          element: <RedirectQuery />,
        },
        {
          path: '/forecasts',
          element: <Navigate to='/app/forecasts' replace />,
        },
        {
          path: '/forecasts/:id',
          element: <RedirectForcast />,
        },
        {
          path: '/statements',
          element: <Navigate to='/app/statements' replace />,
        },
        {
          path: '/statements/:id',
          element: <RedirectStatement />,
        },
        {
          path: '/invoices',
          element: <Navigate to='/app/invoices' replace />,
        },
        {
          path: '/invoices/:id',
          element: <RedirectInvoice />,
        },
        {
          path: '/documents',
          element: <Navigate to='/app/documents' replace />,
        },
        {
          path: '/dashboard',
          element: <Navigate to='/app/dashboard' replace />,
        },
        {
          path: '/my-account',
          element: <Navigate to='/app/my-account' replace />,
        },
        {
          path: '/login',
          element: <Navigate to='/' replace />,
        },
        {
          path: '/register',
          element: <Navigate to='/' replace />,
        },
      ],
    },
  ];
  const appRoutes = [
    {
      path: '/app',
      element: <AppRoute />,
      children: PrivateRoutes,
    },
  ];

  const element = useRoutes([...publicRoutes, ...appRoutes]);

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return <>{element}</>;
};

export { AppRoutes };
