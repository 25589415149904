import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

const useGetContracts = () => {
  const { hsId } = useUserContext();

  const handleGetContracts = async () => {
    return await api({
      hsId: hsId,
    }).contracts.getContracts();
  };

  const {
    data: contractsData,
    isLoading: isContractsLoading,
    error: isContractsError,
  } = useQuery({
    queryKey: ['contracts'],
    queryFn: handleGetContracts,
    enabled: !!hsId,
  });

  return {
    contractsData,
    isContractsLoading,
    isContractsError,
  };
};

export { useGetContracts };
