import { SingleItemProps } from './types';
import { SingleItemTicketsWrapperStyled } from './styles/singleItemTickets.styles';

const SingleItemTickets = ({ children }: SingleItemProps) => {
  return (
    <SingleItemTicketsWrapperStyled>{children}</SingleItemTicketsWrapperStyled>
  );
};

export default SingleItemTickets;
