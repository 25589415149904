import styled from 'styled-components';

export const DashboardPageStyled = styled.section`
  max-width: 128rem;
  width: 100%;
  margin: auto;
  padding 4rem 4rem 10rem;

  @media (min-width: 992px) {
    display: grid;
    grid-gap: 3.2rem;
    padding 4rem;
    grid-template-columns: 40% 1fr;
    padding: 6rem 1.6rem 0 1.6rem;
  }

  h1 {
    font-size: 4.8rem;
    font-weight: bold;
  }

  h1,
  p {
    color: var(--nbs-blue);
  }

  p {
    font-size: 1.6rem;
    @media (min-width: 992px) {
      font-size: 1.8rem;
    }
  }
`;

export const DashboardContentStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: sticky;
  top: 2rem;
  align-self: flex-start;
  h1 {
    margin-top: 20px;
    font-size: 2.4rem;
  }
  @media (min-width: 992px) {
    grid-template-columns: 2fr 24rem;
    h1 {
      margin-top: 0;
      font-size: 4.8rem;
    }
  }
`;

export const DashboardLogoStyled = styled.figure`
  display: flex;
  justify-content: flex-end;
  // width: 20rem;
  // height: 12rem;
  margin-left: auto;

  img {
    object-fit: contain;
    object-position: center;
  }
`;

export const DashboardVideoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1;
  width: 100%;
  padding: 2rem;
  margin-top: 3.2rem;
  background: #f5f5f5;
`;

export const DashboardHomepageMessageStyled = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 2.4rem;
    color: var(--nbs-blue);
    margin-bottom: 1.6rem;
  }
  p {
    margin-top: 0rem;
    font-size: 1.6rem;
  }
`;

export const DashboardHomepageMessageButtonStyled = styled.a`
  display: inline-flex;
  color: #fff;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  padding: 1rem 1.5rem;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.8;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #0a5ca9;
  border: solid 0.1rem #0a5ca9;
  border-radius: 999px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    color: var(--white);
    border-color: var(--white);
    box-shadow: 0.2rem 0.2rem 1rem rgb(10 92 169 / 42%);
    background-color: #524388;
  }
`;
