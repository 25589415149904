export default function ForecastsBlue() {
  return (
    <svg
      width='22'
      height='21'
      viewBox='0 0 22 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 8.29622H4V20.731H0V8.29622ZM6 5.42665H10V20.731H6V5.42665ZM12 0.644043H16V20.731H12V0.644043ZM18 7.33969H22V20.731H18V7.33969Z'
        fill='#023F85'
      />
    </svg>
  );
}
