import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { useRednerStatusBadge } from './hooks/useRenderStatusBadge';
export default function StatusBadge({ statusType }) {
  const { badgeStatus, handleBadgeStatus } = useRednerStatusBadge();

  useEffect(() => {
    handleBadgeStatus(statusType);

    return () => {};
  }, [statusType]);
  return (
    <>
      <span className={`badge badge--${badgeStatus}`}>
        {/* If status type equals 'Ticket' change to 'Query' */}
        {statusType === 'Ticket' ? 'Query' : statusType}
      </span>
    </>
  );
}

StatusBadge.propTypes = {
  statusType: PropTypes.string,
};
