import { Container } from '../../../components/container';
import { Wrapper } from '../../../components/layout';
import { MainLayout } from '../../../components/layouts/mainLayout';
import { MyAccountForm } from '../components/myAccountForm';

const MyAccount = () => {
  return (
    <MainLayout>
      <Wrapper gradient>
        <Container>
          <MyAccountForm />
        </Container>
      </Wrapper>
    </MainLayout>
  );
};

export { MyAccount };
