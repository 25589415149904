import styled from 'styled-components';

export const SearchFormWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
  @media (min-width: 992px) {
    justify-content: flex-end;
    margin-top: 0;
  }

  form {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;

    @media (min-width: 992px) {
      max-width: 52rem;
    }

    .form-control {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0 2rem 0 0;
      background: var(--white);

      svg {
        width: 2rem;
        margin-left: 2rem;
        height: 100%;
      }
      input {
        border: none;
      }
    }
  }
`;
