import styled from 'styled-components';

export const CardActionMenuToggleStyled = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0;
    background: none;
    border-radius: 0.4rem;

    svg {
      width: 2.8rem;
      stroke: var(--nbs-navy-blue);
    }

    &.is-active {
      background: var(--nbs-navy-blue);

      svg {
        stroke: var(--white);
      }
    }
  }
`;
