import PropTypes from 'prop-types';
import SuccessIcon from '../../svg/successIcon';
import { FormSuccessStyled } from './styles/formSuccess.styles';
export default function FormSuccess({ title, message, refreshMessage }) {
  return (
    <FormSuccessStyled>
      <SuccessIcon />
      <h3>{title}</h3>
      <p>{message}</p>
      {refreshMessage ? <p>{refreshMessage}</p> : null}
    </FormSuccessStyled>
  );
}

FormSuccess.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  refreshMessage: PropTypes.string,
};

FormSuccess.defaultProps = {
  title: 'All done!',
  message: 'Sent.',
};
