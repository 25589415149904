import { appHelpers } from '../../../helpers';
import { LOA_PROPERTIES } from '../../../constants/loaProperties';
import { ids } from '../../../helpers';

import axios from 'axios';

const fetchUsersAccounts = async (hsId) => {
  const BODY = {
    objectType: ids.getObjectIds().loas,
    properties: LOA_PROPERTIES,
    associationData: {
      objectFromType: ids.getObjectIds().contacts,
      recordId: hsId,
    },
  };

  const CONFIG = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/accounts/fetchUsersAccounts`,
    data: BODY,
  };

  try {
    return await axios(CONFIG);
  } catch (err) {
    console.error(err);
    throw new Error(err.message);
  }
};

export { fetchUsersAccounts };
