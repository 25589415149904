import styled from 'styled-components';

export const SkeletonEmailsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 21rem;
  margin-bottom: 1.2rem;
  padding: 1.6rem 2rem;
  background: var(--white);
  border-radius: 0.4rem;

  box-shadow: 1.5px 3px 7px rgba(2, 63, 133, 0.1);
  overflow: hidden;

  --glare-bg: linear-gradient(
    90deg,
    transparent,
    hsla(0, 0%, 100%, 0.4),
    transparent
  );

  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--glare-bg);
    transform: translateX(-100%);
    animation: skeleton-glare 1.75s infinite ease-out;
    z-index: 1;
  }

  @keyframes skeleton-glare {
    to {
      transform: translateX(100%);
    }
  }

  .skeleton-emails__header,
  .skeleton-emails__Id,
  .skeleton-emails__content {
    background: var(--nbs-blue-light);
    border-radius: 0.8rem;
  }

  .skeleton-emails__header {
    width: 100%;
    max-width: 32rem;
    height: 2.2rem;
    margin: 1.6rem 0 2rem 0;
  }

  .skeleton-emails__Id {
    width: 100%;
    max-width: 12rem;
    height: 1.8rem;
    margin-bottom: 2.8rem;
  }

  .skeleton-emails__content {
    width: 100%;
    max-width: 36rem;
    height: 1.6rem;
    margin-bottom: 2.8rem;
  }
`;
