import { Container } from '../../../../components/layout';
import {
  SingleItemContent,
  SingleItemAttachment,
} from '../../../../components/singleItem';
import { formatters } from '../../../../helpers';

type SingleInvoiceContentProps = {
  date: string;
  invoiceNumber: string;
  total: string;
  attachmentUrl: string;
};

const SingleInvoiceContent = ({
  date,
  invoiceNumber,
  total,
  attachmentUrl,
}: SingleInvoiceContentProps) => {
  return (
    <SingleItemContent>
      <Container>
        <ul>
          <li>
            <strong>Invoice Date:</strong>{' '}
            {formatters.formatDate(date, 'DD/MM/YYYY')}
          </li>
          {invoiceNumber ? (
            <li>
              <strong>Invoice Number:</strong> {invoiceNumber}
            </li>
          ) : null}
          <li>
            <strong>Invoice Net Total:</strong>{' '}
            {formatters.formatCurrency(total)}
          </li>
        </ul>
        {attachmentUrl ? (
          <>
            <SingleItemAttachment attachmentUrl={attachmentUrl} />
          </>
        ) : null}
      </Container>
    </SingleItemContent>
  );
};

export { SingleInvoiceContent };
