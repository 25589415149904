import PropTypes from 'prop-types';
import { EmptyStateStyled } from './styles/emptyState.styles';
import { WarningIcon } from '../svg';
import { svg } from '../';

export default function EmptyState({
  icon,
  label,
  text,
  link,
  linkText,
  linkExternal,
}) {
  const handleIcon = (icon) => {
    switch (icon) {
      case 'cog':
        return <svg.SettingsBlue />;
      case 'warning':
        return <WarningIcon />;
      case 'none':
        return null;
      default:
        return <WarningIcon />;
    }
  };

  return (
    <EmptyStateStyled>
      {handleIcon(icon)}
      <h2>{label}</h2>
      <p>{text}</p>
      {link && (
        <a
          href={link}
          target={linkExternal ? '_blank' : '_self'}
          rel={linkExternal ? 'noreferrer' : null}
        >
          {linkText ? <>{linkText}</> : <>{link}</>}
        </a>
      )}
    </EmptyStateStyled>
  );
}

EmptyState.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkExternal: PropTypes.bool,
};

EmptyState.defaultProps = {
  label: 'None found.',
  text: 'Please add some items to see them here.',
};
