import {
  ASSOCIATION_IDS_DEV,
  ASSOCIATION_IDS_PROD,
  ASSOCIATION_IDS_STAGING,
} from '../../constants/associationIds';

const getAssociationIds = () => {
  if (process.env.REACT_APP_MODE === 'local') {
    return ASSOCIATION_IDS_DEV;
  } else if (process.env.REACT_APP_MODE === 'staging') {
    return ASSOCIATION_IDS_STAGING;
  } else if (process.env.REACT_APP_MODE === 'production') {
    return ASSOCIATION_IDS_PROD;
  } else {
    return null;
  }
};

export { getAssociationIds };
