import PropTypes from 'prop-types';
import { Modal } from './styles/modalPopup.styles';

export default function ModalPopup({ children, modalActiveState }) {
  return (
    <>
      <Modal
        modalActiveState={modalActiveState}
        className={`modal-popup ${modalActiveState ? 'is-active' : ''}`}
      >
        {children}
      </Modal>
    </>
  );
}

ModalPopup.propTypes = {
  children: PropTypes.node.isRequired,
  modalActiveState: PropTypes.bool,
};
