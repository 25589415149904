import { debounce } from 'lodash';
import { MessagesInboxSorterDropdownWrapperStyled } from '../../style/messagesInboxSorter.styles';
import { useMessagesContext } from '../../../../../providers/messages/useMessagesContext';
import { ChangeEvent } from 'react';

const MessagesInboxSorterDropdown = () => {
  const { setSort } = useMessagesContext();

  const debouncedSetSort = debounce((sortOption) => {
    setSort(sortOption);
  }, 500);

  const handleSortMessages = (e: ChangeEvent<HTMLSelectElement>) => {
    const sortOption = e.target.value;
    debouncedSetSort(sortOption);
  };

  return (
    <MessagesInboxSorterDropdownWrapperStyled>
      <select name='sort' id='sort' onChange={(e) => handleSortMessages(e)}>
        <option value=''>--</option>
        <option value='date-newest'>Date (newest first)</option>
        <option value='date-oldest'>Date (oldest first)</option>
        <option value='type-asc'>Type (A - Z)</option>
        <option value='type-desc'>Type (Z - A)</option>
      </select>
    </MessagesInboxSorterDropdownWrapperStyled>
  );
};

export { MessagesInboxSorterDropdown };
