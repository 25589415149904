import { useState } from 'react';
import { SingleInvoiceTicketsProps } from '../types';

import {
  SingleItemTickets,
  SingleItemTicketsForm,
  SingleItemLoader,
  SingleItemError,
} from '../../../../components/singleItem';

// import { TicketForm } from '../../../../components/forms/tickets';
import { TicketsList } from '../../../../components/tickets/ticketsList';
import { layout } from '../../../../components';
import { AddQuery } from '../../../../components/forms/queries/addQuery';
import { useGetInvoiceTickets } from '../../../../hooks/invoices';

import toast from 'react-hot-toast';
import { RefreshButton } from '../../../../components/buttons';

const SingleInvoiceTickets = ({ invoice }: SingleInvoiceTicketsProps) => {
  const [showTicketForm, setShowTicketForm] = useState(false);
  const { tickets, ticketsLoading, ticketsError, ticketsRefetch } =
    useGetInvoiceTickets();
  const { results } = invoice;

  const handleTicketRefresh = () => {
    return ticketsRefetch();
  };

  const onSentCallback = () => {
    ticketsRefetch();
  };

  const loadTickets = () => {
    toast.promise(handleTicketRefresh(), {
      loading: 'Loading queries ...',
      success: 'Statement queries updated',
      error: 'Error updating statement queries',
    });
  };

  if (ticketsLoading) {
    return <SingleItemLoader label='Loading queries' />;
  }

  if (ticketsError) {
    return (
      <SingleItemError
        errorMessage='Error loading queries, please refresh your browser'
        className='has-bg-blue-100'
      />
    );
  }

  return (
    <SingleItemTickets>
      <layout.Container>
        <header className='tickets-list-header'>
          <h2>
            Invoice Queries <span>{tickets?.results.items.length}</span>
          </h2>
          <div className='tickets-list-actions'>
            <RefreshButton
              refreshFunction={loadTickets}
              label='Update queries'
            />

            <button
              className='button button-primary--blue'
              onClick={() => setShowTicketForm(!showTicketForm)}
            >
              {!showTicketForm ? 'Add Query' : 'View Queries'}
            </button>
          </div>
        </header>
        {showTicketForm ? (
          <SingleItemTicketsForm>
            <AddQuery
              queryType='invoices'
              queryItem={results}
              onSentCallback={onSentCallback}
            />
          </SingleItemTicketsForm>
        ) : (
          <>
            {tickets && (
              <TicketsList showFilters={false} tickets={tickets.results} />
            )}
          </>
        )}
      </layout.Container>
    </SingleItemTickets>
  );
};

export { SingleInvoiceTickets };
