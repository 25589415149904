import { UploadButtonStyled } from './styles/uploadButton.styles';

import { UploadButtonProps } from './types';
const UploadButtonDesktop = ({
  modalFunction,
  setModalType,
  label,
}: UploadButtonProps) => {
  const handleModal = () => {
    if (setModalType) {
      setModalType('add-new');
    }

    modalFunction(true);
  };

  return (
    <UploadButtonStyled onClick={() => handleModal()}>
      {label}
    </UploadButtonStyled>
  );
};

export { UploadButtonDesktop };
