import { QueryFormItemProps } from '../types';

const AddQueryInvoices = ({
  categories,
  register,
  invoiceNumber,
}: QueryFormItemProps) => {
  return (
    <>
      <div className='form-group form-group--select'>
        <label htmlFor='category'>Category</label>
        <div className='select-wrapper'>
          <select id='category' {...register('category')}>
            {categories.map((category) => (
              <option key={category.value} value={category.value}>
                {category.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='form-group'>
        <label htmlFor='invoiceNumber'>Invoice Number</label>
        <input
          id='invoiceNumber'
          type='text'
          className='form-control'
          {...register('invoiceNumber')}
          readOnly
          value={invoiceNumber}
          defaultValue={invoiceNumber}
        />
      </div>
    </>
  );
};

export { AddQueryInvoices };
