import { useUserContext } from '../../../providers/user/useUserContext';

import {
  DashboardContentStyled,
  DashboardLogoStyled,
  DashboardHomepageMessageStyled,
  DashboardHomepageMessageButtonStyled,
} from '../styles/dashboardContent.styles';
const DashboardContent = () => {
  const { user } = useUserContext();

  return (
    <DashboardContentStyled>
      <div>
        <h1>
          Welcome {user && user.given_name ? <> {user.given_name}</> : null}
        </h1>

        <p>
          See all your energy transactions at a glance, and easily manage
          forecasts, invoices and more.
        </p>
      </div>
      <DashboardLogoStyled></DashboardLogoStyled>
      <DashboardHomepageMessageStyled>
        <h2>Energy Generators: have your say</h2>
        <p>
          Independent energy generators already play a vital role in supporting
          the UK’s net zero and energy security ambitions.
        </p>
        <p>
          However, planning restrictions, funding challenges and red tape have
          often stopped many projects from becoming a reality.
        </p>
        <p>
          With a new Labour government in place, now is the time for independent
          energy generators to have their say.
        </p>
        <p>
          From policy decisions to the future of the Power Purchase Agreement
          market, want to make sure your voice is heard.
        </p>
        <p>
          <DashboardHomepageMessageButtonStyled
            target='_blank'
            href='https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fsurvey.survicate.com%2Fe3f6ee1e9d283256%2F%3Fp%3Dhubspot%26first_name%3D%257b%257bcontact.firstname%257d%257d%26last_name%3D%257b%257bcontact.lastname%257d%257d%26email%3D%257b%257bcontact.email%257d%257d&data=05%7C02%7Ctamzin.boyd%40npower.com%7C429ae04992784915e28408dcc29ce0d3%7Cb914a242e718443ba47c6b4c649d8c0a%7C0%7C0%7C638599226242805568%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=19NZ0iD3gtlS3vkB%2Bqe4VPUTvTpjIWYBsyaiZfEiIjc%3D&reserved=0'
          >
            Have your say
          </DashboardHomepageMessageButtonStyled>
        </p>
      </DashboardHomepageMessageStyled>
    </DashboardContentStyled>
  );
};

export { DashboardContent };
