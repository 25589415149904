import { MainLayout } from '../../../../components/layouts/mainLayout';
import { useGetSingleInvoice } from '../../../../hooks/invoices';
import {
  SingleItemError,
  SingleItemLoader,
} from '../../../../components/singleItem';

import { SingleInvoiceHeader } from '../components/singleInvoiceHeader';
import { SingleInvoiceContent } from '../components/singleInvoiceContent';
import { SingleInvoiceTickets } from '../components/singleInvoiceTickets';
import { InfoResources } from '../../../../components/infoResources';

const InvoicesSingle = () => {
  const { singleInvoiceData, singleInvoiceLoading, singleInvoiceError } =
    useGetSingleInvoice();

  if (singleInvoiceLoading) {
    return (
      <MainLayout>
        <SingleItemLoader label='Loading invoice' />;
        <InfoResources />
      </MainLayout>
    );
  }

  if (singleInvoiceError) {
    return (
      <MainLayout>
        <SingleItemError errorMessage='Error loading invoice, please refresh your browser' />
        <InfoResources />
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      {singleInvoiceData ? (
        <>
          <SingleInvoiceHeader
            invoiceReference={singleInvoiceData.results.reference}
            invoicePipelineStage={singleInvoiceData.results.pipelineStageLabel}
          />
          <SingleInvoiceContent
            date={singleInvoiceData.results.date}
            invoiceNumber={singleInvoiceData.results.invoice_number}
            total={singleInvoiceData.results.net_total}
            attachmentUrl={singleInvoiceData.results.invoice_attachment_url}
          />
          <SingleInvoiceTickets invoice={singleInvoiceData} />
        </>
      ) : null}
      <InfoResources />
    </MainLayout>
  );
};

export { InvoicesSingle };
