import styled from 'styled-components';

export const RegisterButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 2.4rem;
  color: var(--nbs-navy-blue);
  font-weight: bold;
  text-transform: uppercase;
  border: solid 0.2rem var(--nbs-navy-blue);
  border-radius: 999rem;

  &:hover {
    color: var(--white);
    background-color: var(--nbs-navy-blue);
  }
`;
