import { MainLayout } from '../../../../components/layouts/mainLayout';
import { Hero } from '../../../../components/hero';
import queriesHeroBgImage from '../../../../img/tickets_hero_image.png';
import { Modal } from '../../../../components/modal';
import { Row } from '../../../../components/layout/row';
import {
  UploadButtonDesktop,
  RefreshButton,
} from '../../../../components/buttons';
import { QueriesList } from '../components/queriesList';

// Hooks
import { useState } from 'react';
import { useGetTickets } from '../../../../hooks/tickets';
import toast from 'react-hot-toast';
import { AddQuery } from '../../../../components/forms/queries/addQuery';
import { InfoResources } from '../../../../components/infoResources';

// import { SearchForm } from '../../../../components/search';

const Queries = () => {
  const [modalActive, setModalActive] = useState(false);
  const { refetchTickets } = useGetTickets();

  const handleModalClose = () => {
    refetchTickets();
    setModalActive(false);
  };

  const handleFetchItems = async () => {
    await refetchTickets();
  };

  const loadItems = () => {
    toast.promise(handleFetchItems(), {
      loading: 'Loading queries ...',
      success: 'Queries updated',
      error: 'Error updating queries',
    });
  };

  return (
    <MainLayout>
      <Hero
        title='Queries'
        subTitle='Our team is always on hand to help you with any questions or concerns. Here you can keep track of your existing queries, or create a new one.'
        bgImage={queriesHeroBgImage}
      >
        <Row className='p-t-sm p-b-md'>
          <UploadButtonDesktop modalFunction={setModalActive} label='Add new' />
          <RefreshButton refreshFunction={loadItems} label='Refresh list' />
        </Row>

        <QueriesList />
      </Hero>
      <InfoResources />
      <Modal
        modalTitle='Create new Query'
        modalActiveState={modalActive}
        setModalActiveState={setModalActive}
        updater={handleFetchItems}
      >
        <AddQuery queryType='all' setModalActiveState={handleModalClose} />
      </Modal>
    </MainLayout>
  );
};

export { Queries };
