import headerLogoUrl from '../../img/energy-generators-2024-colour-grey.png';

const HeaderLogoImage = () => {
  return (
    <img
      src={headerLogoUrl}
      alt='Energy Generators Logo'
      data-testid='logo-item'
    />
  );
};

export default HeaderLogoImage;
