export default function LogoutBlue() {
  return (
    <svg
      width='23'
      height='21'
      viewBox='0 0 23 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.125 4.875L15.5387 6.46125L18.4412 9.375H7V11.625H18.4412L15.5387 14.5275L17.125 16.125L22.75 10.5L17.125 4.875ZM2.5 2.625H11.5V0.375H2.5C1.2625 0.375 0.25 1.3875 0.25 2.625V18.375C0.25 19.6125 1.2625 20.625 2.5 20.625H11.5V18.375H2.5V2.625Z'
        fill='#023F85'
      />
    </svg>
  );
}
