export default function MenuMoreBlue() {
  return (
    <svg
      width='35'
      height='8'
      viewBox='0 0 35 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='4' cy='4' r='4' fill='#023F85' />
      <circle cx='17.3334' cy='4' r='4' fill='#023F85' />
      <circle cx='30.6666' cy='4' r='4' fill='#023F85' />
    </svg>
  );
}
