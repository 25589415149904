// Hooks
import { useState, useEffect } from 'react';
import { MessagesItem } from '../../types';
import { useMessagesContext } from '../../../../../providers/messages/useMessagesContext';
import { useMessagesStatusUpdate } from '../../../../../hooks/messages/useMessagesStatusUpdate';

// Utils
import { formatters } from '../../../../../helpers';

// Components
import { MessagesInboxListMessagesItemStyled } from '../../style/messagesItem.styles';
import { StatusBadge } from '../../../../../components';

type MessagesInboxListItemMobileProps = {
  message: MessagesItem;
  setSingleMessageActive: (value: boolean) => void;
};

const MessagesInboxListItemMobile = ({
  message,
  setSingleMessageActive,
}: MessagesInboxListItemMobileProps) => {
  const [readStatus, setReadStatus] = useState(message.isUnread);
  const [activeStatus, setActiveStatus] = useState(false);

  const { setActiveMessage, activeMessage } = useMessagesContext();
  const { handleUpdateStatus } = useMessagesStatusUpdate();

  const handleSelectItem = () => {
    setActiveMessage(message);
    setSingleMessageActive(true);

    if (readStatus) {
      handleUpdateStatus(message.id);
      setReadStatus(false);
    }
  };

  useEffect(() => {
    if (activeMessage && activeMessage.id === message.id) {
      setActiveStatus(true);
    } else {
      setActiveStatus(false);
    }
  }, [message, activeMessage]);

  return (
    <MessagesInboxListMessagesItemStyled
      onClick={() => {
        handleSelectItem();
      }}
    >
      <div
        className={`message-item ${readStatus ? 'is-unread' : ''} ${
          activeStatus ? 'is-active' : ''
        }`}
      >
        <div className='message-item__content'>
          <h4>{message.subject ? <>{message.subject}</> : <>No subject</>}</h4>
          <ul>
            {message.from ? (
              <li className='message-item-from'>From: {message.from}</li>
            ) : null}

            <li>
              <StatusBadge statusType={message.object} />
            </li>
          </ul>
        </div>
        <div className='message-item__date'>
          <span>
            {formatters.formatUnixDate(message.updatedOn, 'DD/MM/YYYY, h:mm A')}
          </span>
        </div>
      </div>
    </MessagesInboxListMessagesItemStyled>
  );
};

export { MessagesInboxListItemMobile };
