import * as React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from '../auth/auth0ProviderWithNavigate';

import { UserProvider } from '../user/userProvider';
import { MessagesProvider } from '../messages/messagesProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 5 * 60 * 1000,
    },
  },
});

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Auth0ProviderWithNavigate>
          <UserProvider>
            <MessagesProvider>{children}</MessagesProvider>
          </UserProvider>
        </Auth0ProviderWithNavigate>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
