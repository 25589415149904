import axios from 'axios';
import { appHelpers } from '../../../helpers';

const updateHubspotUser = async (userData) => {
  const config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/users/updateHubspotUserData`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: userData,
  };

  try {
    await axios(config);
    return { status: 'success', message: 'User updated' };
  } catch (err) {
    return { status: 'error', message: err };
  }
};

export { updateHubspotUser };
