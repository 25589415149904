import React from 'react';

interface CustomInputProps {
  value?: string;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
}

const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
  ({ value, onClick, onChange, placeholder, className }, ref) => (
    <input
      type='text'
      ref={ref}
      value={value}
      onClick={onClick}
      onChange={onChange}
      placeholder={placeholder}
      aria-label={placeholder}
      className={className}
    />
  )
);

CustomInput.displayName = 'CustomInput';

export default CustomInput;
