import { RefresherWrapper, Refresher } from './styles/refreshButton.styles';
import RefreshBlue from '../../svg/refreshBlue';

type RefreshButtonProps = {
  refreshFunction: () => void;
  label: string;
};

const RefreshButton = ({ refreshFunction, label }: RefreshButtonProps) => {
  return (
    <RefresherWrapper>
      <Refresher
        onClick={() => {
          refreshFunction();
        }}
      >
        <RefreshBlue />
        <span>{label}</span>
      </Refresher>
    </RefresherWrapper>
  );
};

export { RefreshButton };
