import styled from 'styled-components';

export const NotificationsDropdownStyled = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
`;

export const NotificationsDropdownToggleStyled = styled.button`
  display: flex;
  position: relative;
  padding: 1.2rem;
  background-color: transparent;

  &:hover {
    background-color: var(--nbs-ui-grey);
  }

  svg {
    width: 2.4rem;
    // height: 2.2rem;
  }
`;
