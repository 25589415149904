export const TICKETS_PROPERTIES = [
  'content',
  'createdate',
  'closed_date',
  'hs_lastmodifieddate',
  'hs_object_id',
  'hs_pipeline',
  'hs_pipeline_stage',
  'hs_ticket_category',
  'hs_ticket_priority',
  'subject',
  'hubspot_owner_id',
  'hs_created_by_user_id',
  'submitted_by',
  'last_reply_date',
  'hs_lastactivitydate',
  'estimated_response_date',
  'hs_last_message_sent_at',
  'hs_resolution',
  'closed_date',
  'source_type',
  'hs_ticket_category',
  'statement_id',
  'invoice_id',
  'forecast_id',
];
