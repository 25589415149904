import { ChangeEvent } from 'react';
import { AddQueryCategoriesProps } from '../types';

const AddQueryCategories = ({
  categories,
  register,
  setQueryType,
}: AddQueryCategoriesProps) => {
  const handleCategorySelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (setQueryType) {
      if (e.target.value === 'Invoice Query') {
        setQueryType('invoices');
      } else if (
        e.target.value === 'Statement Query' ||
        e.target.value === 'Statement Due Date Query'
      ) {
        setQueryType('statements');
      } else if (e.target.value === 'Volume Forecast Query') {
        setQueryType('forecasts');
      } else {
        setQueryType('');
      }
    }
  };

  return (
    <div className='form-group form-group--select'>
      <label htmlFor='category'>Category</label>
      <div className='select-wrapper'>
        <select
          {...register('category')}
          onChange={(e) => handleCategorySelect(e)}
        >
          {categories.map((category) => (
            <option key={category.value} value={category.value}>
              {category.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export { AddQueryCategories };
