import { useAuth0 } from '@auth0/auth0-react';
import { LoginButtonStyled } from './styles/loginButton.styles';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLoginClick = () => {
    loginWithRedirect({
      appState: {
        returnTo: '/app/dashboard',
      },
    });
  };

  return (
    <LoginButtonStyled onClick={() => handleLoginClick()}>
      Login
    </LoginButtonStyled>
  );
};

export { LoginButton };
