import styled from 'styled-components';

export const InvoicesListItemStyled = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 20rem 2.8rem 2.8rem;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
  }
`;
