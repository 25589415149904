export default function TicketsIcon() {
  return (
    <svg
      width='44'
      height='57'
      viewBox='0 0 44 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M43.9045 35.5382C43.9045 36.2994 43.3754 36.9052 42.7107 36.9052H41.5575V38.2256C41.5575 40.245 40.0788 41.876 38.2745 41.876C36.4702 41.876 34.9779 40.245 34.9779 38.2256V26.3888C34.9779 24.3694 36.4431 22.7383 38.2745 22.7383H38.383V21.9306C38.383 16.8665 36.5923 12.2996 33.7027 8.97533C30.7995 5.65108 26.811 3.61615 22.3884 3.61615C17.9659 3.61615 13.9774 5.66662 11.0742 8.97533C8.17103 12.2996 6.39385 16.8665 6.39385 21.9306V22.7539H6.48882C8.30669 22.7539 9.77185 24.3849 9.77185 26.3888V38.2256C9.77185 40.245 8.29313 41.876 6.48882 41.876C4.68451 41.876 3.20579 40.245 3.20579 38.2256V36.9052H2.05265C1.40148 36.9052 0.858826 36.2994 0.858826 35.5382V29.0451C0.858826 28.2839 1.38791 27.6781 2.05265 27.6781H3.20579V26.3732C3.20579 25.8606 3.30075 25.3635 3.47711 24.9131V21.915C3.47711 9.96949 11.9424 0.276367 22.3884 0.276367C32.8345 0.276367 41.2862 9.96949 41.2862 21.9306V24.9286C41.4626 25.3791 41.5575 25.8606 41.5575 26.3888V27.6936H42.7107C43.3619 27.6936 43.9045 28.2994 43.9045 29.0606V35.5538V35.5382ZM22.8633 54.9555C22.497 55.9808 21.4795 56.4779 20.5841 56.0584L17.3554 54.5517C16.7042 54.2565 16.3108 53.5575 16.2701 52.8119L6.19036 48.4935C5.86477 48.3537 5.63414 47.9808 5.63414 47.577V41.8605H7.31636V41.9692V46.909L17.0027 51.0565C17.4639 50.6837 18.0879 50.575 18.6577 50.8391L21.8865 52.3459C22.7819 52.7653 23.216 53.9303 22.8497 54.9555'
        fill='white'
      />
    </svg>
  );
}
