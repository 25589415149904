import styled from 'styled-components';

export const DocumentsListItemStyled = styled.div`
  display: grid;
  // grid-gap: 1.2rem;
  position: relative;
  grid-template-columns: 17.2rem 1fr 8rem;
  grid-template-rows: 1fr 1fr;

  @media (min-width: 992px) {
    grid-template-columns: 12rem minmax(12rem, 20rem) minmax(36rem, 1fr) 12rem 4rem;
    grid-template-rows: 1fr;
  }

  .document-date {
    grid-column: 1 / 2;
    flex-direction: row;
    flex-wrap: wrap;

    span {
      width: auto;
      font-size: 1.4rem;
    }
  }

  .document-download-button {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    align-items: center;
    justify-content: center;

    @media (min-width: 992px) {
      grid-row: 1 / 2;
      grid-column: 5 / 6;
    }
  }

  .document-type {
    justify-content: center;
    align-items: center;
    font-weight: bold;

    @media (min-width: 992px) {
      justify-content: flex-start;
      align-items: flex-start;
      font-weight: 400;
    }
  }
`;
