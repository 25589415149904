import styled from 'styled-components';

export const UploadButtonStyled = styled.button`
  display: inline-flex;
  width: 100%;
  color: #fff;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  padding: 1rem 1.5rem;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.8;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #0a5ca9;
  border: solid 0.2rem #0a5ca9;
  border-radius: 999px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 2rem;
  margin-top: 2rem;
  &:hover {
    color: var(--white);
    border-color: var(--white);
    box-shadow: 0.2rem 0.2rem 1rem rgb(10 92 169 / 42%);
    background-color: #524388;
  }

  @media (min-width: 667px) {
    width: auto;
    margin-bottom: 0;
    margin-top: 0;
  }
`;
