import { DownloadWhite } from '../../svg';
import { DownloadButtonStyled } from './styles/downloadButtons.styles';

type DownloadButtonProps = {
  downloadUrl: string;
  label: string;
};

const DownloadButton = ({ downloadUrl, label }: DownloadButtonProps) => {
  return (
    <DownloadButtonStyled
      href={downloadUrl}
      target='_blank'
      rel='noreferrer'
      download
    >
      <DownloadWhite />
      {label}
    </DownloadButtonStyled>
  );
};

export { DownloadButton };
