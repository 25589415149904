export default function MenuHomeBlue() {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.3245 11.7119L10.9692 3.57724L1.68231 11.6475L0 9.53691L10.9624 0L16.2145 4.56455V2.26081H18.8405V6.84683L22 9.59415L20.3177 11.7047L20.3245 11.7119ZM18.8405 22H13.6295V13.7509H8.37737V22H3.0774V12.0481L10.9555 5.17268L18.8405 12.0481V22Z'
        fill='#033F85'
      />
    </svg>
  );
}
