import axios from 'axios';
import { appHelpers } from '../../../helpers';

type EmailRequest = {
  subject: string;
  message: string;
  headers: {
    from: {
      email: string;
    };
    sender: {
      email: string;
    };
    to: {
      email: string;
    }[];
  };
  timeStamp: string;
  associations: {
    to: {
      id?: string;
    };
    types: {
      associationCategory: string;
      associationTypeId: number;
    }[];
  }[];
};

const createEmail = async (requestData: EmailRequest) => {
  if (!requestData) {
    throw new Error('Unable to fetch invoice');
  }

  const CONFIG = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/emails/createEmail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: requestData,
  };

  try {
    const { data } = await axios(CONFIG);
    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error creating email: ${err.message}`);
    } else {
      throw new Error(`Error creating email`);
    }
  }
};

export { createEmail };
