import styled from 'styled-components';

export const MyAccountFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 40rem;
  margin: auto;
  padding: 3.2rem;
  background: var(--white);
  box-shadow: 2px 4px 9px rgba(2, 63, 133, 0.1);
  border-radius: 0.8rem;

  h2 {
    margin-bottom: 2rem;
    color: var(--nbs-navy-blue);
    text-align: center;
  }
`;
