import { LoginButton } from '../../../components/buttons';
import { RegisterButton } from '../../../components/buttons/registerButton/registerButton';
import { LoginFormStyled } from '../styles/loginForm.styles';

const LoginForm = () => {
  return (
    <LoginFormStyled>
      <h1>Login or Register</h1>
      <p>Log in with your email address, or register for a new account.</p>
      <LoginButton />
      <RegisterButton />
    </LoginFormStyled>
  );
};

export { LoginForm };
