import { useMatchMedia } from '../../hooks/useMatchMedia';

import { HeroStyled } from './styles/hero.styles';

import { HeroProps } from './types';

const Hero = ({ title, subTitle, bgImage, children }: HeroProps) => {
  const isDeskTopResolution = useMatchMedia('(min-width: 992px)', true);

  return (
    <HeroStyled>
      <div className='hero'>
        {isDeskTopResolution && (
          <figure className='hero-image'>
            <img src={bgImage} alt='' />
          </figure>
        )}
        <div className='hero-content'>
          <div className='hero-items'>
            <h1>{title} </h1>
            <p>{subTitle}</p>
            {children}
          </div>
        </div>
      </div>
    </HeroStyled>
  );
};

export { Hero };
