import { appHelpers } from '../../../helpers';
import axios from 'axios';

const setFirestoreUser = async (data) => {
  const config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/users/setFirestoreUser`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: data,
  };

  try {
    return await axios(config);
  } catch (err) {
    return err;
  }
};

export { setFirestoreUser };
