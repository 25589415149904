import { useState } from 'react';
import { useUserContext } from '../../providers/user/useUserContext';
import { AccountIcon, ArrowDownBlue } from '../svg';

import { AccountMenuDropdown } from './accountDropdownMenu';
import {
  HeaderAccountMenuStyled,
  DropdownButtonName,
  IconWrapper,
} from './styles/headerAccountMenu.styles';

const AccountDropdown = () => {
  const [dropdownState, setDropdownState] = useState(false);
  const { isAuthenticated } = useUserContext();

  if (!isAuthenticated) return null;

  return (
    <HeaderAccountMenuStyled>
      <DropdownButtonName
        className='button--unstyled'
        data-testid='menu-toggle'
        onClick={() => {
          setDropdownState(!dropdownState);
        }}
        aria-label='your account'
      >
        <IconWrapper>
          <AccountIcon />
        </IconWrapper>

        <ArrowDownBlue />
      </DropdownButtonName>
      <AccountMenuDropdown
        dropdownState={dropdownState}
        setDropdownState={setDropdownState}
      />
    </HeaderAccountMenuStyled>
  );
};

export { AccountDropdown };
