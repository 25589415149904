import styled from 'styled-components';

export const SingleItemTicketsWrapperStyled = styled.section`
  width: 100%;
  padding: 3.2rem;
  background: var(--nbs-blue-light);

  .tickets-list-header {
    display: flex;
    flex-direction: column;
    padding: 3.2rem 0 0;

    @media (min-width: 992px) {
      flex-direction: row;
      align-items: center;
    }

    h2 {
      display: flex;

      align-items: inherit;
      font-size: 2.4rem;

      @media (min-width: 992px) {
        margin-right: auto;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.4rem;
        height: 2.4rem;
        margin-left: 1.2rem;
        color: var(--white);
        font-size: 1.2rem;
        background: var(--nbs-blue);
        border-radius: 999px;
      }
    }

    .tickets-list-actions {
      display: flex;
      flex-direction: column;

      @media (min-width: 992px) {
        align-items: center;
        flex-direction: row;
      }

      > div {
        justify-content: flex-start;

        @media (min-width: 992px) {
          justify-content: flex-end;
        }
      }

      .button {
        width: 28rem;
        margin-left: 2rem;
      }
    }
  }
`;
