export const FORECAST_PROPERTIES = [
  'reference',
  'status',
  'forecast_attachment',
  'forecast_from',
  'forecast_to',
  'total_forecasted_kwh',
  'submitted_by',
  'forecast_estimated_volume__mwh_',
  'forecast_month',
  'mpxn',
  'site_name',
  'status_dropdown',
  'hs_pipeline',
  'hs_pipeline_stage',
];
