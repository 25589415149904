import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Divider from '../layout/divider/divider';
import CustomInput from './customInput'; // Import the custom input component

interface FilterProps {
  onSubmit: (startDate: Date | null, endDate: Date | null) => void;
  onClear: () => void;
  initialStartDate: Date | null;
  initialEndDate: Date | null;
}

const Filter: React.FC<FilterProps> = ({
  onSubmit,
  onClear,
  initialStartDate,
  initialEndDate,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    if (date && endDate && date > endDate) {
      setEndDate(date); // Ensure the end date is not before the start date
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const handleFilterClick = () => {
    onSubmit(startDate, endDate); // Pass the start and end dates back to the parent when filtering
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
    onClear(); // Notify parent to clear any external dependencies
  };

  return (
    <div className='is-display-flex is-flex-col filters-wrapper'>
      <Divider text='' />
      <div>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          startDate={startDate}
          endDate={endDate}
          enableTabLoop={false}
          dateFormat='dd-MM-yyyy'
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          placeholderText='Start Date'
          customInput={<CustomInput className='custom-date-picker lg-m-none' />}
        />
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          enableTabLoop={false}
          dateFormat='dd-MM-yyyy'
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          placeholderText='End Date'
          customInput={<CustomInput className='custom-date-picker lg-m-l-md' />}
        />
      </div>
      <Divider text='' />
      <div className='is-display-flex'>
        <button
          onClick={handleFilterClick}
          className='button button-primary button-primary--blue'
          aria-label='filter dates'
        >
          Filter
        </button>
        <button
          onClick={handleClearDates}
          className='is-font-bold is-text-blue m-l-sm'
          aria-label='clear dates'
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export { Filter };
