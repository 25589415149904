import { StatementSingleContentProps } from '../types';
import {
  SingleItemContent,
  SingleItemAttachment,
} from '../../../../components/singleItem';
import { Container } from '../../../../components/container';
import { formatters } from '../../../../helpers';

const StatementSingleContent = ({
  statementData,
}: StatementSingleContentProps) => {
  return (
    <SingleItemContent>
      <Container>
        <ul>
          <li>
            <strong>Statement Date:</strong>{' '}
            {statementData.releaseDate ? (
              <>
                {formatters.formatDate(statementData.releaseDate, 'DD/MM/YYYY')}
              </>
            ) : (
              <>
                {formatters.formatDate(statementData.createdDate, 'DD/MM/YYYY')}
              </>
            )}
          </li>
          <li>
            <strong>Statement Type:</strong> {statementData.statementType}
          </li>
          <li>
            <strong>Statement Period:</strong> {statementData.statementPeriod}
          </li>
          <li>
            <strong>Statement Net Total:</strong>{' '}
            {formatters.formatCurrency(statementData.total)}
          </li>
        </ul>
        {statementData.attachment ? (
          <>
            <SingleItemAttachment attachmentUrl={statementData.attachment} />
          </>
        ) : null}
      </Container>
    </SingleItemContent>
  );
};

export { StatementSingleContent };
