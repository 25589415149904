import {
  DocumentsFormProps,
  DocumentData,
} from '../../../features/energyGenerators/documents/types';

// Form stuff
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Toast stuff
import toast from 'react-hot-toast';

// Files
import {
  VALID_FILE_EXTENSIONS,
  MAX_FILE_SIZE,
} from '../../../constants/fileUploadRules';
import { files } from '../../../helpers';
import { FileUpload } from '../fileUpload';
import { useGetContracts } from '../../../hooks/contracts';

import { useState } from 'react';
import { FormSending } from '../formSending';
import FormSuccess from '../formSuccess/formSuccess';

type FormData = {
  document_reference: string;
  contract_id?: string;
  // other form fields...
};

type ContractDropdownItem = {
  id: string;
  contract_account_name: string;
};

const DocumentsForm = ({ formSubmitHandler, id }: DocumentsFormProps) => {
  const { contractsData, isContractsLoading } = useGetContracts();
  const [formStatus, setFormStatus] = useState('start');

  const schema = yup
    .object({
      document_reference: yup
        .string()
        .required('Document reference is required'),
      [`attachment_${id}`]: yup
        .mixed()
        .required('Attachment is required')
        .test(
          'is-valid-type',
          `Please upload one of the following allowed file types: ${VALID_FILE_EXTENSIONS.join(
            ', '
          )}`,
          (value) =>
            Boolean(files.isValidFileType(value && value.name.toLowerCase()))
        )
        .test(
          'is-valid-size',
          'Max allowed size is 10MB',
          (value) => value && value.size <= MAX_FILE_SIZE
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (data: FormData) => {
    setFormStatus('sending');
    try {
      formSubmitHandler(data as unknown as DocumentData);
      reset();
      setFormStatus('sent');
    } catch (err) {
      console.error(err);
    }
  };

  const handleForm = (data: FormData) => {
    toast.promise(handleFormSubmit(data as unknown as DocumentData), {
      loading: 'Saving...',
      success: () => `Successfully uploaded document`,
      error: () => `There was an error uploading your document`,
    });
  };

  console.log(contractsData);

  return (
    <>
      <h2 className='m-b-sm'>Upload New Document</h2>
      {formStatus === 'start' && (
        <form onSubmit={handleSubmit(handleForm)}>
          <div className='form-group'>
            <label htmlFor='document_reference'>Document Reference</label>
            <input
              id='document_reference'
              className={`form-control ${
                errors.document_reference && 'has-error'
              }`}
              {...register('document_reference')}
              placeholder='Document reference'
            />
            {errors.document_reference && (
              <p className='form-error'>{errors.document_reference?.message}</p>
            )}
          </div>
          <div className='form-group form-group--select'>
            <label htmlFor='contract_id'>Select Contract</label>
            <div className='select-wrapper'>
              <select id='contract_id' {...register('contract_id')}>
                {isContractsLoading ? (
                  <option selected disabled value=''>
                    Loading contracts
                  </option>
                ) : null}
                {!isContractsLoading && contractsData?.results?.length > 0 ? (
                  <>
                    <option selected disabled value=''>
                      Select contract
                    </option>
                    {contractsData.results.map(
                      (contract: ContractDropdownItem) => (
                        <option key={contract.id} value={contract.id}>
                          {contract.contract_account_name}
                        </option>
                      )
                    )}
                  </>
                ) : (
                  <>
                    <option selected disabled value=''>
                      No Contracts
                    </option>
                  </>
                )}
              </select>
            </div>
          </div>
          <FileUpload setValue={setValue} id={id} errors={errors} />
          <input
            className='button button--full button-primary--blue'
            type='submit'
            value='Submit'
          />
        </form>
      )}
      {formStatus === 'sending' && (
        <FormSending
          title='Submitting query'
          message='Please wait whilst we submit your document'
        />
      )}

      {formStatus === 'sent' && (
        <FormSuccess
          title='Success'
          message='Your document has been submitted'
        />
      )}
    </>
  );
};

export { DocumentsForm };
