// Components
import { Hero } from '../../../../components/hero';
import { MainLayout } from '../../../../components/layouts/mainLayout';
import statementsHeroImage from '../../../../img/statements_hero_image.png';
import { Row } from '../../../../components/layout/row';
import { StatementsList } from '../components/statementsList';

import { RefreshButton } from '../../../../components/buttons';
import { Modal } from '../../../../components/modal';

// Hooks
import { useState } from 'react';
import { useMatchMedia } from '../../../../hooks/useMatchMedia';
import toast from 'react-hot-toast';

import { useGetStatements } from '../../../../hooks/statements';
import { AddQuery } from '../../../../components/forms/queries/addQuery';
import { AddInvoice } from '../../../../components/forms/invoices';
import { InfoResources } from '../../../../components/infoResources';

const Statements = () => {
  const [modalActive, setModalActive] = useState(false);
  const [modalType, setModalType] = useState('add-new');
  const [activeItem, setActiveItem] = useState({});
  const isDeskTopResolution = useMatchMedia('(min-width: 992px)', true);
  const { statementsRefetch } = useGetStatements();

  const handleFetchItems = async () => {
    await statementsRefetch();
  };

  const loadItems = () => {
    toast.promise(handleFetchItems(), {
      loading: 'Loading statements ...',
      success: 'Statements updated',
      error: 'Error updating statements',
    });
  };

  const handleModalClose = () => {
    statementsRefetch();
    setModalActive(false);
  };

  return (
    <MainLayout>
      <Hero
        title='Statements'
        subTitle='View your statements at a glance, keep track of any status updates, and submit an invoice, all in just a few clicks.'
        bgImage={statementsHeroImage}
      >
        {isDeskTopResolution && (
          <Row className='p-t-sm p-b-md'>
            <RefreshButton
              refreshFunction={loadItems}
              label='Update statements'
            />
          </Row>
        )}

        <StatementsList
          setModalType={setModalType}
          setModalActive={setModalActive}
          setActiveItem={setActiveItem}
        />
      </Hero>
      <InfoResources />
      <Modal
        modalTitle='Upload new statement'
        modalActiveState={modalActive}
        setModalActiveState={setModalActive}
        updater={handleFetchItems}
      >
        {modalType === 'add-invoice' && (
          <AddInvoice
            type='statements-page'
            id='newInvoiceForm'
            setModalActiveState={setModalActive}
            statement={activeItem}
          />
        )}
        {modalType === 'add-query' && (
          <AddQuery
            queryType='statements'
            queryItem={activeItem}
            setModalActiveState={handleModalClose}
          />
        )}
      </Modal>
    </MainLayout>
  );
};

export { Statements };
