import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IonGrid, IonRow } from '@ionic/react';

const AnnouncementBarWrapper = styled.div`
  width: 100%;
`;

const AnnouncementBar = styled.div`
  padding: 1rem;
  max-width: 123rem;
`;

export default function NavAnnouncementBar({ navAnnouncementMessage }) {
  const [showAnnouncementBar, setShowAnnouncementBar] = useState(false);
  const displayAnnouncementBarMessage = () => {
    if (navAnnouncementMessage) {
      return (
        <AnnouncementBar className='announcements__bar'>
          {navAnnouncementMessage}
          <button
            className='button button--unstyled'
            onClick={() => setShowAnnouncementBar(false)}
          >
            x
          </button>
        </AnnouncementBar>
      );
    }
  };

  return (
    <AnnouncementBarWrapper>
      {showAnnouncementBar ? (
        <IonGrid className='announcements'>
          <IonRow
            color='none'
            className='ion-align-items-center ion-justify-content-center is-text-white'
          >
            {displayAnnouncementBarMessage()}
          </IonRow>
        </IonGrid>
      ) : null}
    </AnnouncementBarWrapper>
  );
}

NavAnnouncementBar.propTypes = {
  navAnnouncementMessage: PropTypes.string,
};
