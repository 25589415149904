import { api } from '../../api/apiHandler';

import { useState } from 'react';
const useUploadFile = () => {
  const [uploading, setUploading] = useState(false);

  const handleUploadFile = async (
    file: unknown,
    hsId: string,
    uploadType: string
  ) => {
    setUploading(true);

    const fileUrl = await api({
      clientName: hsId,
      file: file,
      uploadType: uploadType,
    }).files.uploadFile();

    setUploading(false);

    return fileUrl;
  };

  return {
    uploading,
    handleUploadFile,
  };
};

export { useUploadFile };
