import {
  FooterColumnTitleStyled,
  FooterTelNumberStyled,
} from '../styles/footer.styles';
import { FooterContactListStyled } from './styles/footerContact.styles';

const FooterContact = () => {
  return (
    <>
      <FooterColumnTitleStyled>Contact</FooterColumnTitleStyled>
      <FooterContactListStyled>
        <li>
          New Business:{' '}
          <FooterTelNumberStyled href='tel:03309128884'>
            0330 912 8884
          </FooterTelNumberStyled>
        </li>
        <li>
          Customer Service:{' '}
          <FooterTelNumberStyled href='tel:08001382322'>
            0800 138 2322
          </FooterTelNumberStyled>
        </li>
        <li className='contact-button'>
          <a
            className='button button-secondary button-secondary--white'
            rel='noreferrer'
            target='_blank'
            href='https://npowerbusinesssolutions.com/get-in-touch'
          >
            Contact us
          </a>
        </li>
      </FooterContactListStyled>
    </>
  );
};

export default FooterContact;
