import PropTypes from 'prop-types';

import { LoaderWrapper } from './styles/loader.styles';

const Loader = ({ label }) => {
  return (
    <LoaderWrapper>
      <svg viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
        <circle
          cx='50'
          cy='50'
          fill='none'
          stroke='#023f85'
          strokeWidth='10'
          r='35'
          strokeDasharray='164.93361431346415 56.97787143782138'
        >
          <animateTransform
            attributeName='transform'
            type='rotate'
            repeatCount='indefinite'
            dur='1s'
            values='0 50 50;360 50 50'
            keyTimes='0;1'
          ></animateTransform>
        </circle>
      </svg>
      <p>{label}</p>
    </LoaderWrapper>
  );
};

Loader.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

export { Loader };
