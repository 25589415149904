import { useState } from 'react';

import { MessagesInboxFilter } from '../global/messagesInboxFilter';
import { MessagesInboxListMobile } from './messagesInboxListMobile';
import { MessagesInboxSingleMessageMobile } from './messagesInboxSingleMessageMobile';

const MessagesInboxMobile = () => {
  const [singleMessageActive, setSingleMessageActive] = useState(false);
  return (
    <>
      <MessagesInboxFilter />
      <MessagesInboxListMobile
        setSingleMessageActive={setSingleMessageActive}
      />
      <MessagesInboxSingleMessageMobile
        singleMessageActive={singleMessageActive}
        setSingleMessageActive={setSingleMessageActive}
      />
    </>
  );
};

export { MessagesInboxMobile };
