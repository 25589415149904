import PropTypes from 'prop-types';
import { IonCol } from '@ionic/react';
import { CtaTitle, CtaText, CtaNum } from './ctaBlock.styles';

function CtaBlockContact({ contactTitle, contactText, contactExistingText }) {
  return (
    <IonCol>
      {contactTitle ? (
        <CtaTitle className='is-font-bold is-text-blue'>
          {contactTitle}
        </CtaTitle>
      ) : (
        <CtaTitle className='is-font-bold is-text-blue'>Contact us</CtaTitle>
      )}
      <CtaText>{contactText}</CtaText>
      <CtaNum href='tel:03309128884'>0330 912 8884</CtaNum>
      <CtaText>{contactExistingText}</CtaText>
      <CtaNum href='tel:03309128884'>0330 912 8884</CtaNum>
    </IonCol>
  );
}

CtaBlockContact.propTypes = {
  contactTitle: PropTypes.string,
  contactText: PropTypes.string,
  contactExistingText: PropTypes.string,
};
export default CtaBlockContact;
