import {
  SingleMessageMobileEmptyStateStyled,
  SingleMessageMobileHeaderStyled,
  SingleMessageMobileStyled,
} from '../../style/messagesInboxSingleMessageMobile.styles';
import { useMessagesContext } from '../../../../../providers/messages/useMessagesContext';
import { useMessagesArchivedStatusUpdate } from '../../../../../hooks/messages/useMessagesArchivedStatusUpdate';
import {
  ActiveMessageHeaderStyled,
  ActiveMessageBodyStyled,
  ActiveMessageFooterStyled,
} from '../../style/activeMessage.styles';
import { svg } from '../../../../../components';
import { formatters } from '../../../../../helpers';
import { EmptyState } from '../../../../../components/emptyState';

type MessagesInboxSingleMessageMobileProps = {
  singleMessageActive: boolean;
  setSingleMessageActive: (value: boolean) => void;
};

const MessagesInboxSingleMessageMobile = ({
  singleMessageActive,
  setSingleMessageActive,
}: MessagesInboxSingleMessageMobileProps) => {
  const { activeMessage } = useMessagesContext();
  const { handleUpdateArchiveStatus } = useMessagesArchivedStatusUpdate();
  if (!activeMessage) {
    return (
      <SingleMessageMobileEmptyStateStyled>
        <div className='p-y-lg p-x-md'>
          <EmptyState
            icon='warning'
            label='No messages found'
            text='Your inbox is empty'
          />
        </div>
      </SingleMessageMobileEmptyStateStyled>
    );
  }
  return (
    <SingleMessageMobileStyled
      className={singleMessageActive ? 'is-active' : ''}
    >
      <SingleMessageMobileHeaderStyled>
        <h2>{activeMessage.subject || 'No subject'}</h2>
        <button onClick={() => setSingleMessageActive(false)}>
          <svg.MenuCloseWhite />
        </button>
      </SingleMessageMobileHeaderStyled>
      <ActiveMessageHeaderStyled>
        <div className='message-from'>
          <span className='message-from__avatar'>
            <svg.ProfileBlue />
          </span>
          <span>{activeMessage.from}</span>
        </div>
        <span className='message-date'>
          {formatters.formatUnixDate(activeMessage.updatedOn, 'DD/MM/YYYY')}
        </span>
      </ActiveMessageHeaderStyled>
      <ActiveMessageBodyStyled>
        <div
          dangerouslySetInnerHTML={{ __html: activeMessage.messageBody }}
        ></div>
      </ActiveMessageBodyStyled>
      <ActiveMessageFooterStyled>
        <button
          onClick={() => {
            handleUpdateArchiveStatus(activeMessage.id);
          }}
        >
          Archive message
        </button>
      </ActiveMessageFooterStyled>
    </SingleMessageMobileStyled>
  );
};

export { MessagesInboxSingleMessageMobile };
