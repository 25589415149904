import { AddQueryDropdownProps, StatementItem } from './types';
import { useGetAllStatements } from '../../../../hooks/statements';

const AddQueryStatementsDropdown = ({ register }: AddQueryDropdownProps) => {
  const { statementsData, isStatementsLoading } = useGetAllStatements();

  return (
    <div className='form-group form-group--select'>
      <label htmlFor='statementNumber'>Statement Ref</label>
      <div className='select-wrapper'>
        <select id='statementNumber' {...register('statementNumber')}>
          {isStatementsLoading ? (
            <option selected disabled value=''>
              Loading statements
            </option>
          ) : null}
          {!isStatementsLoading && statementsData.results.length > 0 ? (
            <>
              <option selected disabled value=''>
                Select statement
              </option>
              {statementsData.results.map((statement: StatementItem) => (
                <option key={statement.id} value={statement.id}>
                  {statement.statementNumber}
                </option>
              ))}
            </>
          ) : (
            <>
              <option selected disabled value=''>
                No statements
              </option>
            </>
          )}
        </select>
      </div>
    </div>
  );
};

export { AddQueryStatementsDropdown };
