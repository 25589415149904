import { MessagesInboxListStyled } from '../../style/messagesInboxList.styles';
import { MessagesInboxFilter } from '../global/messagesInboxFilter';
import { MessagesInboxListMessages } from './messagesInboxListMessages';
import { MessagesInboxSorter } from '../global/messagesInboxSorter';

const MessagesInboxList = () => {
  return (
    <MessagesInboxListStyled>
      <MessagesInboxSorter />
      <MessagesInboxFilter />
      <MessagesInboxListMessages />
    </MessagesInboxListStyled>
  );
};

export { MessagesInboxList };
