import PropTypes from 'prop-types';
import { EllipsisHorizontal } from '../svg';
import { CardActionMenuToggleStyled } from './styles/cardActionMenuToggle.styles';
const CardActionMenuToggle = ({ dropdownState, setDropdownState }) => {
  return (
    <CardActionMenuToggleStyled>
      <button
        className={dropdownState ? 'is-active' : null}
        onClick={() => setDropdownState(!dropdownState)}
        aria-label='Toggle menu'
      >
        <EllipsisHorizontal />
      </button>
    </CardActionMenuToggleStyled>
  );
};

export default CardActionMenuToggle;

CardActionMenuToggle.propTypes = {
  dropdownState: PropTypes.bool,
  setDropdownState: PropTypes.func,
};
