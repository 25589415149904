import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuth0Config } from '../../config/auth0';

const useGetAuth0Token = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const auth0Config = getAuth0Config();
  const [authToken, setAuthToken] = useState('');

  const fetchToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${auth0Config?.domain}/api/v2/`,
        },
      });

      setAuthToken(accessToken);
    } catch (error) {
      // Handle error if needed
      console.error('Error fetching Auth0 token:', error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    fetchToken();
  }, [isAuthenticated]);

  return authToken;
};

export { useGetAuth0Token };
