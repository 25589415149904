import { useEffect, useState, useRef } from 'react';

// Helpers
import { sorters } from '../../../helpers';

import { TicketListItem } from '../ticketListItem';
import { TicketsListProps } from '../types';

import {
  TicketsListHeaderStyles,
  TicketsListSortSelectWrapperStyled,
  TicketsListStyled,
  TicketsListEmptyStateStyled,
} from './styles/ticketsList.styled';
import { emptyState } from '../..';

import { TicketItem } from '../../../types/tickets';
const TicketsList = ({ showFilters, tickets }: TicketsListProps) => {
  const [sortedTickets, setSortedTickets] = useState<TicketItem[]>([]);
  const sorterRef = useRef<HTMLSelectElement | null>(null);
  const { items } = tickets;

  const handleSortTickets = (sortBy: string) => {
    let sortedTicketsList;
    if (sortBy === 'date') {
      sortedTicketsList = sorters.sortByDesc([...sortedTickets], 'createdAt');
    } else if (sortBy === 'status') {
      sortedTicketsList = sorters.sortStringByAsc(
        [...sortedTickets],
        'pipelineStage'
      );
    } else if (sortBy === 'subject') {
      sortedTicketsList = sorters.sortStringByAsc(
        [...sortedTickets],
        'subject'
      );
    }

    setSortedTickets([...sortedTicketsList]);
  };

  useEffect(() => {
    if (tickets) {
      setSortedTickets(tickets.items);
    }
  }, [tickets]);

  if (items && items.length === 0) {
    return (
      <TicketsListEmptyStateStyled>
        <emptyState.EmptyState label='No queries found' />
      </TicketsListEmptyStateStyled>
    );
  }

  return (
    <>
      <TicketsListHeaderStyles>
        <div>
          {showFilters && (
            <TicketsListSortSelectWrapperStyled>
              <div className='select-wrapper'>
                <select
                  name='sort'
                  id='sort'
                  onChange={(e) => handleSortTickets(e.target.value)}
                  ref={sorterRef}
                  defaultValue=''
                >
                  <option value='' disabled>
                    Sort by
                  </option>
                  <option value='date'>Sort by date</option>
                  <option value='status'>Sort by status</option>
                  <option value='subject'>Sort by subject</option>
                </select>
              </div>
              <div className='refresh-wrapper'></div>
            </TicketsListSortSelectWrapperStyled>
          )}
        </div>
      </TicketsListHeaderStyles>

      <TicketsListStyled>
        {sortedTickets &&
          sortedTickets.map((item) => (
            <TicketListItem key={item.id} ticket={item} />
          ))}
      </TicketsListStyled>
    </>
  );
};

export default TicketsList;
