import { useRef } from 'react';

import { SearchFormWrapperStyled } from './styles/searchFormWrapper';
import { SearchInputStyled } from './styles/searchInput.styles';
import { SearchBlue } from '../svg';

// import { useSearch } from '../../hooks/search';

type SearchFormProps = {
  searchType: string;
  setSearchTerm: (searchTerm: string) => void;
  searchTerm: string;
};

const setSearchPlaceholder = (type: string) => {
  switch (type) {
    case 'documents':
      return 'Search... Document reference';
      break;
    case 'invoices':
      return 'Search... Date or Unique invoice reference';
      break;
    case 'forecasts':
      return 'Search... Forecast reference or Site name';
      break;
    case 'tickets':
      return 'Search... Query subject or message';
      break;
    case 'statements':
      return 'Search...  PM reference e.g PM12345';
      break;
    default:
      return 'No results found. Try a different search term.';
      break;
  }
};

const SearchForm = ({
  searchType,
  setSearchTerm,
  searchTerm,
}: SearchFormProps) => {
  const searchInput = useRef<HTMLInputElement>(null);

  const handleSearchInput = () => {
    if (searchInput.current) {
      setSearchTerm(searchInput.current.value);
    }
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // This will stop the form from submitting
  };
  return (
    <SearchFormWrapperStyled>
      <form onSubmit={handleFormSubmit}>
        <div className='form-control'>
          <SearchInputStyled
            type='text'
            name='searchInput'
            id='searchInput'
            ref={searchInput}
            onChange={handleSearchInput}
            placeholder={setSearchPlaceholder(searchType)}
            value={searchTerm}
            aria-label='Search'
          />
          <SearchBlue />
        </div>
      </form>
    </SearchFormWrapperStyled>
  );
};

export { SearchForm };
