import { useState } from 'react';
import PropTypes from 'prop-types';
// Styles
import '../../footer/footer.styles.scss';
import Container from '../../../layout/container/container';
import FooterMobileNav from './footerMobileNav';
import styled from 'styled-components';
import MenuHomeBlue from '../../../svg/menuHomeBlue';
import MenuMessagesBlue from '../../../svg/menuMessagesBlue';
import MenuTicketsBlueMobile from '../../../svg/menuTicketsBlueMobile';
import MenuMoreBlue from '../../../svg/menuMoreBlue';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../../../providers/user/useUserContext';

const FooterMobileIcon = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  span {
    color: #4e4e50;
    font-size: 1.2rem;
  }
`;

const FooterMobileNavHolder = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
  height: 80px;
`;

const FooterChatIcon = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  span {
    color: #4e4e50;
    font-size: 1.2rem;
  }
`;

const FooterMoreIcon = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    color: #4e4e50;
    font-size: 1.2rem;
  }
`;

const IconHolder = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
`;

const MobileBottomPadding = styled.div`
  padding-bottom: 80px;
`;

export default function FooterMobile(props) {
  const { isAuthenticated } = useUserContext();
  const { headerAccountName, companyName } = props;
  const [menuState, setMenuState] = useState(false);
  const handleMenuState = () => {
    setMenuState((menuState) => !menuState);
  };
  return (
    <>
      {isAuthenticated ? (
        <>
          <FooterMobileNav
            menuState={menuState}
            setMenuState={setMenuState}
            headerAccountName={headerAccountName}
            companyName={companyName}
          ></FooterMobileNav>
          <MobileBottomPadding />
          <div className='site-footer-mobile'>
            <Container>
              <>
                <FooterMobileNavHolder>
                  <FooterMobileIcon to='/app/dashboard'>
                    <IconHolder>
                      <MenuHomeBlue />
                    </IconHolder>
                    <span className='p-t-2xs'>Home</span>
                  </FooterMobileIcon>
                  <FooterChatIcon to='/app/messages'>
                    <IconHolder>
                      <MenuMessagesBlue />
                    </IconHolder>

                    <span className='p-t-2xs'>Messages</span>
                  </FooterChatIcon>
                  <FooterMobileIcon to='/app/queries'>
                    <IconHolder>
                      <MenuTicketsBlueMobile />
                    </IconHolder>
                    <span className='p-t-2xs'>Queries</span>
                  </FooterMobileIcon>
                  <FooterMoreIcon
                    onClick={() => {
                      handleMenuState();
                    }}
                  >
                    <IconHolder>
                      <MenuMoreBlue />
                    </IconHolder>
                    <span className='p-t-2xs'>More</span>
                  </FooterMoreIcon>
                </FooterMobileNavHolder>
              </>
            </Container>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

FooterMobile.propTypes = {
  className: PropTypes.string,
  headerAccountName: PropTypes.string,
  companyName: PropTypes.string,
};
