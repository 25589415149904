import {
  MessagesInboxSorterWrapperStyled,
  MessagesInboxSorterFormStyled,
} from '../../style/messagesInboxSorter.styles';
import { MessagesInboxSorterDropdown } from './messagesInboxSorterDropdown';

const MessagesInboxSorter = () => {
  return (
    <MessagesInboxSorterWrapperStyled>
      <MessagesInboxSorterFormStyled>
        <label htmlFor='sort'>Sort by:</label>
        <MessagesInboxSorterDropdown />
      </MessagesInboxSorterFormStyled>
    </MessagesInboxSorterWrapperStyled>
  );
};

export { MessagesInboxSorter };
