import moment from 'moment';

const formatDate = (date, format) => {
  const momentDate = moment(date);

  if (!momentDate.isValid()) {
    return 'Invalid date';
  }

  return momentDate.format(format);
};

export { formatDate };
