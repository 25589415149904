import { useContext } from 'react';
import { MessagesContext } from './messagesContext';

const useMessagesContext = () => {
  const appUser = useContext(MessagesContext);

  if (appUser === undefined) {
    throw new Error(
      'useMessagesContext can only be used inside of MessagesProvider'
    );
  }

  return appUser;
};

export { useMessagesContext };
