import { useEffect } from 'react';
import { Container, Wrapper } from '../../../components/layout';
import { MainLayout } from '../../../components/layouts/mainLayout';
import { LoginForm } from '../components/loginForm';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router';

export const Login = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/app/dashboard');
    }
  }, [isAuthenticated]);

  return (
    <MainLayout>
      <Wrapper gradient={true}>
        <Container>
          <LoginForm />
        </Container>
      </Wrapper>
    </MainLayout>
  );
};
