import styled from 'styled-components';
import menuLine from '../../../assets/img/nav-bar-bg.webp';

export const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 2rem);
  right: -10px;
  width: 90vw;
  color: var(--nbs-blue);
  background: var(--white);
  box-shadow: 4px 6px 9px rgba(87, 87, 87, 0.12);
  border-radius: 1px;
  opacity: 0;
  visibility: hidden;
  z-index: 3000;
  @media (min-width: 992px) {
    right: 0;
    width: 36rem;
  }
  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  header {
    padding: 2rem 2rem 3.2rem;
  }

  .menu_bg_line {
    background: url(${menuLine}) no-repeat;
    background-size: cover;
    background-position: center;
    height: 15px;
  }

  h3 {
    margin: 0;
  }
`;

export const List = styled.div`
  margin: 0;
  padding: 0;
  background: var(--white);
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  color: var(--nbs-blue);
  overflow: visible;
  &:hover {
    background: var(--nbs-blue-light-100);
  }

  a,
  button,
  &.usermenu-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 5.6rem;
    padding: 0 1.2rem;
    color: var(--nbs-black);
  }

  &.usermenu-item {
    flex-direction: row;
  }

  a {
    border-bottom: solid 0.1rem var(--nbs-ui-grey);
  }

  .icon-wrapper {
    width: 2.8rem;
  }

  svg {
    max-width: 2rem;
    max-height: 2rem;
    margin-right: 0.8rem;
    // margin: 0 1.2rem;
  }

  .account-link,
  .list-link {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding: 0.8rem;
    font-size: 1.4rem;

    &__label {
      color: var(--nbs-navy-blue);
      font-size: 1.2rem;
    }
  }
`;
