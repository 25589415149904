import { CardColStyled } from './styles/cardCol.styles';

type CardColProps = {
  children: React.ReactNode;
  className?: string;
};

const CardCol = ({ children, className }: CardColProps) => {
  return <CardColStyled className={className}>{children}</CardColStyled>;
};

export default CardCol;
