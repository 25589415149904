type FormInfoProps = {
  message: string;
};

import { FormInfoStyled } from './styles/formInfo.styles';

const FormInfo = ({ message }: FormInfoProps) => {
  return (
    <FormInfoStyled>
      <p>{message}</p>
    </FormInfoStyled>
  );
};

export { FormInfo };
