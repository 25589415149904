import { Link } from 'react-router-dom';
import { QueriesSingleItemProps } from '../types';

const QueriesSingleContent = ({ ticket }: QueriesSingleItemProps) => {
  return ticket ? (
    <>
      <h2>Query description</h2>
      <p>{ticket.content}</p>
      <p>{ticket.category}</p>
      {ticket.ticketStatementId ? (
        <Link to={'/statements/' + ticket.ticketStatementId}>
          View Statement
        </Link>
      ) : null}
      {ticket.ticketInvoiceId ? (
        <Link to={'/invoices/' + ticket.ticketInvoiceId}>View Invoice</Link>
      ) : null}
      {ticket.ticketForecastId ? (
        <Link to={'/forecasts/' + ticket.ticketForecastId}>View Forecast</Link>
      ) : null}
    </>
  ) : null;
};

export { QueriesSingleContent };
