import styled from 'styled-components';

export const MigrateUserFormWrapperStyled = styled.div`
  display: block;
  padding: 3.2rem;
  width: 100%;
  max-width: 60rem;
  margin: auto;
  background: var(--white);
  border-radius: 0.8rem;
  box-shadow: 2px 4px 9px rgba(2, 63, 133, 0.1);
`;
