import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

const useMessagesQuery = () => {
  const { hsId } = useUserContext();

  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: ['messages'],
    queryFn: async () => {
      if (hsId) {
        const response = await api(hsId).messages.getMessages();
        return response;
      } else {
        return [];
      }
    },
    enabled: !!hsId,
  });

  return {
    messagesData: data,
    messagesLoading: isLoading,
    messagesError: error,
    messagesRefetch: refetch,
    messagesIsFetching: isFetching,
  };
};

export { useMessagesQuery };
