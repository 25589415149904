// Components
// Components
import {
  Card,
  CardCol,
  CardColLabel,
  CardColContent,
  CardColDate,
  CardColStatus,
  CardActionMenuDropdown,
  CardActionMenuToggle,
} from '../../../../components/card';
import { ForecastListItemStyled } from '../styles/forecastListItem.styles';
import {
  DownloadCloudBlue,
  Information,
  QuestionMarkCircle,
} from '../../../../components/svg';
import { Link } from 'react-router-dom';

// Helpers
import { formatters } from '../../../../helpers';

// Types
import { ForecastsListItemProps } from '../types';

// Hooks
import { useState } from 'react';

const accountId = null;

const ForecastsListItem = ({
  forecast,
  setModalActive,
  setModalType,
  setActiveItem,
}: ForecastsListItemProps) => {
  const [dropdownState, setDropdownState] = useState(false);

  return (
    <>
      <Card>
        <ForecastListItemStyled>
          <CardCol>
            <CardColLabel label='Forecast from' />
            <CardColDate date={forecast.forecastFrom} />
          </CardCol>
          <CardCol>
            <CardColLabel label='Forecast to' />
            <CardColDate date={forecast.forecastTo} />
          </CardCol>
          <CardCol className='forecast-submitted-by'>
            <CardColLabel label='Submitted' />
            <CardColContent content={forecast.submittedBy} />
            <CardColContent
              content={formatters.formatDate(
                forecast.createdDate,
                'DD/MM/YYYY'
              )}
            />
          </CardCol>
          <CardCol className='forecast-total'>
            <CardColLabel label='Total forecasted kWh' />
            <CardColContent
              content={formatters.formatNumber(forecast.totalForecastedKwh)}
            />
          </CardCol>

          <CardColStatus
            className='forecast-status'
            status={forecast.pipelineStage}
          />

          <CardCol className='forecast-download'>
            {forecast.attachmentUrl ? (
              <a
                href={forecast.attachmentUrl}
                download
                target='_blank'
                rel='noreferrer'
              >
                <DownloadCloudBlue />
              </a>
            ) : null}
          </CardCol>
          <CardActionMenuToggle
            dropdownState={dropdownState}
            setDropdownState={setDropdownState}
          />
        </ForecastListItemStyled>
        <CardActionMenuDropdown dropdownState={dropdownState}>
          <ul>
            <li>
              <Link
                to={
                  accountId
                    ? `/app/${accountId}/forecasts/${forecast.id}`
                    : `/app/forecasts/${forecast.id}`
                }
              >
                <Information /> View Forecast
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  setModalActive(true);
                  setModalType('add-query');
                  setActiveItem(forecast);
                  setDropdownState(false);
                }}
              >
                <QuestionMarkCircle /> Query Forecast
              </button>
            </li>
          </ul>
        </CardActionMenuDropdown>
      </Card>
    </>
  );
};

export { ForecastsListItem };
