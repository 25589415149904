import { AddQueryFormItemProps } from '../types';
import { AddQueryCategories } from './addQueryCategories';

const AddQueryForecasts = ({
  categories,
  register,
  recordNumber,
  pmRef,
}: AddQueryFormItemProps) => {
  return (
    <>
      <AddQueryCategories categories={categories} register={register} />
      <div className='form-group'>
        <label htmlFor='forecastId'>Forecast Reference</label>
        <input
          id='forecastId'
          type='text'
          className='form-control'
          readOnly
          value={pmRef}
          defaultValue={pmRef}
        />
      </div>
      <div className='form-group'>
        <input
          type='text'
          className='form-control'
          {...register('forecastId')}
          readOnly
          hidden
          value={recordNumber}
          defaultValue={recordNumber}
        />
      </div>
    </>
  );
};

export { AddQueryForecasts };
