import { Toaster } from 'react-hot-toast';
import { CtaBlock } from '../global/ctaBlock';
import { Footer } from '../global/footer';
import { Footermobile } from '../global';
import { Header } from '../header';
import { Nav } from '../nav';
import { useMatchMedia } from '../../hooks/useMatchMedia';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const isDeskTopResolution = useMatchMedia('(min-width: 992px)', true);
  return (
    <div>
      <Toaster />
      <Header />
      <Nav />
      <main>{children}</main>
      {isDeskTopResolution && (
        <>
          <CtaBlock />
          <Footer />
        </>
      )}
      {!isDeskTopResolution && <Footermobile />}
    </div>
  );
};
