import {
  PIPELINE_IDS_DEV,
  PIPELINE_IDS_PROD,
  PIPELINE_IDS_STAGING,
} from '../../constants/pipelineIds';

const getPipelineIds = () => {
  if (process.env.REACT_APP_MODE === 'local') {
    return PIPELINE_IDS_DEV;
  } else if (process.env.REACT_APP_MODE === 'staging') {
    return PIPELINE_IDS_STAGING;
  } else if (process.env.REACT_APP_MODE === 'production') {
    return PIPELINE_IDS_PROD;
  } else {
    return null;
  }
};

export { getPipelineIds };
