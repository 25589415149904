import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';

import toast from 'react-hot-toast';

const useMessagesStatusUpdate = () => {
  const queryClient = useQueryClient();

  const updateStatus = async (messageId: string) => {
    const updateData = {
      messageId: messageId,
      updates: { isUnread: false },
    };
    try {
      await api(updateData).messages.updateMessageStatus();
    } catch {
      toast.error('Error updating message read status');
    }
  };

  const mutation = useMutation({
    mutationFn: updateStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['messages'] });
    },
  });

  const handleUpdateStatus = (messageId: string) => {
    mutation.mutate(messageId);
  };

  return { handleUpdateStatus };
};

export { useMessagesStatusUpdate };
