import styled from 'styled-components';

type CardStyledProps = {
  cols: string;
};

export const CardStyled = styled.div<CardStyledProps>`
position relative;
  padding: 15px 20px;
  background: var(--white);
  border-radius: 8px;
  box-shadow: 1.5px 3px 7px rgba(2, 63, 133, 0.1);

  &.is-unread {
    
  }

  .pod-card {
    display: grid;
    grid-column-gap: 10px;

    &__label {
      padding-bottom: 0.8rem;
      color: var(--nbs-navy-blue);
      font-size: 1.2rem;
    }

    &__date {
      color: var(--nbs-navy-blue);
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      margin-bottom: 10px;

      @media (min-width: 992px) {
        font-size: 12px;
        margin-bototm: 0;
      }
    }

    &__content {
      color: var(--nbs-black);
      font-size: 1.2rem;
    }

    &__status,
    &__download {
      align-self: stretch;
    }
  }

  .card-dropdown-toggle {
    button {
      display: inline-flex;
      align-self: flex-end;

      border: 0;

      svg {
        width: 1.6rem;
        fill: var(--nbs-navy-blue);
      }
    }
  }

  .ticket {
    grid-row-gap: 1.2rem;
    @media (min-width: 992px) {
      grid-template-rows: 1fr;
      grid-template-columns: 12rem 1fr 12rem 24rem 8rem minmax(12rem, 1fr);
    }

    &__subject {
      color: var(--nbs-navy-blue);
      font-size: 1.6rem;
      font-weight: 700;

      @media (min-width: 992px) {
        color: var(--nbs-black);
        font-size: 1.2rem;
        font-weight: 400;
      }
    }

    &__date {
      @media (min-width: 992px) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      span {
        display: flex;
        @media (min-width: 992px) {
          justify-content: flex-start;
        }
      }
    }

    &__date-label {
      display: none;

      @media (min-width: 992px) {
        display: block;
      }
    }

    &__submitted-by {
      display: none;

      @media (min-width: 992px) {
        display: flex;
      }
    }

    &__status {

      @media (min-width: 992px) {
        grid-column: 4 / 5;
        grid-row: 1 / 2;
        justify-content: center;
      }
      &.full_width_badge span{
        width: 100%;
        max-width: 300px;
      }
    }

    &__dropdown-toggle {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      svg {
        width: 4rem;
        fill: red;
      }
    }
  }

  .pod-card--invoice {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 1.2rem;
    align-items: center;

    @media(min-width:992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 20rem 2.8rem;
      grid-template-rows: 1fr;
      align-items: flex-start;
    }

    .invoice-date .pod-card__label,
    .invoice-pmi .pod-card__label,
    .invoice-number {
      display: none;

      @media(min-width:992px) {
        display: flex;
      }
    }

    .invoice-date {
      grid-column: 1 / 2;
    }

    .invoice-number {
      grid-columnr: 2 / 3;
    }

    .invoice-total {
      display: flex;
      flex-direction: row;
      justify-content: center;
      grid-column: 2 / 3;
      grid-row: 2 / 3;

      @media(min-width:992px) {
        grid-row: 1 / 2;
        flex-direction: column;
      }

      .pod-card__label {
        margin-right: 0.8rem;
        padding-bottom: 0;

        @media(min-width:992px) {
          padding-bottom: 0.8rem;
        }
      }
    }

    .invoice-pmi {
      
    }

    .invoice-pmi {
      grid-column: 3 / 4;
      text-align: right;

      @media(min-width:992px) {
        grid-row: 1 / 2;
        text-align: left;
      }
    }

    .invoice-status {
      grid-column: 1 / 2;

      @media(min-width:992px) {
        grid-column: 5 / 6;
        grid-row: 1 / 2;
      }
    }

    .invoice-download {
      
      @media(min-width:992px) {
        grid-column: 6 / 7;
        grid-row: 1 / 2;
      }

      .pod-card__download-button{
        display: flex;
        justify-content: flex-end;

        a {
          align-self: flex-end;
        }

        @media(min-width:992px) {
          justify-content: center;

          a {
            align-self: flex-start;
          }
        }
      }
    }
  }

  

  .pod-card--forecast {
    grid-template-columns: 1fr 1fr;

    .forecast-submitted-on {
      display: none;
    }

    .forecast-submitted-by,
    .forecast-total,
    .forecast-download {
      grid-column: 1 / 2;
    }

    .forecast-download {
      justify-content: flex-start;
      margin-top: 1.2rem;
    }

    .forecast-status {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    @media(min-width:992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2.8rem;

      .forecast-submitted-on {
        display: flex;

        grid-column: 2 / 3;
      }

      .forecast-submitted-by {
        grid-column: 3 / 4;
      }

      .forecast-total {
        grid-column: 4 / 5;
      }

      .forecast-download {
        grid-column: 6 / 7;
      }
      
      .forecast-status {
        grid-column: 5 / 6;
      }
    }
    
  }

  .pod-card--documents {
    grid-row-gap: 2rem;
    @media (min-width: 992px) {
      grid-template-columns: 12rem minmax(12rem, 20rem) minmax(36rem, 1fr) 12rem 2.4rem;
      grid-template-rows: 1fr;

      .pod-card__doc-date,
      .pod-card__doc-ref,
      .pod-card__doc-type {
        grid-row: 1 / 2;
      }

      .pod-card__doc-ref,
      .pod-card__doc-name {
        .pod-card__content {
          word-wrap: break-word;
        }
      } 

      .pod-card__doc-name {
        grid-row: 1 / 2;
        grid-column: 3 / 4;
      }

      .pod-card__download {
        justify-content: center;
      }
    }
  }


`;
