import { appHelpers } from '../../../helpers';

import { ids } from '../../../helpers';

import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { TICKETS_PROPERTIES } from '../../../constants/ticketObjectProperties';

const getStatementTickets = async (statementId) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  let body = {
    objectType: ids.getObjectIds().tickets,
    associationData: {
      objectFromType: ids.getObjectIds().statements,
      recordId: statementId,
    },
    properties: TICKETS_PROPERTIES,
    pipelineId: ids.getPipelineIds().tickets,
  };

  const config = {
    url: `${appHelpers.getApiUrl()}/api/v2/statements/getStatementTickets`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUser.accessToken}`,
    },
    data: body,
  };

  try {
    let response = await axios(config);

    return response;
  } catch (err) {
    console.log(err);
  }
};

export { getStatementTickets };
