import {
  Login,
  Register,
  AuthLoading,
  AuthError,
  Migrate,
} from '../features/auth';

export const PublicRoutes = [
  { path: '/', element: <Login /> },
  { path: '/auth/register', element: <Register /> },
  { path: '/auth/loading', element: <AuthLoading /> },
  {
    path: '/auth/error',
    element: <AuthError />,
  },
  {
    path: '/auth/migrate',
    element: <Migrate />,
  },
];
