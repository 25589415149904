import styled from 'styled-components';

export const StatementsListStyled = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  margin: 3.2rem 0;

  &.is-fetching {
    opacity: 0.8;
  }
`;
