import { FooterLogoStyled } from './styles/footerLogo.styles';
import footerLogoUrl from '../../../../img/nBS_footer_logo.png';

const FooterLogo = () => {
  return (
    <FooterLogoStyled>
      <img src={footerLogoUrl} alt='nBS Logo' />
    </FooterLogoStyled>
  );
};

export default FooterLogo;
