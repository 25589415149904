import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

const useGetTicketEmails = () => {
  const { id } = useParams();
  const { hsId } = useUserContext();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['singleTicketEmails', id],
    queryFn: async () => {
      if (id) {
        return await api({
          ticketId: id,
          hsId: hsId,
        }).tickets.getSingleTicketEmails();
      } else {
        return [];
      }
    },
    enabled: !!hsId,
  });

  return {
    singleTicketEmailData: data,
    singleTicketEmailError: error,
    singleTicketEmailLoading: isLoading,
    singleTicketEmailRefetch: refetch,
  };
};

export { useGetTicketEmails };
