// Components
import SimpleBar from 'simplebar-react';

// Context
import { useMessagesContext } from '../../../../../providers/messages/useMessagesContext';
import { MessagesInboxListMessagesItem } from './messagesInboxListMessagesItem';

const MessagesInboxListMessages = () => {
  const { messages } = useMessagesContext();

  return (
    <SimpleBar
      className='inbox-list-simplebar'
      autoHide={true}
      style={{
        maxHeight: 700,
        width: '100%',
      }}
      // forcehidden='x'
    >
      {messages &&
        messages.length > 0 &&
        messages.map((message) => (
          <MessagesInboxListMessagesItem
            key={message.id}
            message={{ ...message }}
          />
        ))}
    </SimpleBar>
  );
};

export { MessagesInboxListMessages };
