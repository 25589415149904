import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { skeleton } from '..';

import {
  EG_BLOG_TAG_ID_DEV,
  EG_BLOG_TAG_ID_STAGING,
  EG_BLOG_TAG_ID_PROD,
} from '../../constants/appConstants';
import InfoResourcesMobileItem from './infoResourcesMobileItem';
import { PostsList } from './styles/infoResourcesMobile.styles';

let tagId;
switch (process.env.REACT_APP_MODE) {
  case 'local':
    tagId = EG_BLOG_TAG_ID_DEV;
    break;
  case 'staging':
    tagId = EG_BLOG_TAG_ID_STAGING;
    break;
  case 'production':
  default:
    tagId = EG_BLOG_TAG_ID_PROD;
    break;
}

function InfoResourcesMobile({ title }) {
  const { data, isLoading } = useQuery(
    ['posts'],
    async () => {
      return await api(tagId).posts.getPosts();
    },
    {
      enabled: !!tagId,
    }
  );

  return (
    <PostsList>
      <h2>{title}</h2>
      {isLoading ? (
        <>
          <skeleton.SkeletonBlogCards />
        </>
      ) : (
        <>
          {data && data.data.results.length > 0
            ? data.data.results.map((post, key) => {
                return <InfoResourcesMobileItem post={post} key={key} />;
              })
            : null}
        </>
      )}
    </PostsList>
  );
}
InfoResourcesMobile.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  posts: PropTypes.array,
  loadingStatus: PropTypes.bool,
};

InfoResourcesMobile.defaultProps = {
  title: 'Latest Blog Articles',
  text: 'Stay up to date with the all the latest business energy news here.',
  posts: [],
  loadingStatus: false,
};

export default InfoResourcesMobile;
