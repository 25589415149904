import PropTypes from 'prop-types';
import { StyledWrapper } from './wrapper.styles';

export default function Wrapper({ gradient, marginTop, className, children }) {
  return (
    <StyledWrapper
      gradient={gradient}
      marginTop={marginTop}
      className={className}
    >
      {children}
    </StyledWrapper>
  );
}

Wrapper.propTypes = {
  gradient: PropTypes.bool,
  marginTop: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
