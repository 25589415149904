import { InfoResources } from '../../../../components/infoResources';
import { MainLayout } from '../../../../components/layouts/mainLayout';
import {
  SingleItemError,
  SingleItemLoader,
} from '../../../../components/singleItem';

import { useGetForecast } from '../../../../hooks/forecasts/useGetForecast';
import { ForecastsSingleContent } from '../components/forecastsSingleContent';
import { ForecastsSingleHeader } from '../components/forecastsSingleHeader';
import { ForecastsSingleTickets } from '../components/forecastsSingleTickets';

const ForecastsSingle = () => {
  const { data, isLoading, error } = useGetForecast();

  if (isLoading) {
    return (
      <MainLayout>
        <SingleItemLoader label='Loading invoice' />
        <InfoResources />
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <SingleItemError errorMessage='Error loading forecast, please refresh your browser' />
        <InfoResources />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      {data && (
        <>
          <ForecastsSingleHeader forecast={data.results} />
          <ForecastsSingleContent forecast={data.results} />
          <ForecastsSingleTickets forecast={data.results} />
        </>
      )}
      <InfoResources />
    </MainLayout>
  );
};

export { ForecastsSingle };
