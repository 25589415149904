import { InvoicesItem } from '../../invoices/types';

import { Container } from '../../../../components/container';
import { StatementSingleInvoicesItem } from './statementSingleInvoicesItem';

import { useGetStatementInvoices } from '../../../../hooks/statements/useGetStatementInvoices';
import {
  SindleStatementInvoicesFormStyled,
  SingleStatementInvoicesStyled,
} from '../styles/statementSingleInvoices.styles';
import { AddInvoice } from '../../../../components/forms/invoices';
import { StatementSingleContentProps } from '../types';

const StatementSingleInvoices = ({
  statementData,
}: StatementSingleContentProps) => {
  const { invoices } = useGetStatementInvoices();

  return (
    <SingleStatementInvoicesStyled>
      <Container>
        <h2>Statement Invoices</h2>
        {invoices &&
          invoices.results.items.length > 0 &&
          invoices.results.items.map((invoice: InvoicesItem) => (
            <StatementSingleInvoicesItem key={invoice.id} invoice={invoice} />
          ))}
        {invoices && invoices.results.items.length === 0 && (
          <SindleStatementInvoicesFormStyled>
            <AddInvoice
              type='statements-page'
              id='newInvoiceForm'
              statement={statementData}
            />
          </SindleStatementInvoicesFormStyled>
        )}
      </Container>
    </SingleStatementInvoicesStyled>
  );
};

export { StatementSingleInvoices };
