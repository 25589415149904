import { appHelpers } from '../../../helpers';
import axios from 'axios';

const fetchFirebaseUser = async (uid) => {
  const body = {
    firebaseUserId: uid,
  };

  const config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/users/fetchFirebaseUser`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_GS_FIREBASE_FUNCTIONS_API_TOKEN}`,
    },
    data: body,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    throw new Error(`Error fetching HubSpot user: ${err.message}`);
  }
};

export { fetchFirebaseUser };
