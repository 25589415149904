import { SingleItemAttachmentProps } from './types';
import { SingleItemAttachmentStyled } from './styles/singleItemAttachment.styles';

import { svg } from '../';

const SingleItemAttachment = ({ attachmentUrl }: SingleItemAttachmentProps) => {
  return (
    <SingleItemAttachmentStyled>
      <div>
        <h2>Statement Attachment</h2>
        <a href={attachmentUrl} target='_blank' rel='noreferrer' download>
          Click to download
        </a>
      </div>
      <div>
        <a href={attachmentUrl} target='_blank' rel='noreferrer' download>
          <svg.DownloadCloudBlue />
        </a>
      </div>
    </SingleItemAttachmentStyled>
  );
};

export default SingleItemAttachment;
