import styled from 'styled-components';

export const RefresherWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: 667px) {
    margin-left: auto;
  }
`;

export const Refresher = styled.button`
  display: flex;
  align-items: center;
  color: var(--nbs-navy-blue);
  font-size: 1.6rem;
  font-weight: 700;
  background: none;
  cursor: pointer;
`;
