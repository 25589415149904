import styled from 'styled-components';

export const SearchInputStyled = styled.input`
  width: 100%;
  padding: 1.2rem;
  color: var(--nbs-black);
  background: #fff;
  border: solid 0.1rem var(--nbs-ui-grey);
  border-radius: 0.4rem;
`;
