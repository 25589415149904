import {
  SkeletonBlogCardsStyled,
  SkeletonBlogCardStyled,
} from './styles/skeletonBlogCards.styles';

const SkeletonBlogCards = () => {
  return (
    <SkeletonBlogCardsStyled>
      <SkeletonBlogCardStyled>
        <div className='skeleton-blog-card__image'></div>
        <div className='skeleton-blog-card__content'>
          <div className='skeleton-blog-card__title'></div>
          <div className='skeleton-blog-card__text'></div>
          <div className='skeleton-blog-card__text'></div>
          <div className='skeleton-blog-card__button'></div>
        </div>
      </SkeletonBlogCardStyled>
      <SkeletonBlogCardStyled>
        <div className='skeleton-blog-card__image'></div>
        <div className='skeleton-blog-card__content'>
          <div className='skeleton-blog-card__title'></div>
          <div className='skeleton-blog-card__text'></div>
          <div className='skeleton-blog-card__text'></div>
          <div className='skeleton-blog-card__button'></div>
        </div>
      </SkeletonBlogCardStyled>
      <SkeletonBlogCardStyled>
        <div className='skeleton-blog-card__image'></div>
        <div className='skeleton-blog-card__content'>
          <div className='skeleton-blog-card__title'></div>
          <div className='skeleton-blog-card__text'></div>
          <div className='skeleton-blog-card__text'></div>
          <div className='skeleton-blog-card__button'></div>
        </div>
      </SkeletonBlogCardStyled>
    </SkeletonBlogCardsStyled>
  );
};

export default SkeletonBlogCards;
