import axios from 'axios';
import { appHelpers } from '../../../helpers';

const createFirebaseEmailUser = async (data) => {
  const body = {
    email: data.email,
    password: data.password,
  };

  const config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/auth/createFirebaseEmailUser`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_TOKEN}`,
    },
    data: body,
  };

  try {
    return await axios(config);
  } catch (err) {
    console.error(err);
  }
};

export { createFirebaseEmailUser };
