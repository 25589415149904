import styled from 'styled-components';

export const AlertStyled = styled.div`
  margin: 1.6rem 0;
  padding: 2rem;
  border-radius: 0.4rem;

  &.alert--success {
    background: var(--nbs-light-blue);

    p {
      color: var(--nbs-blue-light-100);
    }
  }

  &.alert--error {
    background-color: var(--red-200);
    border-color: var(--red);

    p,
    a {
      color: var(--red);
    }

    a {
      font-weight: bold;
    }
  }

  p {
    margin: 0;
    font-weight: 400;
  }
`;
