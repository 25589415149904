import styled from 'styled-components';

export const CardColDateStyled = styled.span`
  color: var(--nbs-navy-blue);
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 10px;

  @media (min-width: 992px) {
    font-size: 12px;
    margin-bototm: 0;
  }
`;
