import { appHelpers } from '../../../helpers';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const createTicketAssociation = async (requestData) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  let config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/tickets/createTicketAssociation`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUser.accessToken}`,
    },
    data: requestData,
  };

  return await axios(config);
};

export { createTicketAssociation };
