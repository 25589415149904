export const MAX_FILE_SIZE = 10485760; // 10MB

export const VALID_FILE_EXTENSIONS = [
  'jpg',
  'png',
  'jpeg',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'csv',
];
