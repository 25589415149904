import {
  SkeletonLoaderStyled,
  SkeletonLoaderLabelStyled,
  SkeletonLoaderItemStyled,
} from './styles/skeletonLoader.styles';
const skeletonLoaderItem = () => {
  return (
    <SkeletonLoaderStyled className='skeleton-loader card'>
      <div className='skeleton-loader__col'>
        <SkeletonLoaderLabelStyled />
        <SkeletonLoaderItemStyled />
      </div>
      <div className='skeleton-loader__col'>
        <SkeletonLoaderLabelStyled />
        <SkeletonLoaderItemStyled />
      </div>
      <div className='skeleton-loader__col'>
        <SkeletonLoaderLabelStyled />
        <SkeletonLoaderItemStyled />
      </div>
      <div className='skeleton-loader__col'>
        <SkeletonLoaderLabelStyled />
        <SkeletonLoaderItemStyled />
      </div>
      <div className='skeleton-loader__col'>
        <SkeletonLoaderLabelStyled />
        <SkeletonLoaderItemStyled />
      </div>
    </SkeletonLoaderStyled>
  );
};

export default skeletonLoaderItem;
