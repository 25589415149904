import styled from 'styled-components';

export const ActiveMessageStyled = styled.div`
  grid-column: 3 / 7;
`;

export const ActiveMessageHeaderStyled = styled.header`
  display: grid;
  grid-template-columns: 1fr 12rem;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1.6rem;
  align-items: center;
  width: calc(100% - 8rem);
  margin: 0 auto 2rem;
  padding: 2.4rem 0;
  border-bottom: 1px solid var(--nbs-ui-grey);
  @media only screen and (max-width: 992px) {
    grid-template-rows: 1fr;
    padding-top: 0;
  }
  h2,
  h1 {
    margin: 0;
    color: var(--nbs-blue);
    font-size: 3.2rem;
    font-weight: 900;
  }

  .message-from,
  .message-date {
    grid-row: 2 / 3;
  }

  .message-from {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--nbs-navy-blue);
    font-weight: 700;

    &__avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4.4rem;
      height: 4.4rem;
      margin-right: 1.6rem;
      border: solid 0.2rem var(--nbs-navy-blue);
      border-radius: 50%;

      svg {
        width: 1.6rem;
      }
    }
  }

  .message-date {
    display: flex;
    justify-content: flex-end;
    grid-column: 2 / 3;
    font-weight: 700;
    @media only screen and (max-width: 992px) {
      font-size: 1.2rem;
    }
  }
`;

export const ActiveMessageBodyStyled = styled.div`
  padding: 0 4rem;
`;

export const ActiveMessageFooterStyled = styled.footer`
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 8rem);
  margin: 4rem auto 0;
  padding: 1.6rem 0 0;
  border-top: solid 0.1rem var(--nbs-ui-grey);

  button {
    margin-left: 1.6rem;
    padding: 1.2rem;
    color: var(--nbs-navy-blue);
    font-weight: 700;
    background: none;
  }
`;
